// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listingPagination_showing__etiGF{
    color: #6C757D;
font-family: Cabin;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Listings/ListingPagination/listingPagination.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB,kBAAkB;AAClB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB","sourcesContent":[".showing{\n    color: #6C757D;\nfont-family: Cabin;\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showing": `listingPagination_showing__etiGF`
};
export default ___CSS_LOADER_EXPORT___;
