// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderInvoice_invoiceCard__g5ru0 {
  max-width: 830px !important;
  width: auto;
  padding: 3rem;
  color: black;
  margin: auto;
  box-shadow: 0px 20px 45px #f0edf6;
}

.OrderInvoice_logoimg__Ehk0e {
  width: 200px;
  max-height: 200px;
  height: 86px;
  object-fit: cover;
}

.OrderInvoice_greyscale__MwgJF {
  filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);
}
`, "",{"version":3,"sources":["webpack://./src/Pages/DeliveryForYouOrders/OrderInvoice.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,WAAW;EACX,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,iCAAiC;AACnC;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EAEE,uEAAuE;AACzE","sourcesContent":[".invoiceCard {\n  max-width: 830px !important;\n  width: auto;\n  padding: 3rem;\n  color: black;\n  margin: auto;\n  box-shadow: 0px 20px 45px #f0edf6;\n}\n\n.logoimg {\n  width: 200px;\n  max-height: 200px;\n  height: 86px;\n  object-fit: cover;\n}\n\n.greyscale {\n  -webkit-filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);\n  filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invoiceCard": `OrderInvoice_invoiceCard__g5ru0`,
	"logoimg": `OrderInvoice_logoimg__Ehk0e`,
	"greyscale": `OrderInvoice_greyscale__MwgJF`
};
export default ___CSS_LOADER_EXPORT___;
