// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchBar_searchBar__sGM81 .react-select__control--is-focused {
  border-color: #8834ff !important;
  box-shadow: none !important;
  padding-right: 10px;
}

.searchBar_searchBar__sGM81 input:focus {
  border: none !important;
}

.searchBar_searchBar__sGM81 .react-select__option {
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid var(--bs-gray-200);
}

.searchBar_searchBar__sGM81 .react-select__menu-list::-webkit-scrollbar {
  width: 0.3em !important;
}
.searchBar_searchBar__sGM81 .react-select__menu-list::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1) !important;
  outline: 1px solid rgba(222, 223, 228, 1) !important;
  border-radius: 6px !important;
}
.searchBar_searchBar__sGM81 .react-select__menu-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SalesPage/Cart/searchBar.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,mDAAmD;EACnD,oDAAoD;EACpD,6BAA6B;AAC/B;AACA;EACE,uDAAuD;AACzD","sourcesContent":[".searchBar :global(.react-select__control--is-focused) {\n  border-color: #8834ff !important;\n  box-shadow: none !important;\n  padding-right: 10px;\n}\n\n.searchBar input:focus {\n  border: none !important;\n}\n\n.searchBar :global(.react-select__option) {\n  padding-top: 3px;\n  padding-bottom: 3px;\n  border-bottom: 1px solid var(--bs-gray-200);\n}\n\n.searchBar :global(.react-select__menu-list)::-webkit-scrollbar {\n  width: 0.3em !important;\n}\n.searchBar :global(.react-select__menu-list)::-webkit-scrollbar-thumb {\n  background-color: rgba(178, 183, 203, 1) !important;\n  outline: 1px solid rgba(222, 223, 228, 1) !important;\n  border-radius: 6px !important;\n}\n.searchBar :global(.react-select__menu-list)::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBar": `searchBar_searchBar__sGM81`
};
export default ___CSS_LOADER_EXPORT___;
