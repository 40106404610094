// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/selecttick.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchAcoordian_bottomDiv__7M-kS {
  display: flex;
  justify-content: center;
  height: 40px;
  position: fixed;
  bottom: 0%;

  width: 100%;
  opacity: 1;
  margin-bottom: 10px;
}

.SearchAcoordian_accordionCardBody__74p9G {
  overflow: auto;
  height: calc(100vh - 305px) !important;
}
.SearchAcoordian_accordion_item__0wTtP {
  border-bottom: 1px solid #dee2e6;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.SearchAcoordian_radio__rSj95[type="radio"] {
  border-radius: 50% !important;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}
.SearchAcoordian_radio__rSj95:checked[type="radio"] {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: white !important;
}
.SearchAcoordian_form-check-label__zNRt\\+ {
  color: blueviolet;
}
`, "",{"version":3,"sources":["webpack://./src/styles/SearchAcoordian.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,UAAU;;EAEV,WAAW;EACX,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,sCAAsC;AACxC;AACA;EACE,gCAAgC;EAChC,2BAA2B;EAC3B,4BAA4B;EAC5B,6BAA6B;AAC/B;AACA;EACE,6BAA6B;EAC7B,gDAAgD;AAClD;AACA;EACE,oEAAiE;EACjE,6BAA6B;EAC7B,uBAAuB;EACvB,kCAAkC;AACpC;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".bottomDiv {\n  display: flex;\n  justify-content: center;\n  height: 40px;\n  position: fixed;\n  bottom: 0%;\n\n  width: 100%;\n  opacity: 1;\n  margin-bottom: 10px;\n}\n\n.accordionCardBody {\n  overflow: auto;\n  height: calc(100vh - 305px) !important;\n}\n.accordion_item {\n  border-bottom: 1px solid #dee2e6;\n  border-top: none !important;\n  border-left: none !important;\n  border-right: none !important;\n}\n.radio[type=\"radio\"] {\n  border-radius: 50% !important;\n  border: 1px solid rgba(0, 0, 0, 0.25) !important;\n}\n.radio:checked[type=\"radio\"] {\n  background-image: url(../assets/images/selecttick.png) !important;\n  border-radius: 0px !important;\n  border: none !important;\n  background-color: white !important;\n}\n.form-check-label {\n  color: blueviolet;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottomDiv": `SearchAcoordian_bottomDiv__7M-kS`,
	"accordionCardBody": `SearchAcoordian_accordionCardBody__74p9G`,
	"accordion_item": `SearchAcoordian_accordion_item__0wTtP`,
	"radio": `SearchAcoordian_radio__rSj95`,
	"form-check-label": `SearchAcoordian_form-check-label__zNRt+`
};
export default ___CSS_LOADER_EXPORT___;
