// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_ldsEllipsis__JvkMN {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 20px;
  }
  .Loader_ldsEllipsis__JvkMN div {
    position: absolute;
    top: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .Loader_ldsEllipsis__JvkMN div:nth-child(1) {
    left: 8px;
    animation: Loader_ldsEllipsis1__viXPz 0.6s infinite;
  }
  .Loader_ldsEllipsis__JvkMN div:nth-child(2) {
    left: 8px;
    animation: Loader_ldsEllipsis2__-DJDG 0.6s infinite;
  }
  .Loader_ldsEllipsis__JvkMN div:nth-child(3) {
    left: 32px;
    animation: Loader_ldsEllipsis2__-DJDG 0.6s infinite;
  }
  .Loader_ldsEllipsis__JvkMN div:nth-child(4) {
    left: 56px;
    animation: Loader_ldsEllipsis3__yfSt- 0.6s infinite;
  }
  @keyframes Loader_ldsEllipsis1__viXPz {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes Loader_ldsEllipsis3__yfSt- {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes Loader_ldsEllipsis2__-DJDG {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

`, "",{"version":3,"sources":["webpack://./src/styles/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;EACA;IACE,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,mDAAmD;EACrD;EACA;IACE,SAAS;IACT,mDAAqC;EACvC;EACA;IACE,SAAS;IACT,mDAAqC;EACvC;EACA;IACE,UAAU;IACV,mDAAqC;EACvC;EACA;IACE,UAAU;IACV,mDAAqC;EACvC;EACA;IACE;MACE,mBAAmB;IACrB;IACA;MACE,mBAAmB;IACrB;EACF;EACA;IACE;MACE,mBAAmB;IACrB;IACA;MACE,mBAAmB;IACrB;EACF;EACA;IACE;MACE,0BAA0B;IAC5B;IACA;MACE,6BAA6B;IAC/B;EACF","sourcesContent":[".ldsEllipsis {\n    display: inline-block;\n    position: relative;\n    width: 80px;\n    height: 20px;\n  }\n  .ldsEllipsis div {\n    position: absolute;\n    top: 9px;\n    width: 8px;\n    height: 8px;\n    border-radius: 50%;\n    background: #fff;\n    animation-timing-function: cubic-bezier(0, 1, 1, 0);\n  }\n  .ldsEllipsis div:nth-child(1) {\n    left: 8px;\n    animation: ldsEllipsis1 0.6s infinite;\n  }\n  .ldsEllipsis div:nth-child(2) {\n    left: 8px;\n    animation: ldsEllipsis2 0.6s infinite;\n  }\n  .ldsEllipsis div:nth-child(3) {\n    left: 32px;\n    animation: ldsEllipsis2 0.6s infinite;\n  }\n  .ldsEllipsis div:nth-child(4) {\n    left: 56px;\n    animation: ldsEllipsis3 0.6s infinite;\n  }\n  @keyframes ldsEllipsis1 {\n    0% {\n      transform: scale(0);\n    }\n    100% {\n      transform: scale(1);\n    }\n  }\n  @keyframes ldsEllipsis3 {\n    0% {\n      transform: scale(1);\n    }\n    100% {\n      transform: scale(0);\n    }\n  }\n  @keyframes ldsEllipsis2 {\n    0% {\n      transform: translate(0, 0);\n    }\n    100% {\n      transform: translate(24px, 0);\n    }\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ldsEllipsis": `Loader_ldsEllipsis__JvkMN`,
	"ldsEllipsis1": `Loader_ldsEllipsis1__viXPz`,
	"ldsEllipsis2": `Loader_ldsEllipsis2__-DJDG`,
	"ldsEllipsis3": `Loader_ldsEllipsis3__yfSt-`
};
export default ___CSS_LOADER_EXPORT___;
