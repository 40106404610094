// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WidgetCard_widgetCard__kzoAU {
    height: 180px !important;
    /* max-width: 370px; */
    box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.WidgetCard_textWrap__hm93K {
    /* white-space: pre; */
    font-size: 26px !important;
    line-height: 32px;
    letter-spacing: -0.01rem;
}

@media screen and (max-width: 500px) {
    .WidgetCard_widgetCard__kzoAU {
        height: 100% !important;
        max-width: 100%;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
    .WidgetCard_widgetCard__kzoAU img {
        width: 50px !important;
        height: 50px !important;
        margin-left: 5px;
    }
    .WidgetCard_widgetCard__kzoAU h3 {
        font-size: 22px !important;
        margin: 0;
    }
    .WidgetCard_widgetCard__kzoAU .card-title {
        font-size: 18px !important;
        margin: 0 !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/WidgetCard.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,sBAAsB;IACtB,gDAAgD;IAChD,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;IACtB,0BAA0B;IAC1B,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI;QACI,uBAAuB;QACvB,eAAe;QACf,iBAAiB;QACjB,oBAAoB;IACxB;IACA;QACI,sBAAsB;QACtB,uBAAuB;QACvB,gBAAgB;IACpB;IACA;QACI,0BAA0B;QAC1B,SAAS;IACb;IACA;QACI,0BAA0B;QAC1B,oBAAoB;IACxB;AACJ","sourcesContent":[".widgetCard {\n    height: 180px !important;\n    /* max-width: 370px; */\n    box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);\n    padding-top: 1rem;\n    padding-bottom: 1rem;\n}\n\n.textWrap {\n    /* white-space: pre; */\n    font-size: 26px !important;\n    line-height: 32px;\n    letter-spacing: -0.01rem;\n}\n\n@media screen and (max-width: 500px) {\n    .widgetCard {\n        height: 100% !important;\n        max-width: 100%;\n        padding-top: 0rem;\n        padding-bottom: 0rem;\n    }\n    .widgetCard img {\n        width: 50px !important;\n        height: 50px !important;\n        margin-left: 5px;\n    }\n    .widgetCard h3 {\n        font-size: 22px !important;\n        margin: 0;\n    }\n    .widgetCard :global(.card-title) {\n        font-size: 18px !important;\n        margin: 0 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetCard": `WidgetCard_widgetCard__kzoAU`,
	"textWrap": `WidgetCard_textWrap__hm93K`
};
export default ___CSS_LOADER_EXPORT___;
