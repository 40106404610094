// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormCard_formCard__ja3Vo {
  width: 500px;
  padding: 3rem;
  box-shadow: 0px 20px 45px #f0edf6 !important;
}
.FormCard_disabledbutton__ADmdi {
  pointer-events: none;
  opacity: 0.4;
}
@media screen and (max-width: 636px) {
  .FormCard_formCard__ja3Vo {
    padding: 1rem;
    min-width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .FormCard_formCard__ja3Vo {
    min-width: 300px !important;
    width: 300px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/FormCard.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,4CAA4C;AAC9C;AACA;EACE,oBAAoB;EACpB,YAAY;AACd;AACA;EACE;IACE,aAAa;IACb,2BAA2B;EAC7B;AACF;;AAEA;EACE;IACE,2BAA2B;IAC3B,YAAY;EACd;AACF","sourcesContent":[".formCard {\n  width: 500px;\n  padding: 3rem;\n  box-shadow: 0px 20px 45px #f0edf6 !important;\n}\n.disabledbutton {\n  pointer-events: none;\n  opacity: 0.4;\n}\n@media screen and (max-width: 636px) {\n  .formCard {\n    padding: 1rem;\n    min-width: 300px !important;\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .formCard {\n    min-width: 300px !important;\n    width: 300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formCard": `FormCard_formCard__ja3Vo`,
	"disabledbutton": `FormCard_disabledbutton__ADmdi`
};
export default ___CSS_LOADER_EXPORT___;
