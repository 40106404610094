// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.DatePicker_button__BHeVI{
    background-color: transparent;
    padding: 5px;
    min-width: 100%;
    border: 1px solid #CED4DA !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/DatePicker/DatePicker.module.css"],"names":[],"mappings":";AACA;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,oCAAoC;AACxC","sourcesContent":["\n.button{\n    background-color: transparent;\n    padding: 5px;\n    min-width: 100%;\n    border: 1px solid #CED4DA !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `DatePicker_button__BHeVI`
};
export default ___CSS_LOADER_EXPORT___;
