import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import customloader from "../../assets/images/RollingLoader.gif";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import NoResult from "../../Components/NoResult";
import axios from "axios";
import PaginationBar from "../../Components/PaginationBar";
import Csv from "../../Components/csv/Csv";
import { requestDateTime, TIMEOUT } from "../../Features/dateTime";
import LoaderPage from "../../Components/loaders/loaderPage";

const VendorWithoutPurchaseOrder = ({ searchData, dateFrom, dateTo, pageSize }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { user } = useSelector((state) => state.auth);
  const shopId = user.shop_id;
  const shopName = user.shop_name;

  //data toggle
  const [toggle, refresh] = useState(true);

  //pagination
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/supplier?page=" + page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  //orderBy
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });

  //Vendors
  const [vendors, setVendors] = useState([]);
  const [totalVendors, setTotalVendors] = useState();

  const edit = (id) => {
    navigate("/supplier/edit/" + id, { state: { redirect: "/supplier" } });
  };

  const remove = (id) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/vendors/delete/" + id, {
        withCredentials: true,
      })
      .then((data) => {
        refresh((prev) => !prev);
      });
  };

  function getVendors() {
    if (user?.tour && !user?.tour.Vendorlist) {
      setVendors([
        {
          id: 0,
          vendor_name: "abc",
          email: "abc@gmail.com",
          contact_number: "+92-XXX-XXXXXXX",
          created_at: "1970-01-01T18:26:47.000Z",
        },
      ]);
      setTotalVendors(1);
      setNoOfPages(1);
      setIsLoading(false);
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/vendors/get-detail?page=" + currentPage + "&size=" + pageSize,
          {
            shop_id: shopId,
            search: searchData,
            dates: {
              from: requestDateTime(dateFrom).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
              to: requestDateTime(dateTo).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
            },
            orderBy: orderBy,
          },
          { withCredentials: true }
        )
        .then((data) => {
          const res = data.data;
          setVendors(
            res?.data.map((vendor) => {
              const { vendor_name, ...rest } = vendor;
              return {
                supplier_name: vendor_name,
                ...rest,
              };
            })
          );
          if (res?.count?.no_of_pages === 0) {
            if (searchParams.get("page")) {
              setNoOfPages(0);
              navigate("/vendor?page=0");
            }
          } else {
            setTotalVendors(res?.count?.no_of_items);
            setNoOfPages(res?.count?.no_of_pages);
            if (currentPage > res?.count?.no_of_pages) {
              setCurrentPage(res?.count?.no_of_pages);
              navigate("/vendor?page=" + res?.count?.no_of_pages);
            }
            if (searchParams.get("page") === "0") {
              setCurrentPage(1);
              navigate("/vendor?page=1");
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          const tempErr = err.response.data.error;
          if (tempErr.status === 404 && tempErr.message === "No Record Found") {
            setVendors([]);
          }
        });
    }
  }
  //loading
  const [isLoading, setIsLoading] = useState(true);

  //fetch data
  useEffect(() => {
    let dataTimeout;
    if (searchData?.length > 0) {
      dataTimeout = setTimeout(() => {
        setIsLoading(true);
        getVendors();
      }, TIMEOUT);
    } else {
      setIsLoading(true);
      getVendors();
    }
    return () => {
      clearTimeout(dataTimeout);
    };
  }, [searchData, shopId, toggle, searchParams, currentPage, pageSize, orderBy, user?.tour?.Vendorlist]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [dateFrom, dateTo]);

  function requestAllHistory() {
    return axios.post(
      process.env.REACT_APP_API_URL + "/vendors/get-detail",
      {
        shop_id: shopId,
        search: searchData,
        dates: {
          from: requestDateTime(dateFrom).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
          to: requestDateTime(dateTo).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
        },
        orderBy: orderBy,
      },
      { withCredentials: true }
    );
  }

  const [vendorData, setVendorData] = useState({});
  const [vendorName, setVendorName] = useState();
  const caret = async (id) => {
    if (user?.tour && !user?.tour.Vendorlist) {
      setVendorName("abc");
      setVendorData({
        business_email: "abc@gmail.com",
        business_phone: "+92-XXX-XXXXXXX",
        contact_person_name: "abc",
        contact_number: "+92-XXX-XXXXXXX",
        product_types: "mobiles,tabs",
        vendor_opening_balance: 0,
        memo: null,
      });
    } else {
      const data = await axios.post(process.env.REACT_APP_API_URL + "/vendors/get/" + id, { shopId: shopId }, { withCredentials: true });
      const { created_at, is_deleted, location, shop_id, terms, vendor_id, vendor_name, ...vendorObj } = data.data;
      setVendorName(vendor_name);
      setVendorData(vendorObj);
    }
  };

  return (
    <>
      <div id="supplier-without-purchase-table">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
            <LoaderPage />
          </div>
        ) : !vendors[0] ? (
          <NoResult name="Vendors" />
        ) : (
          <div id="supplier-without-purchase-table">
            <CustomizeTableview
              className={"mt-5"}
              data={vendors}
              edit={edit}
              remove={remove}
              // purchaseOrder={purchaseOrder}
              sortColumn={["created_at"]}
              columnNotShow={["id"]}
              dateColumn={["created_at"]}
              // actionButton={{
              //   label: "Add Purchase",
              //   onClick: (id) => {
              //     handleAddPurchaseButton(id);
              //   },
              //   variant: "outline-primary",
              //   style: { padding: "8px", fontSize: "12px" },
              //   column: "id",
              //   isShow: (value) => {
              //     return true;
              //   },
              // }}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              caret={caret}
              caretTitle={vendorName}
              caretData={vendorData}
            />
            <Row className="mt-5 align-items-center">
              <Col className="d-none d-md-block" md="3" lg="3">
                <span className="text-secondary fs-6">{totalVendors} result(s)</span>
              </Col>
              <Col xs="12" md="6" lg="6">
                <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
              </Col>
              <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                <span className="text-secondary fs-6">{totalVendors} result(s)</span>
              </Col>
              <Col xs="6" md="3" lg="3" className="text-end ">
                <Csv
                  filename={`${
                    "Vendors " +
                    shopName +
                    " " +
                    new Date().toLocaleDateString("en-US", {
                      timeZone: "Asia/Karachi",
                    })
                  }`}
                  api={requestAllHistory}
                  format={(data) => {
                    const { id, created_at, contact_number, ...temp } = data;
                    const newObj = {
                      ...temp,
                      contact_number: contact_number.replaceAll("+", "").replaceAll("-", " "),
                      created_at: created_at.split("T")[0],
                    };
                    return newObj;
                  }}
                  error={(err) => {
                    if (err.response) {
                      const tempErr = err.response.data.error;
                      if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  }}
                  responseObject={(data) => {
                    return data?.data?.data;
                  }}
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default VendorWithoutPurchaseOrder;
