// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listingAddItem_modalDialog__BSiSq .table{
    margin-bottom: 0 !important;
}

.listingAddItem_modalDialog__BSiSq .modal-content{
    height: calc(100vh - 100px) !important;

}

.listingAddItem_customizeModal__J2pv0 .modal-content{
    margin: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) -1px 3px 25px 20px;
    height: calc(100vh - 300px) !important;

}
.listingAddItem_tableContainer__mEBXt{
    height:calc(100% - 90px)  !important;
}

@media screen and (max-width: 575px) {
    .listingAddItem_modalDialog__BSiSq .modal-content{
        height: 100vh !important;
    }
    .listingAddItem_tableContainer__mEBXt{
        height:calc(100% - 67px)  !important;
    }
  }
@media screen and (max-width: 500px) {
    .listingAddItem_modalDialog__BSiSq .modal-body{
        padding:0 !important;
    }
  }
  @media screen and (max-width: 1500px) {
    .listingAddItem_customizeModal__J2pv0 .modal-content{
        height: calc(100vh - 100px) !important;
    
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/Listings/ListingAddItem/listingAddItem.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sCAAsC;;AAE1C;;AAEA;IACI,YAAY;IACZ,uDAAuD;IACvD,sCAAsC;;AAE1C;AACA;IACI,oCAAoC;AACxC;;AAEA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,oCAAoC;IACxC;EACF;AACF;IACI;QACI,oBAAoB;IACxB;EACF;EACA;IACE;QACI,sCAAsC;;IAE1C;EACF","sourcesContent":[".modalDialog :global(.table){\n    margin-bottom: 0 !important;\n}\n\n.modalDialog :global(.modal-content){\n    height: calc(100vh - 100px) !important;\n\n}\n\n.customizeModal :global(.modal-content){\n    margin: 30px;\n    box-shadow: rgba(100, 100, 111, 0.2) -1px 3px 25px 20px;\n    height: calc(100vh - 300px) !important;\n\n}\n.tableContainer{\n    height:calc(100% - 90px)  !important;\n}\n\n@media screen and (max-width: 575px) {\n    .modalDialog :global(.modal-content){\n        height: 100vh !important;\n    }\n    .tableContainer{\n        height:calc(100% - 67px)  !important;\n    }\n  }\n@media screen and (max-width: 500px) {\n    .modalDialog :global(.modal-body){\n        padding:0 !important;\n    }\n  }\n  @media screen and (max-width: 1500px) {\n    .customizeModal :global(.modal-content){\n        height: calc(100vh - 100px) !important;\n    \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalDialog": `listingAddItem_modalDialog__BSiSq`,
	"customizeModal": `listingAddItem_customizeModal__J2pv0`,
	"tableContainer": `listingAddItem_tableContainer__mEBXt`
};
export default ___CSS_LOADER_EXPORT___;
