// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadFile_upload_btn_wrapper__AqBOm input[type=file] {
    font-size: 100px;
    width: 81%;
    height: 50px;
    position: absolute;
    /* left: 23px; */
    right: 47px;
    opacity: 0;
  }
`, "",{"version":3,"sources":["webpack://./src/styles/UploadFile.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,UAAU;EACZ","sourcesContent":[".upload_btn_wrapper input[type=file] {\n    font-size: 100px;\n    width: 81%;\n    height: 50px;\n    position: absolute;\n    /* left: 23px; */\n    right: 47px;\n    opacity: 0;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload_btn_wrapper": `UploadFile_upload_btn_wrapper__AqBOm`
};
export default ___CSS_LOADER_EXPORT___;
