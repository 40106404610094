import { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col } from "react-bootstrap";
import SearchableSelect from "../../SearchableSelect";
import Markdown from "../../MarkdownEditor/Markdown";
import ImageUploader from "../../ImageUploader/ImageUploader";
import ReactSelect from "../../ReactSelect";
import { getcomponent } from "../../InventoryForms/utils";
import { convert } from "html-to-text";
import VideoUploader from "../../VideoUploader/VideoUploader";
import { generateShortDescription } from "./generateDescription";
import style from "./validationModal.module.css";

const TvMonitorForm = forwardRef(({ state, setState }, ref) => {
  const { imageRef, videoRef } = ref;

  const [titlePlaceholder, setTitlePlaceholder] = useState("e.g. Samsung 32 inch");
  const [isTitleCustomize, setIsTitleCustomize] = useState(true);
  const [customizeTitle, setCustomizeTitle] = useState({
    fixedPart: "",
    dynamicPart: "",
  });
  const [isTitleCharacterLimitExceeded, setIsTtitleCharacterLimitExceeded] = useState(false);
  const [isCustomizeTitleFocused, setIsCustomizeTitleFocused] = useState(false);
  const [isErrorInGeneratingDescription, setIsErrorInGeneratingDescription] = useState(false);

  const handleChangeScreenSize = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        screen_size: e.label,
        fk_screen_size_id: e.value,
      },
    }));
  };
  const handleChangeBrand = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        brand: e.label,
        fk_brand_id: e.value,
      },
    }));
  };
  const handleChangeSpeaker = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        speaker: e.label,
        fk_speaker_id: e.value,
      },
    }));
  };
  const handleChangeScreenType = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        screen_type: e.label,
        fk_screen_type_id: e.value,
      },
    }));
  };
  const handleChangeRefreshRate = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        refresh_rate: e.label,
        fk_refresh_rate_id: e.value,
      },
    }));
  };
  const handleChangeDisplayType = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        display_type: e.label,
        fk_display_type_id: e.value,
      },
    }));
  };
  const handleChangeResolution = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        resolution: e.label,
        fk_resolution_id: e.value,
      },
    }));
  };
  const handleChangeOperatingSystem = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        operating_system: e.label,
        fk_operating_system_id: e.value,
      },
    }));
  };
  const handleChangeWebCam = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        is_webcam: e.value,
      },
    }));
  };
  const handleChangeSmartTV = (e) => {
    if (!e.value) {
      setState((prev) => {
        const { is_tv_certificated, operating_system, fk_operating_system_id, ...listing } = prev.listing;
        return {
          ...prev,
          listing,
        };
      });
    }
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        is_smart_tv: e.value,
      },
    }));
  };
  const handleChangeCertificated = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        is_tv_certificated: e.value,
      },
    }));
  };
  const handleChangeColor = (e) => {
    setState((prev) => ({
      ...prev,
      listing_color: {
        color: e.label,
        fk_color_id: e.value,
      },
    }));
  };
  const handleChangeTVMonitor = (e) => {
    setState((prev) => {
      return {
        ...prev,
        listing: {
          ...prev.listing,
          type: e.value,
        },
      };
    });
  };
  const handleChangePort = (e, { action }) => {
    const list = e.map((item) => {
      return { port: item.label, fk_port_id: item.value };
    });
    if (action === "clear") {
      setState((prev) => {
        delete prev.listing_port;
        return { ...prev };
      });
    } else if (action === "select-option" || action === "remove-value") {
      if (list.length > 0) {
        setState((prev) => ({
          ...prev,
          listing_port: list,
        }));
      } else {
        setState((prev) => {
          delete prev.listing_port;

          return { ...prev };
        });
      }
    }
  };
  const handleChangeModel = (e) => {
    const cleanedValue = e.target.value
      .replace(/[^a-zA-Z0-9 \-\/'+]/g, "")
      .replace(/\s+/g, " ")
      .replace(/\s+/g, " ");
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        model: cleanedValue,
        fk_model_id: -1,
      },
    }));
  };
  const handleChangeAdditionalNotes = (e) => {
    setState((prev) => {
      return {
        ...prev,
        listing_optional: {
          ...prev.listing_optional,
          notes: e.target.value,
        },
      };
    });
  };
  const handleChangeImages = (event) => {
    if (typeof event === "function") {
      setState((prev) => ({
        ...prev,
        images: event(prev.images),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        images: event,
      }));
    }
  };

  const handleChangeVideo = (event) => {
    if (typeof event === "function") {
      setState((prev) => ({
        ...prev,
        video: event(prev.video),
      }));
    } else {
      if (event === null) {
        setState((prev) => {
          if (prev?.video) {
            delete prev?.video;
          }
          return prev;
        });
      } else {
        setState((prev) => ({
          ...prev,
          video: event,
        }));
      }
    }
  };
  const validateInput = (event) => {
    const regex = /^[a-zA-Z0-9 \-\/']*$/;

    const input = event.target.value + event.key;
    if (!regex.test(input)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isTitleCustomize) {
      if (state?.listing?.brand && state?.listing?.model) {
        const autoFillPart = `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand) : ""} ${state?.listing?.model?.trim()}`;
        setCustomizeTitle((prev) => ({
          ...prev,
          fixedPart: autoFillPart,
        }));
        setState((prev) => ({
          ...prev,
          title: autoFillPart + `${customizeTitle?.dynamicPart?.length > 0 ? " " + customizeTitle.dynamicPart : ""}`,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          title: "",
        }));
        setCustomizeTitle({ fixedPart: "", dynamicPart: "" });
        setTitlePlaceholder("Fill all the fields to Add the title....");
      }
    } else {
      if (state?.listing?.brand && state?.listing?.model && state?.listing?.screen_size && state?.listing?.refresh_rate && state?.listing?.screen_type && state?.listing?.type) {
        setState((prev) => ({
          ...prev,
          title: `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand + " ") : ""}${state?.listing?.model?.trim()}${state?.listing?.screen_size && state?.listing?.screen_size !== "0.00" ? " " + state?.listing?.screen_size + " Inches" : ""}${
            state?.listing?.refresh_rate ? " " + state?.listing?.refresh_rate : ""
          } Hz${state?.listing?.screen_type && state?.listing?.screen_type !== "Other" ? " " + state?.listing?.screen_type : ""} ${state?.listing?.type ? "[" + state?.listing?.type + "]" : ""}`,
        }));
      } else {
        if (state?.listing?.brand || state?.listing?.model || state?.listing?.screen_size || state?.listing?.refresh_rate || state?.listing?.screen_type) {
          setTitlePlaceholder("Fill all the fields to generate the title....");
        }
        setState((prev) => {
          return {
            ...prev,
            title: "",
          };
        });
      }
    }
  }, [state?.listing?.brand, state?.listing?.model, state?.listing?.screen_size, state?.listing?.refresh_rate, state?.listing?.screen_type, state?.listing?.type, isTitleCustomize, customizeTitle.dynamicPart]);
  return (
    <>
      <Form noValidate validated={state?.validated} className="m-3">
        <Row className="gx-0 gx-sm-3 gx-lg-5">
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.brand
                    ? {
                        label: state?.listing?.brand,
                        value: state?.listing?.fk_brand_id,
                      }
                    : null
                }
                label={"Brand"}
                important
                placeholder="Select brand"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("brand", { is_led: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeBrand}
                name="Brand"
                isInvalid={state?.validated && !state?.listing?.brand}
                required
              />
              <Form.Control.Feedback type="invalid">* Please Select Brand </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group className="" controlId="model">
              <Form.Label className="mb-0">
                Model<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control onKeyDown={validateInput} className="py-2" type="text" placeholder="e.g. dell" onChange={handleChangeModel} value={state?.listing?.model ? state?.listing?.model : ""} name="model" required />

              <Form.Control.Feedback type="invalid">* Please Select Model </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Title<span style={{ color: "red" }}>*</span>{" "}
                <Form.Check
                  type="checkbox"
                  style={{ display: "inline", paddingLeft: "5px" }}
                  checked={!isTitleCustomize}
                  onChange={(e) => {
                    setIsTitleCustomize(!e.target.checked);
                  }}
                />
                <span className={`ps-1 text-muted`}>Auto-Generate Title</span>
              </Form.Label>
              {!isTitleCustomize ? (
                <Form.Control className="py-3 pe-5" type="text" placeholder={titlePlaceholder} value={state?.title} disabled={true} onChange={() => {}} name="model" required />
              ) : (
                <>
                  <div className={`p-3 form-control ${style.customizeTitleField} ${state.title ? "" : style.disabledDiv}`}>
                    {state?.title ? (
                      <>
                        <span className={style.customizeTitleFieldSpan}>{customizeTitle.fixedPart}&nbsp;</span>
                        <input
                          value={customizeTitle.dynamicPart}
                          className={`${style.customizeTitleFieldInput}`}
                          onKeyDown={validateInput}
                          onChange={(e) => {
                            const value = e.target.value;

                            const cleanedValue = value.replace(/[^a-zA-Z0-9 \-\/'+]/g, "").replace(/\s+/g, " ");
                            if (customizeTitle.fixedPart.length + cleanedValue.length <= 250) {
                              setCustomizeTitle((prev) => ({
                                ...prev,
                                dynamicPart: cleanedValue,
                              }));
                            }

                            if (customizeTitle.fixedPart.length + cleanedValue.length <= 70) {
                              setIsTtitleCharacterLimitExceeded(false);
                            } else {
                              setIsTtitleCharacterLimitExceeded(true);
                            }
                          }}
                          type="text"
                          disabled={state?.listing?.brand && state?.listing?.model ? false : true}
                          name="model"
                          required
                        />
                      </>
                    ) : (
                      <span className="text-muted">{titlePlaceholder}</span>
                    )}
                  </div>
                  {isTitleCharacterLimitExceeded ? <div className="text-danger py-2">*7-100 characters are recommended above 100 is not SEO friendly. The maximum allowed length is 250 characters.</div> : <></>}

                  <div className="alert alert-warning" role="alert">
                    Please enter "Brand and Model" before entering the title
                  </div>
                </>
              )}
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing_color?.color
                    ? {
                        label: state?.listing_color?.color,
                        value: state?.listing_color?.fk_color_id,
                      }
                    : null
                }
                name="Color"
                label={"Color"}
                important
                placeholder="Select Color"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("color", { is_led: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeColor}
                required
                isInvalid={state?.validated && !state?.listing_color?.color}
              />

              <Form.Control.Feedback type="invalid">Please Select Color</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={SearchableSelect}
                value={state?.listing?.type ? (state?.listing?.type === "TV" ? { value: "TV", label: "TV" } : { value: "Monitor", label: "Monitor" }) : null}
                label={"TV/Monitor"}
                important={true}
                placeholder="Select TV/Monitor"
                searchable={false}
                options={[
                  { value: "TV", label: "TV" },
                  { value: "Monitor", label: "Monitor" },
                ]}
                handleChange={handleChangeTVMonitor}
                name="tv_monitor_type"
                required
                isInvalid={state?.validated ? !state?.listing?.type : false}
              />
              <Form.Control.Feedback type="invalid">* Please Select TV/Monitor</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.screen_size
                    ? {
                        label: state?.listing?.screen_size,
                        value: state?.listing?.fk_screen_size_id,
                      }
                    : null
                }
                label={"Screen Size (Inches)"}
                important
                placeholder="Select Screen Size"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("screen_size", { is_led: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeScreenSize}
                name="Screen Size"
                isInvalid={state?.validated && !state?.listing?.screen_size}
                required
              />
              <Form.Control.Feedback type="invalid">* Please Select Screen Size</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing_port && state?.listing_port?.length > 0
                    ? state?.listing_port?.map((item) => {
                        return {
                          label: item.port,
                          value: item.fk_port_id,
                        };
                      })
                    : null
                }
                label={"Port"}
                important
                placeholder="Select Port"
                searchable={true}
                multiselect={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("ports", { is_led: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangePort}
                name="Port"
                isInvalid={state?.validated && !(state?.listing_port?.length > 0)}
                required
              />
              <Form.Control.Feedback type="invalid">* Please Select Port</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.speaker
                    ? {
                        label: state?.listing?.speaker,
                        value: state?.listing?.fk_speaker_id,
                      }
                    : null
                }
                label={"Speaker"}
                important
                placeholder="Select Speaker"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("speaker")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeSpeaker}
                name="Speaker"
                required
                isInvalid={state?.validated && !state?.listing?.speaker}
              />
              <Form.Control.Feedback type="invalid">* Please Select Speaker</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.screen_type
                    ? {
                        label: state?.listing?.screen_type,
                        value: state?.listing?.fk_screen_type_id,
                      }
                    : null
                }
                label={"Screen Type"}
                important
                placeholder="Select Screen Type"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("screen_type")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeScreenType}
                name="Screen Type"
                isInvalid={state?.validated && !state?.listing?.screen_type}
                required
              />
              <Form.Control.Feedback type="invalid">* Please Select Screen Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.refresh_rate
                    ? {
                        label: state?.listing?.refresh_rate,
                        value: state?.listing?.fk_refresh_rate_id,
                      }
                    : null
                }
                label={"Refresh Rate (Hz)"}
                important
                placeholder="Select Refresh Rate"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("refresh_rate")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeRefreshRate}
                name="Refresh Rate"
                required
                isInvalid={state?.validated && !state?.listing?.refresh_rate}
              />
              <Form.Control.Feedback type="invalid">* Please Select Refresh Rate</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={SearchableSelect}
                value={typeof state?.listing?.is_webcam === "boolean" ? (state?.listing?.is_webcam ? { value: true, label: "Yes" } : { value: false, label: "No" }) : null}
                label={"WebCam"}
                important
                placeholder="Select WebCam"
                searchable={false}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                handleChange={handleChangeWebCam}
                name="WebCam"
                required
                isInvalid={state?.validated && !(typeof state?.listing?.is_webcam === "boolean")}
              />
              <Form.Control.Feedback type="invalid">* Please Select WebCam</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.display_type
                    ? {
                        label: state?.listing?.display_type,
                        value: state?.listing?.fk_display_type_id,
                      }
                    : null
                }
                label={"Display Type"}
                important
                placeholder="Select Display Type"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("display_type")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeDisplayType}
                name="Display Type"
                required
                isInvalid={state?.validated && !state?.listing?.display_type}
              />
              <Form.Control.Feedback type="invalid">* Please Select Display Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.resolution
                    ? {
                        label: state?.listing?.resolution,
                        value: state?.listing?.fk_resolution_id,
                      }
                    : null
                }
                label={"Resolution"}
                important
                placeholder="Select Resolution"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("resolution")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeResolution}
                name="Resolution"
                required
                isInvalid={state?.validated && !state?.listing?.resolution}
              />
              <Form.Control.Feedback type="invalid">* Please Select Resolution</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={SearchableSelect}
                value={typeof state?.listing?.is_smart_tv === "boolean" ? (state?.listing?.is_smart_tv ? { value: true, label: "Yes" } : { value: false, label: "No" }) : null}
                label={"Smart TV"}
                important
                placeholder="Select Smart TV"
                searchable={false}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                handleChange={handleChangeSmartTV}
                name="Smart TV"
                required
                isInvalid={state?.validated && !(typeof state?.listing?.is_smart_tv === "boolean")}
              />
              <Form.Control.Feedback type="invalid">* Please Select Smart TV</Form.Control.Feedback>
            </Form.Group>
          </Col>
          {state?.listing?.is_smart_tv ? (
            <>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group>
                  <Form.Control
                    as={SearchableSelect}
                    value={typeof state?.listing?.is_tv_certificated === "boolean" ? (state?.listing?.is_tv_certificated ? { value: true, label: "Yes" } : { value: false, label: "No" }) : null}
                    label={"Certificated"}
                    important
                    placeholder="Select Certificated"
                    searchable={false}
                    options={[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ]}
                    handleChange={handleChangeCertificated}
                    name="Certificated"
                    required
                    isInvalid={state?.validated && !(typeof state?.listing?.is_tv_certificated === "boolean")}
                  />
                  <Form.Control.Feedback type="invalid">* Please Select Certificated</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group>
                  <Form.Control
                    as={ReactSelect}
                    value={
                      state?.listing?.operating_system
                        ? {
                            label: state?.listing?.operating_system,
                            value: state?.listing?.fk_operating_system_id,
                          }
                        : null
                    }
                    label={"Operating System"}
                    important
                    placeholder="Select Operating System"
                    searchable={true}
                    options={(e) => {
                      return new Promise((resolve, reject) => {
                        getcomponent("operating_system")
                          .then((res) => {
                            const value = res.data.success.data
                              .filter((item) => item.label !== "nil")
                              .map((loc) => ({
                                value: loc.id,
                                label: loc.label,
                              }));
                            const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                            resolve(filteredValue);
                          })
                          .catch((error) => {
                            throw new Error(error);
                          });
                      });
                    }}
                    handleChange={handleChangeOperatingSystem}
                    name="Operating System"
                    required
                    isInvalid={state?.validated && !state?.listing?.operating_system}
                  />
                  <Form.Control.Feedback type="invalid">* Please Select Operating System</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </>
          ) : (
            ""
          )}
          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Additional Notes <span className="text-muted">(Optional)</span>
              </Form.Label>
              <Form.Control className="py-3 pe-5" type="text" as="textarea" rows={3} placeholder="Enter Additional Information" value={state?.listing_optional?.notes} onChange={handleChangeAdditionalNotes} name="notes" isInvalid={state?.listing_optional?.notes?.length > 500} />
              <Form.Control.Feedback type="invalid">Character Limit Exceeded: Please limit your input to a maximum of 500 characters.</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={12}>
            <Form.Group>
              <Markdown
                important
                value={state?.listing?.description ? state?.listing?.description : ""}
                autoGenerated={
                  state?.listing
                    ? () => {
                        try {
                          const description = generateShortDescription("TV / Monitor", {
                            ...state?.listing_color,
                            ...state?.listing,
                            ...(state?.listing_port
                              ? {
                                  ports: state?.listing_port.map((port) => port.port),
                                }
                              : {}),
                          });
                          setState((prev) => ({
                            ...prev,
                            listing: {
                              ...prev.listing,
                              description: description,
                            },
                          }));
                        } catch (error) {
                          setIsErrorInGeneratingDescription(true);
                        }
                      }
                    : undefined
                }
                onEditorChange={(content, editor) => {
                  setState((prev) => ({
                    ...prev,
                    listing: {
                      ...prev.listing,
                      description: content,
                    },
                  }));
                }}
                isInvalid={(state?.validated && !state?.listing?.description) || isErrorInGeneratingDescription}
              />
              <Form.Control value={state?.listing?.description} name="Description" onChange={() => {}} hidden required isInvalid={(state.validated && convert(state?.listing?.description)?.length < 50) || isErrorInGeneratingDescription} />

              <Form.Control.Feedback type="invalid">{isErrorInGeneratingDescription ? "Please Select All Required Fields" : convert(state?.listing?.description)?.length < 50 ? "Description Must be more than 50 characters." : "Please Enter Description"}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={12}>
            <Form.Group>
              <Form.Label className="mb-0">
                Upload Image<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <ImageUploader ref={imageRef} selectedFiles={state?.images} setSelectedFiles={handleChangeImages} isInvalid={state?.validated && !state?.images?.length > 0} />
              <Form.Control value={state?.images?.length > 0 ? state?.images : ""} name="Image" onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please Enter Atleast One Image</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={12}>
            <Form.Group>
              <Form.Label className="mb-0 mt-3">Upload Video</Form.Label>
              <VideoUploader ref={videoRef} selectedFile={state?.video} setSelectedFile={handleChangeVideo} isInvalid={state?.validated && !state?.images?.length > 0} disabled={!state?.listing?.model} />
              <Form.Control value={state?.images?.length > 0 ? state?.images : ""} name="Image" onChange={() => {}} hidden />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
});

TvMonitorForm.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
};

export default TvMonitorForm;
