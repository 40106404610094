import { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col } from "react-bootstrap";
import SearchableSelect from "../../SearchableSelect";
import Markdown from "../../MarkdownEditor/Markdown";
import ImageUploader from "../../ImageUploader/ImageUploader";
import ReactSelect from "../../ReactSelect";
import { getcomponent } from "../../InventoryForms/utils";
import { convert } from "html-to-text";
import VideoUploader from "../../VideoUploader/VideoUploader";
import { generateShortDescription } from "./generateDescription";
import style from "./validationModal.module.css";

const LaptopForm = forwardRef(({ state, setState }, ref) => {
  const { imageRef, videoRef } = ref;

  const [titlePlaceholder, setTitlePlaceholder] = useState("e.g. Lenove Ideapad 3");
  const [isTitleCustomize, setIsTitleCustomize] = useState(true);
  const [customizeTitle, setCustomizeTitle] = useState({
    fixedPart: "",
    dynamicPart: "",
  });
  const [isTitleCharacterLimitExceeded, setIsTtitleCharacterLimitExceeded] = useState(false);
  const [isCustomizeTitleFocused, setIsCustomizeTitleFocused] = useState(false);
  const [isErrorInGeneratingDescription, setIsErrorInGeneratingDescription] = useState(false);

  const [storageType, setStorageType] = useState(
    state?.listing?.storage_hdd && state?.listing?.storage_ssd
      ? {
          value: "both",
          label: "Both",
        }
      : state?.listing?.storage_hdd
      ? {
          value: "hdd",
          label: "HDD",
        }
      : {
          value: "ssd",
          label: "SSD",
        },
  );

  const handleChangeScreenSize = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        screen_size: e.label,
        fk_screen_size_id: e.value,
      },
    }));
  };
  const handleChangeRam = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        ram: e.label,
        fk_ram_id: e.value,
      },
    }));
  };
  const handleChangeRamType = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        ram_type: e.label,
        fk_ram_type_id: e.value,
      },
    }));
  };

  const handleChangeStorageType = (e) => {
    if (e.label === "SSD") {
      setStorageType(e);
      setState((prev) => {
        const listing = prev?.listing;
        if (listing) {
          delete listing?.storage_hdd;
          delete listing?.fk_storage_hdd_id;
        }
        return {
          ...prev,
          listing,
        };
      });
    } else if (e.label === "HDD") {
      setStorageType(e);
      setState((prev) => {
        const listing = prev.listing;
        if (listing) {
          delete listing?.storage_ssd;
          delete listing?.fk_storage_ssd_id;
        }
        return {
          ...prev,
          listing,
        };
      });
    } else {
      setStorageType(e);
    }
  };
  const handleChangeStorageSSD = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        storage_ssd: e.label,
        fk_storage_ssd_id: e.value,
      },
    }));
  };
  const handleChangeStorageHDD = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        storage_hdd: e.label,
        fk_storage_hdd_id: e.value,
      },
    }));
  };
  const handleChangeLaptopType = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        laptop_type: e.label,
        fk_laptop_type_id: e.value,
      },
    }));
  };
  const handleChangeBrand = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        brand: e.label,
        fk_brand_id: e.value,
      },
    }));
  };

  const handleChangeSpeaker = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        speaker: e.label,
        fk_speaker_id: e.value,
      },
    }));
  };
  const handleChangeScreenType = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        screen_type: e.label,
        fk_screen_type_id: e.value,
      },
    }));
  };
  const handleChangeFingerPrint = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        finger_print: e.label,
        fk_finger_print_id: e.value,
      },
    }));
  };
  const handleChangeBattery = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        battery: e.label,
        fk_battery_id: e.value,
      },
    }));
  };
  const handleChangeResolution = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        resolution: e.label,
        fk_resolution_id: e.value,
      },
    }));
  };
  const handleChangeKeyboard = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        keyboard: e.label,
        fk_keyboard_id: e.value,
      },
    }));
  };
  const handleChangeProcessor = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        processor: e.label,
        fk_processor_id: e.value,
      },
    }));
  };
  const handleChangeGeneration = (e) => {
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        generation: e.label,
        fk_generation_id: e.value,
      },
    }));
  };
  const handleChangeGraphicCardType = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        graphic_card_type: e.label,
        fk_graphic_card_type_id: e.value,
      },
    }));
  };
  const handleChangeGraphicCardName = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        graphic_card_name: e.label,
        fk_graphic_card_name_id: e.value,
      },
    }));
  };
  const handleChangeGraphicCardMemory = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        graphic_card_memory: e.label,
        fk_graphic_card_memory_id: e.value,
      },
    }));
  };
  const handleChangeRefreshRate = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        refresh_rate: e.label,
        fk_refresh_rate_id: e.value,
      },
    }));
  };
  const handleChangeCameraType = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        camera_type: e.label,
        fk_camera_type_id: e.value,
      },
    }));
  };

  const handleChangeBacklit = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        is_backlit: e.value,
      },
    }));
  };
  const handleChangeTouchScreen = (e) => {
    setState((prev) => ({
      ...prev,
      listing_optional: {
        ...prev.listing_optional,
        is_touch_screen: e.value,
      },
    }));
  };

  const handleChangeColor = (e) => {
    setState((prev) => ({
      ...prev,
      listing_color: {
        color: e.label,
        fk_color_id: e.value,
      },
    }));
  };
  const handleChangePort = (e, { action }) => {
    const list = e.map((item) => {
      return { port: item.label, fk_port_id: item.value };
    });
    if (action === "clear") {
      setState((prev) => {
        delete prev.listing_port;
        return { ...prev };
      });
    } else if (action === "select-option" || action === "remove-value") {
      if (list.length > 0) {
        setState((prev) => ({
          ...prev,
          listing_port: list,
        }));
      } else {
        setState((prev) => {
          delete prev.listing_port;

          return { ...prev };
        });
      }
    }
  };
  const handleChangeModel = (e) => {
    const cleanedValue = e.target.value
      .replace(/[^a-zA-Z0-9 \-\/'+]/g, "")
      .replace(/\s+/g, " ")
      .replace(/\s+/g, " ");
    setState((prev) => ({
      ...prev,
      listing: {
        ...prev.listing,
        model: cleanedValue,
        fk_model_id: -1,
      },
    }));
  };
  const handleChangeAdditionalNotes = (e) => {
    setState((prev) => {
      return {
        ...prev,
        listing_optional: {
          ...prev.listing_optional,
          notes: e.target.value,
        },
      };
    });
  };
  const handleChangeImages = (event) => {
    if (typeof event === "function") {
      setState((prev) => ({
        ...prev,
        images: event(prev.images),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        images: event,
      }));
    }
  };

  const handleChangeVideo = (event) => {
    if (typeof event === "function") {
      setState((prev) => ({
        ...prev,
        video: event(prev.video),
      }));
    } else {
      if (event === null) {
        setState((prev) => {
          if (prev?.video) {
            delete prev?.video;
          }
          return prev;
        });
      } else {
        setState((prev) => ({
          ...prev,
          video: event,
        }));
      }
    }
  };
  const validateInput = (event) => {
    const regex = /^[a-zA-Z0-9 \-\/']*$/;

    const input = event.target.value + event.key;
    if (!regex.test(input)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    console.log("CHANGE HAPPENED IN THIS USE EFFECT");
    if (isTitleCustomize) {
      if (state?.listing?.brand && state?.listing?.model) {
        const autoFillPart = `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand) : ""} ${state?.listing?.model?.trim()}`;
        setCustomizeTitle((prev) => ({
          ...prev,
          fixedPart: autoFillPart,
        }));
        setState((prev) => ({
          ...prev,
          title: autoFillPart + `${customizeTitle.dynamicPart?.length > 0 ? " " + customizeTitle.dynamicPart : ""}`,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          title: "",
        }));
        setCustomizeTitle({ fixedPart: "", dynamicPart: "" });
        setTitlePlaceholder("Fill all the fields to Add the title....");
      }
    } else {
      if (
        state?.listing?.brand &&
        state?.listing?.model &&
        state?.listing?.ram &&
        (storageType?.value === "ssd" || storageType?.value === "both" ? state?.listing?.storage_ssd : true) &&
        (storageType?.value === "hdd" || storageType?.value === "both" ? state?.listing?.storage_hdd : true) &&
        state?.listing?.generation &&
        state?.listing?.processor &&
        state?.listing?.screen_size &&
        state?.listing_color?.color
      ) {
        setState((prev) => ({
          ...prev,
          title: `${state?.listing?.brand ? (state?.listing?.brand === "Other" ? "" : state?.listing?.brand + " ") : ""}${state?.listing?.model?.trim()}${state?.listing?.ram !== 0 ? " " + state.listing.ram + "GB" : ""}${
            state?.listing?.storage_ssd && state?.listing?.storage_ssd !== 0 ? " " + state.listing.storage_ssd + "GB SSD" : ""
          }${state?.listing?.storage_hdd && state?.listing?.storage_hdd !== 0 ? " " + state.listing.storage_hdd + "GB HDD" : ""}${state?.listing_color?.color && state?.listing_color?.color !== "Other" ? " " + state.listing_color.color : ""}${
            state?.listing?.generation && state?.listing?.generation !== "Other" ? " " + state.listing.generation : ""
          }${state?.listing?.processor && state?.listing?.processor !== "Other" ? " " + state.listing.processor : ""}${state?.listing?.screen_size && state?.listing?.screen_size !== "0.00" ? " " + state?.listing?.screen_size + " Inches" : ""}`,
        }));
      } else {
        if (state?.listing?.brand || state?.listing?.model || state?.listing?.ram || state?.listing?.storage_ssd || state?.listing?.storage_hdd || state?.listing?.generation || state?.listing?.processor || state?.listing?.screen_size || state?.listing_color?.color) {
          setTitlePlaceholder("Fill all the fields to generate the title....");
          setState((prev) => {
            delete prev.title;
            return prev;
          });
        }
        setState((prev) => {
          return {
            ...prev,
            title: "",
          };
        });
      }
    }
  }, [
    state?.listing?.brand,
    state?.listing?.model,
    state?.listing?.ram,
    state?.listing?.storage_ssd,
    state?.listing?.storage_hdd,
    state?.listing?.generation,
    state?.listing?.processor,
    state?.listing?.screen_size,
    state?.listing_color?.color,
    storageType,
    isTitleCustomize,
    customizeTitle.dynamicPart,
  ]);

  return (
    <>
      <Form noValidate validated={state?.validated} className="m-3">
        <Row className="gx-0 gx-sm-3 gx-lg-5">
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.laptop_type
                    ? {
                        label: state?.listing?.laptop_type,
                        value: state?.listing?.fk_laptop_type_id,
                      }
                    : null
                }
                name="Laptop Type"
                required
                label={"Laptop Type"}
                important
                placeholder="Select Laptop Type"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("laptop_type")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeLaptopType}
                isInvalid={state?.validated && !state?.listing?.laptop_type}
              />
              <Form.Control.Feedback type="invalid">* Please Select Laptop Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.brand
                    ? {
                        label: state?.listing?.brand,
                        value: state?.listing?.fk_brand_id,
                      }
                    : null
                }
                label={"Brand"}
                important
                placeholder="Select brand"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("brand", { is_laptop: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeBrand}
                name="Brand"
                required
                isInvalid={state?.validated && !state?.listing?.brand}
              />
              <Form.Control.Feedback type="invalid">* Please Select Brand </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group className="" controlId="model">
              <Form.Label className="mb-0">
                Model<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control className="py-2" type="text" onKeyDown={validateInput} placeholder="e.g. dell" value={state?.listing?.model ? state?.listing?.model : ""} onChange={handleChangeModel} name="model" required />

              <Form.Control.Feedback type="invalid">* Please Select Model </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Title<span style={{ color: "red" }}>*</span>
                <Form.Check
                  type="checkbox"
                  style={{ display: "inline", paddingLeft: "5px" }}
                  checked={!isTitleCustomize}
                  onChange={(e) => {
                    setIsTitleCustomize(!e.target.checked);
                  }}
                />
                <span className={`ps-1 text-muted`}>Auto-Generate Title</span>
              </Form.Label>
              {!isTitleCustomize ? (
                <Form.Control className="py-3 pe-5" type="text" placeholder={titlePlaceholder} value={state?.title} disabled={true} onChange={() => {}} name="model" required />
              ) : (
                <>
                  <div className={`p-3 form-control ${style.customizeTitleField} ${state.title ? "" : style.disabledDiv}`}>
                    {state?.title ? (
                      <>
                        <span className={style.customizeTitleFieldSpan}>{customizeTitle.fixedPart}&nbsp;</span>
                        <input
                          value={customizeTitle.dynamicPart}
                          className={`${style.customizeTitleFieldInput}`}
                          onKeyDown={validateInput}
                          onChange={(e) => {
                            const value = e.target.value;

                            const cleanedValue = value.replace(/[^a-zA-Z0-9 \-\/'+]/g, "").replace(/\s+/g, " ");
                            if (customizeTitle.fixedPart.length + cleanedValue.length <= 250) {
                              setCustomizeTitle((prev) => ({
                                ...prev,
                                dynamicPart: cleanedValue,
                              }));
                            }

                            if (customizeTitle.fixedPart.length + cleanedValue.length <= 70) {
                              setIsTtitleCharacterLimitExceeded(false);
                            } else {
                              setIsTtitleCharacterLimitExceeded(true);
                            }
                          }}
                          type="text"
                          disabled={state?.listing?.brand && state?.listing?.model ? false : true}
                          name="model"
                          required
                        />
                      </>
                    ) : (
                      <span className="text-muted">{titlePlaceholder}</span>
                    )}
                  </div>
                  {isTitleCharacterLimitExceeded ? <div className="text-danger py-2">*7-100 characters are recommended above 100 is not SEO friendly. The maximum allowed length is 250 characters.</div> : <></>}

                  <div className="alert alert-warning" role="alert">
                    Please enter "Brand and Model" before entering the title
                  </div>
                </>
              )}
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing_color?.color
                    ? {
                        label: state?.listing_color?.color,
                        value: state?.listing_color?.fk_color_id,
                      }
                    : null
                }
                name="Color"
                label={"Color"}
                important
                placeholder="Select Color"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("color", { is_laptop: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeColor}
                isInvalid={state?.validated && !state?.listing_color?.color}
                required
              />
              <Form.Control.Feedback type="invalid">Please Select Color</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.ram
                    ? {
                        label: state?.listing?.ram,
                        value: state?.listing?.fk_ram_id,
                      }
                    : null
                }
                label={"RAM (GB)"}
                important
                placeholder="Select RAM"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("ram", { is_laptop: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeRam}
                name="RAM"
                required
                isInvalid={state?.validated && !state?.listing?.ram}
              />
              <Form.Control.Feedback type="invalid">* Please Select RAM</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.ram_type
                    ? {
                        label: state?.listing?.ram_type,
                        value: state?.listing?.fk_ram_type_id,
                      }
                    : null
                }
                label={"RAM Type"}
                important
                placeholder="Select RAM Type"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("ram_type")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeRamType}
                name="RAM Type"
                required
                isInvalid={state?.validated && !state?.listing?.ram_type}
              />
              <Form.Control.Feedback type="invalid">* Please Select RAM Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <SearchableSelect
                label={"Storage Type"}
                important
                placeholder="Select Storage Type"
                searchable={false}
                defaultValue={{ value: "both", label: "Both" }}
                options={[
                  { value: "both", label: "Both" },
                  { value: "ssd", label: "SSD" },
                  { value: "hdd", label: "HDD" },
                ]}
                handleChange={handleChangeStorageType}
                value={storageType}
              />
              <Form.Control value={storageType?.value ? storageType?.value : ""} hidden onChange={() => {}} name="Storage Type" required />
              <Form.Control.Feedback type="invalid">* Please Select Storage Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          {storageType?.value === "both" || storageType?.value === "ssd" ? (
            <Col className="py-3" sm="6" md={6}>
              <Form.Group>
                <Form.Control
                  as={ReactSelect}
                  value={
                    state?.listing?.storage_ssd
                      ? {
                          label: state?.listing?.storage_ssd,
                          value: state?.listing?.fk_storage_ssd_id,
                        }
                      : null
                  }
                  label={"SSD (GB)"}
                  important
                  placeholder="Select SSD"
                  searchable={true}
                  options={(e) => {
                    return new Promise((resolve, reject) => {
                      getcomponent("ssd", { is_laptop: true }, true)
                        .then((res) => {
                          const value = res.data.success.data
                            .filter((item) => item.label !== 0)
                            .map((loc) => ({
                              value: loc.id,
                              label: loc.label,
                            }));

                          const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                          resolve(filteredValue);
                        })
                        .catch((error) => {
                          throw new Error(error);
                        });
                    });
                  }}
                  handleChange={handleChangeStorageSSD}
                  name="SSD"
                  required
                  isInvalid={state?.validated && !state?.listing?.storage_ssd}
                />
                <Form.Control.Feedback type="invalid">* Please Select SSD</Form.Control.Feedback>
              </Form.Group>
            </Col>
          ) : (
            ""
          )}
          {storageType?.value === "both" || storageType?.value === "hdd" ? (
            <Col className="py-3" sm="6" md={6}>
              <Form.Group>
                <Form.Control
                  as={ReactSelect}
                  value={
                    state?.listing?.storage_hdd
                      ? {
                          label: state?.listing?.storage_hdd,
                          value: state?.listing?.fk_storage_hdd_id,
                        }
                      : null
                  }
                  label={"HDD (GB)"}
                  important
                  placeholder="Select HDD"
                  searchable={true}
                  options={(e) => {
                    return new Promise((resolve, reject) => {
                      getcomponent("hdd", { is_laptop: true }, true)
                        .then((res) => {
                          const value = res.data.success.data
                            .filter((item) => item.label !== 0)
                            .map((loc) => ({
                              value: loc.id,
                              label: loc.label,
                            }));

                          const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                          resolve(filteredValue);
                        })
                        .catch((error) => {
                          throw new Error(error);
                        });
                    });
                  }}
                  handleChange={handleChangeStorageHDD}
                  name="HDD"
                  required
                  isInvalid={state?.validated && !state?.listing?.storage_hdd}
                />
                <Form.Control.Feedback type="invalid">* Please Select HDD</Form.Control.Feedback>
              </Form.Group>
            </Col>
          ) : (
            ""
          )}
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.screen_size
                    ? {
                        label: state?.listing?.screen_size,
                        value: state?.listing?.fk_screen_size_id,
                      }
                    : null
                }
                label={"Screen Size (Inches)"}
                important
                placeholder="Select Screen Size"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("screen_size", { is_laptop: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeScreenSize}
                name="Screen Size"
                required
                isInvalid={state?.validated && !state?.listing?.screen_size}
              />
              <Form.Control.Feedback type="invalid">* Please Select Screen Size</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.processor
                    ? {
                        label: state?.listing?.processor,
                        value: state?.listing?.fk_processor_id,
                      }
                    : null
                }
                label={"Processor"}
                important
                placeholder="Select Processor"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("processor", { is_laptop: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeProcessor}
                name="Processor"
                isInvalid={state?.validated && !state?.listing?.processor}
                required
              />
              <Form.Control.Feedback type="invalid">* Please Select Processor</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <Form.Control
                as={ReactSelect}
                value={
                  state?.listing?.generation
                    ? {
                        label: state?.listing?.generation,
                        value: state?.listing?.fk_generation_id,
                      }
                    : null
                }
                label={"Generation"}
                important
                placeholder="Select Generation"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("generation")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeGeneration}
                name="Generation"
                isInvalid={state?.validated && !state?.listing?.generation}
                required
              />
              <Form.Control.Feedback type="invalid">* Please Select Generation</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <SearchableSelect
                label={"Backlit"}
                placeholder="Select Backlit"
                searchable={false}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                handleChange={handleChangeBacklit}
                value={typeof state?.listing_optional?.is_backlit === "boolean" ? (state?.listing_optional?.is_backlit === true ? { value: true, label: "Yes" } : { value: false, label: "No" }) : null}
              />
              <Form.Control value={state?.listing_optional?.is_backlit} hidden onChange={() => {}} name="Backlit" />
              <Form.Control.Feedback type="invalid">* Please Select Backlit</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Port"}
                placeholder="Select Port"
                searchable={true}
                multiselect={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("ports", { is_laptop: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangePort}
                value={
                  state?.listing_port && state?.listing_port?.length > 0
                    ? state?.listing_port?.map((item) => {
                        return {
                          label: item.port,
                          value: item.fk_port_id,
                        };
                      })
                    : null
                }
              />
              <Form.Control value={state?.listing_port && state?.listing_port?.length > 0} hidden onChange={() => {}} name="Port" />
              <Form.Control.Feedback type="invalid">* Please Select Port</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Speaker"}
                placeholder="Select Speaker"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("speaker")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeSpeaker}
                value={
                  state?.listing_optional?.speaker
                    ? {
                        label: state?.listing_optional?.speaker,
                        value: state?.listing_optional?.fk_speaker_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.speaker} hidden onChange={() => {}} name="Speaker" />
              <Form.Control.Feedback type="invalid">* Please Select Speaker</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Screen Type"}
                placeholder="Select Screen Type"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("screen_type")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeScreenType}
                value={
                  state?.listing_optional?.screen_type
                    ? {
                        label: state?.listing_optional?.screen_type,
                        value: state?.listing_optional?.fk_screen_type_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.screen_type} hidden onChange={() => {}} name="Screen Type" />
              <Form.Control.Feedback type="invalid">* Please Select Screen Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Camera Type"}
                placeholder="Select Camera Type"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("camera_type")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeCameraType}
                value={
                  state?.listing_optional?.camera_type
                    ? {
                        label: state?.listing_optional?.camera_type,
                        value: state?.listing_optional?.fk_camera_type_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.camera_type} hidden onChange={() => {}} name="Camera Type" />
              <Form.Control.Feedback type="invalid">* Please Select Camera Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Finger Print"}
                placeholder="Select Finger Print"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("finger_print", { is_laptop: true }, true)
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeFingerPrint}
                value={
                  state?.listing_optional?.finger_print
                    ? {
                        label: state?.listing_optional?.finger_print,
                        value: state?.listing_optional?.fk_finger_print_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.finger_print} hidden onChange={() => {}} name="Finger Print" />
              <Form.Control.Feedback type="invalid">* Please Select Finger Print</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Battery"}
                placeholder="Select Battery"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("battery")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeBattery}
                value={
                  state?.listing_optional?.battery
                    ? {
                        label: state?.listing_optional?.battery,
                        value: state?.listing_optional?.fk_battery_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.battery} hidden onChange={() => {}} name="Battery" />
              <Form.Control.Feedback type="invalid">* Please Select Battery</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Resolution"}
                placeholder="Select Resolution"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("resolution")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeResolution}
                value={
                  state?.listing_optional?.resolution
                    ? {
                        label: state?.listing_optional?.resolution,
                        value: state?.listing_optional?.fk_resolution_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.resolution} hidden onChange={() => {}} name="Resolution" />
              <Form.Control.Feedback type="invalid">* Please Select Resolution</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <SearchableSelect
                label={"Touch Screen"}
                placeholder="Select Touch Screen"
                searchable={false}
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                handleChange={handleChangeTouchScreen}
                value={typeof state?.listing_optional?.is_touch_screen === "boolean" ? (state?.listing_optional?.is_touch_screen === true ? { value: true, label: "Yes" } : { value: false, label: "No" }) : null}
              />
              <Form.Control value={state?.listing_optional?.is_touch_screen} hidden onChange={() => {}} name="Touch Screen" />
              <Form.Control.Feedback type="invalid">* Please Select Touch Screen</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Keyboard"}
                placeholder="Select Keyboard"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("keyboard")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeKeyboard}
                value={
                  state?.listing_optional?.keyboard
                    ? {
                        label: state?.listing_optional?.keyboard,
                        value: state?.listing_optional?.fk_keyboard_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.keyboard} hidden onChange={() => {}} name="Keyboard" />
              <Form.Control.Feedback type="invalid">* Please Select Keyboard</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Graphic Card Type"}
                placeholder="Select Graphic Card Type"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("graphic_card_type")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeGraphicCardType}
                value={
                  state?.listing_optional?.graphic_card_type
                    ? {
                        label: state?.listing_optional?.graphic_card_type,
                        value: state?.listing_optional?.fk_graphic_card_type_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.graphic_card_type} hidden onChange={() => {}} name="Graphic Card Type" />
              <Form.Control.Feedback type="invalid">* Please Select Graphic Card Type</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Graphic Card Name"}
                placeholder="Select Graphic Card Name"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("graphic_card_name")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== "nil")
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));
                        const filteredValue = value.filter((loc) => loc.label.toLowerCase().includes(e.toLowerCase()));
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeGraphicCardName}
                value={
                  state?.listing_optional?.graphic_card_name
                    ? {
                        label: state?.listing_optional?.graphic_card_name,
                        value: state?.listing_optional?.fk_graphic_card_name_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.graphic_card_name} hidden onChange={() => {}} name="Graphic Card Name" />
              <Form.Control.Feedback type="invalid">* Please Select Graphic Card Name</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Graphic Card Capacity (MB)"}
                placeholder="Select Graphic Card Capacity"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("graphic_card_memory")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeGraphicCardMemory}
                value={
                  state?.listing_optional?.graphic_card_memory
                    ? {
                        label: state?.listing_optional?.graphic_card_memory,
                        value: state?.listing_optional?.fk_graphic_card_memory_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.graphic_card_memory} hidden onChange={() => {}} name="Graphic Card Capacity" />
              <Form.Control.Feedback type="invalid">* Please Select Graphic Card Capacity</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col className="py-3" sm="6" md={6}>
            <Form.Group>
              <ReactSelect
                label={"Refresh Rate (Hz)"}
                placeholder="Select Refresh Rate of Screen"
                searchable={true}
                options={(e) => {
                  return new Promise((resolve, reject) => {
                    getcomponent("refresh_rate")
                      .then((res) => {
                        const value = res.data.success.data
                          .filter((item) => item.label !== 0)
                          .map((loc) => ({
                            value: loc.id,
                            label: loc.label,
                          }));

                        const filteredValue = value.filter((loc) => loc.label.toString().toLowerCase().includes(e.toLowerCase())).sort((a, b) => +a.label - +b.label);
                        resolve(filteredValue);
                      })
                      .catch((error) => {
                        throw new Error(error);
                      });
                  });
                }}
                handleChange={handleChangeRefreshRate}
                value={
                  state?.listing_optional?.refresh_rate
                    ? {
                        label: state?.listing_optional?.refresh_rate,
                        value: state?.listing_optional?.fk_refresh_rate_id,
                      }
                    : null
                }
              />
              <Form.Control value={state?.listing_optional?.refresh_rate} hidden onChange={() => {}} name="Graphic Card Capacity" />
              <Form.Control.Feedback type="invalid">* Please Select refresh_rate</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col className="py-2" md={12}>
            <Form.Group>
              <Form.Label>
                Additional Notes <span className="text-muted">(Optional)</span>
              </Form.Label>
              <Form.Control className="py-3 pe-5" type="text" as="textarea" rows={3} placeholder="Enter Additional Information" value={state?.listing_optional?.notes} onChange={handleChangeAdditionalNotes} name="notes" isInvalid={state?.listing_optional?.notes?.length > 500} />
              <Form.Control.Feedback type="invalid">Character Limit Exceeded: Please limit your input to a maximum of 500 characters.</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12} md={12}>
            <Form.Group>
              <Markdown
                important
                value={state?.listing?.description ? state?.listing?.description : ""}
                autoGenerated={
                  state?.listing
                    ? () => {
                        try {
                          setIsErrorInGeneratingDescription(false);
                          const description = generateShortDescription("Laptop", {
                            ...state?.listing_color,
                            ...state?.listing,
                            ...(state?.listing_optional ? state?.listing_optional : {}),
                            ...(state?.listing_port
                              ? {
                                  ports: state?.listing_port.map((port) => port.port),
                                }
                              : {}),
                          });
                          setState((prev) => ({
                            ...prev,
                            listing: {
                              ...prev.listing,
                              description: description,
                            },
                          }));
                        } catch (error) {
                          setIsErrorInGeneratingDescription(true);
                        }
                      }
                    : undefined
                }
                onEditorChange={(content, editor) => {
                  setState((prev) => ({
                    ...prev,
                    listing: {
                      ...prev.listing,
                      description: content,
                    },
                  }));
                }}
                isInvalid={(state?.validated && !state?.listing?.description) || isErrorInGeneratingDescription}
              />
              <Form.Control value={state?.listing?.description} name="Description" onChange={() => {}} hidden required isInvalid={(state.validated && convert(state?.listing?.description)?.length < 50) || isErrorInGeneratingDescription} />

              <Form.Control.Feedback type="invalid">{isErrorInGeneratingDescription ? "Please Select All Required Fields" : convert(state?.listing?.description)?.length < 50 ? "Description Must be more than 50 characters." : "Please Enter Description"}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={12}>
            <Form.Group>
              <Form.Label className="mb-0">
                Upload Image<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <ImageUploader ref={imageRef} selectedFiles={state?.images} setSelectedFiles={handleChangeImages} isInvalid={state?.validated && !state?.images?.length > 0} />
              <Form.Control value={state?.images?.length > 0 ? state?.images : ""} name="Image" onChange={() => {}} hidden required />
              <Form.Control.Feedback type="invalid">Please Enter Atleast One Image</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col sm={12} md={12}>
            <Form.Group>
              <Form.Label className="mb-0 mt-3">Upload Video</Form.Label>
              <VideoUploader ref={videoRef} selectedFile={state?.video} setSelectedFile={handleChangeVideo} isInvalid={state?.validated && !state?.images?.length > 0} disabled={!state?.listing?.model} />
              <Form.Control value={state?.images?.length > 0 ? state?.images : ""} name="Image" onChange={() => {}} hidden />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
});

LaptopForm.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
};

export default LaptopForm;
