// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listings_tabButton__0OTTN{
    height: 37px;
padding: 5px 14px;
cursor: pointer;
}

.listings_activeTabButton__V9-\\+5{
    color: #FFF;
text-align: center;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 160% */
border-radius: 6px 6px 0px 0px;
background: #8834FF;
}

.listings_inactiveTabButton__QtxKt{
    color: #8834FF;
text-align: center;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 160% */
    border-radius: 6px 6px 0px 0px;
border: 1px solid #DEDFE4;
background: #FFF;
}

.listings_listingContainer__SbLc2{
    min-height: calc(100vh - 137px);
}

@media screen and (max-width: 575px) {
    
}`, "",{"version":3,"sources":["webpack://./src/styles/listings.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB,iBAAiB;AACjB,eAAe;AACf;;AAEA;IACI,WAAW;AACf,kBAAkB;AAClB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB,EAAE,SAAS;AAC5B,8BAA8B;AAC9B,mBAAmB;AACnB;;AAEA;IACI,cAAc;AAClB,kBAAkB;AAClB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB,EAAE,SAAS;IACxB,8BAA8B;AAClC,yBAAyB;AACzB,gBAAgB;AAChB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;;AAEA","sourcesContent":[".tabButton{\n    height: 37px;\npadding: 5px 14px;\ncursor: pointer;\n}\n\n.activeTabButton{\n    color: #FFF;\ntext-align: center;\nfont-size: 15px;\nfont-style: normal;\nfont-weight: 600;\nline-height: 24px; /* 160% */\nborder-radius: 6px 6px 0px 0px;\nbackground: #8834FF;\n}\n\n.inactiveTabButton{\n    color: #8834FF;\ntext-align: center;\nfont-size: 15px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 24px; /* 160% */\n    border-radius: 6px 6px 0px 0px;\nborder: 1px solid #DEDFE4;\nbackground: #FFF;\n}\n\n.listingContainer{\n    min-height: calc(100vh - 137px);\n}\n\n@media screen and (max-width: 575px) {\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabButton": `listings_tabButton__0OTTN`,
	"activeTabButton": `listings_activeTabButton__V9-+5`,
	"inactiveTabButton": `listings_inactiveTabButton__QtxKt`,
	"listingContainer": `listings_listingContainer__SbLc2`
};
export default ___CSS_LOADER_EXPORT___;
