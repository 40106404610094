import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import AddForm from "../../Components/AddForm";
import PopUpModal from "../../Components/Modal";

import style from "../../styles/AddInventory.module.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "../../Components/ReactSelect";
import axios from "axios";
import { useSelector } from "react-redux";
import SearchableSelect from "../../Components/SearchableSelect";
import Bank from "./Bank";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";
import AddSupplierTour from "../../TourGuides/AddSupplierTour";

const AddVendor = () => {
  const { user } = useSelector((state) => state.auth);
  const user_type = user.user_type;

  const shop_id = user.shop_id;
  const user_id = user.user_id;

  const navigate = useNavigate();

  //form validation
  const [validatedVendor, setValidatedVendor] = useState(false);
  const [isValid, setIsValid] = useState(false);
  //vendors
  const [vendorName, setVendorName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [vendorLocation, setVendorLocation] = useState("");
  const [productType, setProductType] = useState([]);
  const [banks, setBanks] = useState([]);
  const [terms, setTerms] = useState("");
  const [error, setError] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [vendorOpeningBalance, setVendorOpeningBalance] = useState("");
  const [memo, setMemo] = useState("");

  const loadLocatinOptions = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.REACT_APP_API_URL + "/location/get-location-by-user/" + user_id, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
        .then((locationsRes) => {
          const locations = locationsRes.data.map(({ location_id, location, active_till }) => ({
            value: location_id,
            label: location,
            isDisabled: new Date().toISOString() >= active_till ? true : false,
          }));
          resolve(locations);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const handleVendorNameChange = (e) => {
    setVendorName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleContactNameChange = (e) => {
    setContactName(e.target.value);
  };
  const handleContactPhoneChange = (e) => {
    setContactPhone(e.target.value);
  };

  const handleChangeLocation = (e) => {
    setVendorLocation({ value: e.value, label: e.label });
  };

  const handleTermsChange = (e) => {
    setTerms(e.target.value);
  };

  const handleBankRemove = (index) => {
    setBanks(banks.filter((bank) => bank !== index));
  };

  const handleVendorOpeningBalanceChange = (e) => {
    if (e.target.value.length < 10) setVendorOpeningBalance(e.target.value);
  };

  // submit form

  const handleVendorSubmit = async (e, action) => {
    try {
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
      setValidatedVendor(true);
      const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      let emailPass = true;
      if (email?.length > 0 && !emailFormat.test(email)) {
        emailPass = false;
      }
      if (vendorName.length > 0 && productType.length > 0 && phone && emailPass && (vendorOpeningBalance > 0 && !vendorLocation ? false : true)) {
        const vendors = {
          shop_id: shop_id,
          vendor_name: vendorName,
          business_email: email,
          business_phone: phone,
          contact_person_name: contactName,
          contact_number: contactPhone,
          product_types: productType.map((obj) => obj.value).join(","),
          vendor_opening_balance: vendorOpeningBalance > 0 ? vendorOpeningBalance : 0,
          terms: terms,
        };
        const vendorBankDetail = banks.map((bank) => ({
          account_holder_name: bank.accountName,
          account_number: bank.accountNumber,
          bank_name: bank.bankName,
          email: bank.accountEmail,
        }));
        const purchaseDetails = {
          memo: vendorOpeningBalance > 0 ? memo : "",
          location_id: vendorOpeningBalance > 0 ? vendorLocation?.value : "",
        };

        setIsClicked(true);

        const data = await axios.post(
          process.env.REACT_APP_API_URL + "/vendors/register",
          {
            vendors: vendors,
            banks: vendorBankDetail,
            purchase: purchaseDetails,
          },
          { withCredentials: true }
        );
        if (data) {
          if (action === "Save") {
            navigate("/supplier");
          }
          if (action === "Purchase") {
            navigate("/supplier/purchase/" + data.data.vendorId + "/add");
          }
        }
      }
    } catch (error) {
      setIsClicked(false);
      setError(error.response?.data.error.message);
    }
  };

  const [add_bank_modal_show, setAddBankModalShow] = useState(false);

  return (
    <>
      {user?.tour && !user?.tour?.AddVendor && <AddSupplierTour setAddBankModalShow={setAddBankModalShow} />}
      <AddForm>
        <div className="d-flex justify-content-between align-items-center px-5">
          <h5>Add Supplier</h5>
        </div>
        <hr />
        <div className={`${style.formMain}`} id="supplier-form">
          <Form noValidate validated={validatedVendor} onSubmit={handleVendorSubmit}>
            <hr className={`${style.borderedHr}`} />

            <Row className="">
              <Col className="py-3" sm="6" md={6}>
                <Form.Group className="w-100" controlId="formBasicShopName" id="supplier-form-name">
                  <label>
                    Supplier Name<span className="text-danger">*</span>
                  </label>
                  <Form.Control value={vendorName} className="p-2" onChange={handleVendorNameChange} name="vendor_name" pattern="^^[A-Za-z ]+$" type="text" placeholder="e.g. XYZ " required />
                  <Form.Control.Feedback type="invalid">Please Enter Supplier Name.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group className="w-100" controlId="formBasicShopName" id="supplier-form-email">
                  <label>Business Email </label>
                  <Form.Control value={email} onChange={handleEmailChange} className="p-2" name="business_email" type="email" pattern="[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="e.g. example@mail.com" />
                  <Form.Control.Feedback type="invalid">Invalid Email.</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col className="py-3" sm="6" md={6}>
                <Form.Group className="w-100" controlId="formBasicShopName" id="supplier-form-number">
                  <label>
                    Business Number <span className="text-danger">*</span>{" "}
                  </label>
                  {/* <Form.Control value={phone} onChange={handlePhoneChange} className="p-2" name='business_phone' type="text" placeholder="e.g. xxx xxxxxxx" /> */}
                  <PhoneInput value={phone} onChange={handlePhoneChange} className="p-2" name="business_phone" placeholder="+92-3XX-XXXXXXX" isInvalid={phone.includes("_")} required />
                  {validatedVendor && <Form.Control.Feedback type="invalid">Please Enter Business Number.</Form.Control.Feedback>}
                </Form.Group>
              </Col>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group id="supplier-form-product-types">
                  <SearchableSelect
                    multiselect
                    placeholder="Select"
                    label="Product Type"
                    important
                    searchable={false}
                    options={[
                      { value: "laptops", label: "Laptops" },
                      { value: "mobiles", label: "Mobiles" },
                      { value: "tabs", label: "Tabs" },
                      {
                        value: "desktop computers",
                        label: "Desktop Computers",
                      },
                      { value: "leds", label: "LEDs" },
                      { value: "accessories", label: "Accessories" },
                    ]}
                    closeMenuOnSelect={false}
                    handleChange={(e) => setProductType(e)}
                  />
                  <Form.Control value={productType} onChange={() => {}} hidden required />
                  <Form.Control.Feedback type="invalid">Please select Product Type.</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group className="w-100" controlId="formBasicShopName" id="supplier-form-contact-person-name">
                  <label>Contact Person Name </label>
                  <Form.Control value={contactName} onChange={handleContactNameChange} className="p-2" name="contact_person_name" pattern="^^[A-Za-z ]+$" type="text" placeholder="Contact Person Name" />
                </Form.Group>
              </Col>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group className="w-100" controlId="formBasicShopName" id="supplier-form-contact-person-number">
                  <label>Contact Number </label>
                  {/* <Form.Control value={contactPhone} onChange={handleContactPhoneChange} className="p-2" name='business_email' type="text" placeholder="e.g. xxx xxxxxxx" /> */}
                  <PhoneInput value={contactPhone} onChange={handleContactPhoneChange} className="p-2" name="business_email" placeholder="+92-3XX-XXXXXXX" isInvalid={contactPhone.includes("_")} />
                  {validatedVendor && <Form.Control.Feedback type="invalid">Please Enter Contact Number.</Form.Control.Feedback>}
                </Form.Group>
              </Col>
              <Col className="py-3" sm="6" md={6}>
                <Form.Group className="mb-3" controlId="price" id="supplier-form-supplier-opening-balance">
                  <Form.Label className="mb-0">Supplier Opening Balance</Form.Label>
                  <Form.Control min={0} type="number" placeholder="e.g. 500" onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()} value={vendorOpeningBalance ? vendorOpeningBalance : ""} onChange={handleVendorOpeningBalanceChange} name="vendor_opening_balance" />
                  <Form.Control.Feedback type="invalid">Invalid Supplier Opening Balance </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {vendorOpeningBalance > 0 ? (
                <>
                  <Col className="py-3" sm="6" md={6}>
                    <Form.Group className="mb-3" controlId="price">
                      <Form.Label className="mb-0">
                        Location <span className="text-danger">*</span>
                      </Form.Label>
                      <ReactSelect placeholder="Select" value={vendorLocation} options={loadLocatinOptions} handleChange={handleChangeLocation} searchable={false} />
                      <Form.Control required value={vendorLocation} onChange={() => {}} hidden />
                      <Form.Control.Feedback type="invalid">Please Select Location.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="price">
                      <Form.Label className="mb-0">Memo</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        placeholder="Write Memo"
                        value={memo}
                        onChange={(e) => {
                          setMemo(e.target.value);
                        }}
                        name="memo"
                        pattern="^([A-Za-z ]|[0-9])+$"
                        // required
                      />

                      <Form.Control.Feedback type="invalid">Please fill Memo </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </>
              ) : (
                ""
              )}
              <Col className="d-flex align-items-end" sm="6" md={12}>
                <Form.Group className="w-100 ">
                  <label style={{ visibility: "hidden" }}>.</label>

                  <Button className="w-100 " variant="outline-primary" onClick={() => setAddBankModalShow(true)} id="supplier-form-add-bank-details">
                    Add bank details
                  </Button>

                  <span style={{ visibility: "hidden" }}>.</span>
                </Form.Group>
              </Col>
              {banks.length > 0 && (
                <>
                  <h4 style={{ color: "#39414A" }}>Banks</h4>
                  <div className="  py-3 row justify-content-around gap-2" md={12}>
                    {banks.map((bank, index) => (
                      <div className="p-4 bg-light shadow col-md-5 mt-4" key={index} style={{ borderRadius: "20px" }}>
                        <div>
                          <div className="d-flex flex-column">
                            <div className="d-flex justify-content-between">
                              <h5
                                style={{
                                  color: "#39414A",
                                  marginBottom: "0rem",
                                }}
                              >
                                {bank.accountName}
                              </h5>
                              <div>
                                <FontAwesomeIcon
                                  onClick={() => {
                                    handleBankRemove(bank);
                                  }}
                                  icon={faClose}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                            <span style={{ color: "#39414A", marginBottom: "0rem" }}>Account number: {bank.accountNumber}</span>
                            <span style={{ color: "#39414A", marginBottom: "0rem" }}>Bank:{bank.bankName}</span>

                            <p style={{ color: "#39414A" }}>{bank.accountEmail ? bank.accountEmail : ""}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <Col md={12}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" id="supplier-form-terms-and-condition">
                  <Form.Label>Terms</Form.Label>
                  <Form.Control value={terms} onChange={handleTermsChange} placeholder="Terms and Condition" as="textarea" rows={3} maxLength={300} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="py-3">
              <Col>
                <Button className="w-100" onClick={(e) => handleVendorSubmit(e, "Save")} disabled={isClicked === false ? false : true} id="supplier-form-save-button">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </AddForm>

      <PopUpModal title="Vendor bank info" show={add_bank_modal_show} onHide={() => setAddBankModalShow(false)}>
        <Bank setBanks={setBanks} error={error} close={setAddBankModalShow} />
      </PopUpModal>
    </>
  );
};

export default AddVendor;
