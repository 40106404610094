// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --blue: rgb(173, 123, 230);
    --cyan: rgb(149, 55, 255);
    --green: rgb(149, 55, 255);
  }
  .ProgressBar_statusBar__wtWth {
    width: 100%;
    height: 3px;
    position: relative;
    overflow: hidden;
  }
  .ProgressBar_statusBar__wtWth:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: -100%;
    will-change: transform;
    background: linear-gradient(90deg, var(--blue) 0%, var(--blue) 25%, var(--cyan) 25%, var(--cyan) 37.5%, var(--green) 37.5%, var(--green) 50%, var(--blue) 50%, var(--blue) 75%, var(--cyan) 75%, var(--cyan) 87.5%, var(--green) 87.5%, var(--green) 100%);
    animation: 1.3s ProgressBar_progress__4pyDL ease infinite;
  }
  
  @keyframes ProgressBar_progress__4pyDL {
    0% {
      transform: translate(0);
    }
    100% {
      transform: translate(50%);
    }
  }`, "",{"version":3,"sources":["webpack://./src/styles/ProgressBar.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,0BAA0B;EAC5B;EACA;IACE,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,gBAAgB;EAClB;EACA;IACE,kBAAkB;IAClB,WAAW;IACX,MAAM;IACN,QAAQ;IACR,SAAS;IACT,WAAW;IACX,sBAAsB;IACtB,0PAA0P;IAElP,yDAAsC;EAChD;;EAWA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[":root {\n    --blue: rgb(173, 123, 230);\n    --cyan: rgb(149, 55, 255);\n    --green: rgb(149, 55, 255);\n  }\n  .statusBar {\n    width: 100%;\n    height: 3px;\n    position: relative;\n    overflow: hidden;\n  }\n  .statusBar:before {\n    position: absolute;\n    content: \"\";\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: -100%;\n    will-change: transform;\n    background: linear-gradient(90deg, var(--blue) 0%, var(--blue) 25%, var(--cyan) 25%, var(--cyan) 37.5%, var(--green) 37.5%, var(--green) 50%, var(--blue) 50%, var(--blue) 75%, var(--cyan) 75%, var(--cyan) 87.5%, var(--green) 87.5%, var(--green) 100%);\n    -webkit-animation: 1.3s progress ease infinite;\n            animation: 1.3s progress ease infinite;\n  }\n  \n  @-webkit-keyframes progress {\n    0% {\n      transform: translate(0);\n    }\n    100% {\n      transform: translate(50%);\n    }\n  }\n  \n  @keyframes progress {\n    0% {\n      transform: translate(0);\n    }\n    100% {\n      transform: translate(50%);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusBar": `ProgressBar_statusBar__wtWth`,
	"progress": `ProgressBar_progress__4pyDL`
};
export default ___CSS_LOADER_EXPORT___;
