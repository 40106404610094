// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgetPassword_wrapper__4P8Dq {
  height: 90vh;
}

.ForgetPassword_backToLogin__mOlh0 {
  text-align: center;
  font-weight: 400;
  font-size: 19.8px;
  font-family: osicons;
}
.ForgetPassword_passwordInput__CEHfa {
  position: relative;
}

/* Style for the password input field */
.ForgetPassword_passwordValidate__b6fTa {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding-right: 40px; /* Ensure space for the eye icon button */
}

/* Style for the toggle button container */
.ForgetPassword_passwordField__\\+grow {
  position: absolute;
  top: 21px;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.ForgetPassword_passwordInput__CEHfa .form-control:invalid {
  background-position: right calc(0.375em + 1.5rem) center !important;
}
/* :global(.was-validated) :local(.passwordInput):invalid{
  background-position: right calc(0.375em + 0.1875rem) center;
} */

.ForgetPassword_formCard__cDxYb {
  /* max-width: 500px !important; */
  width: 500px;
}
@media screen and (max-width: 636px) {
  .ForgetPassword_formCard__cDxYb {
    min-width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .ForgetPassword_formCard__cDxYb {
    min-width: 300px !important;
    width: 300px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/ForgetPassword.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,kBAAkB;AACpB;;AAEA,uCAAuC;AACvC;EACE,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB,EAAE,yCAAyC;AAChE;;AAEA,0CAA0C;AAC1C;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,2BAA2B;EAC3B,eAAe;AACjB;AACA;EACE,mEAAmE;AACrE;AACA;;GAEG;;AAEH;EACE,iCAAiC;EACjC,YAAY;AACd;AACA;EACE;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE;IACE,2BAA2B;IAC3B,YAAY;EACd;AACF","sourcesContent":[".wrapper {\n  height: 90vh;\n}\n\n.backToLogin {\n  text-align: center;\n  font-weight: 400;\n  font-size: 19.8px;\n  font-family: osicons;\n}\n.passwordInput {\n  position: relative;\n}\n\n/* Style for the password input field */\n.passwordValidate {\n  border: 1px solid #ced4da;\n  border-radius: 5px;\n  padding-right: 40px; /* Ensure space for the eye icon button */\n}\n\n/* Style for the toggle button container */\n.passwordField {\n  position: absolute;\n  top: 21px;\n  right: 10px;\n  transform: translateY(-50%);\n  cursor: pointer;\n}\n.passwordInput :global(.form-control):invalid {\n  background-position: right calc(0.375em + 1.5rem) center !important;\n}\n/* :global(.was-validated) :local(.passwordInput):invalid{\n  background-position: right calc(0.375em + 0.1875rem) center;\n} */\n\n.formCard {\n  /* max-width: 500px !important; */\n  width: 500px;\n}\n@media screen and (max-width: 636px) {\n  .formCard {\n    min-width: 300px !important;\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .formCard {\n    min-width: 300px !important;\n    width: 300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ForgetPassword_wrapper__4P8Dq`,
	"backToLogin": `ForgetPassword_backToLogin__mOlh0`,
	"passwordInput": `ForgetPassword_passwordInput__CEHfa`,
	"passwordValidate": `ForgetPassword_passwordValidate__b6fTa`,
	"passwordField": `ForgetPassword_passwordField__+grow`,
	"formCard": `ForgetPassword_formCard__cDxYb`
};
export default ___CSS_LOADER_EXPORT___;
