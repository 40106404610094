// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

  .listingFilter_addButtonLink__bM1MR{
    min-width: 140px;
  }

  .listingFilter_filtersContainer__h2mJj{
    background-color: white;
    border-bottom: 1px solid #DEDFE4;
  }

  .listingFilter_collapseDiv__V9aV8{
    border:  1px solid #DEE2E6 !important;

}
.listingFilter_collapseButton__hbjei{
  color: #000 !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important; /* 128.571% */
  background: #FFF !important;
  border-radius: 0 !important;
  text-align: start !important;
  height: 50px !important;

}
.listingFilter_collapseButton__hbjei:active{
  background: #ffffffd9 !important;
}

@media screen and (max-width: 1200px) {

    .listingFilter_filtersContainer__h2mJj{
        background-color: transparent;
        border: 0;
      }

    
}

@media screen and (max-width: 575px) {
  .listingFilter_addButtonLink__bM1MR{
    min-width: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Listings/ListingFilters/listingFilter.module.css"],"names":[],"mappings":";;EAEE;IACE,gBAAgB;EAClB;;EAEA;IACE,uBAAuB;IACvB,gCAAgC;EAClC;;EAEA;IACE,qCAAqC;;AAEzC;AACA;EACE,sBAAsB;EACtB,8BAA8B;EAC9B,0BAA0B;EAC1B,6BAA6B;EAC7B,2BAA2B;EAC3B,4BAA4B,EAAE,aAAa;EAC3C,2BAA2B;EAC3B,2BAA2B;EAC3B,4BAA4B;EAC5B,uBAAuB;;AAEzB;AACA;EACE,gCAAgC;AAClC;;AAEA;;IAEI;QACI,6BAA6B;QAC7B,SAAS;MACX;;;AAGN;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":["\n\n  .addButtonLink{\n    min-width: 140px;\n  }\n\n  .filtersContainer{\n    background-color: white;\n    border-bottom: 1px solid #DEDFE4;\n  }\n\n  .collapseDiv{\n    border:  1px solid #DEE2E6 !important;\n\n}\n.collapseButton{\n  color: #000 !important;\n  font-family: Roboto !important;\n  font-size: 14px !important;\n  font-style: normal !important;\n  font-weight: 500 !important;\n  line-height: 18px !important; /* 128.571% */\n  background: #FFF !important;\n  border-radius: 0 !important;\n  text-align: start !important;\n  height: 50px !important;\n\n}\n.collapseButton:active{\n  background: #ffffffd9 !important;\n}\n\n@media screen and (max-width: 1200px) {\n\n    .filtersContainer{\n        background-color: transparent;\n        border: 0;\n      }\n\n    \n}\n\n@media screen and (max-width: 575px) {\n  .addButtonLink{\n    min-width: 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButtonLink": `listingFilter_addButtonLink__bM1MR`,
	"filtersContainer": `listingFilter_filtersContainer__h2mJj`,
	"collapseDiv": `listingFilter_collapseDiv__V9aV8`,
	"collapseButton": `listingFilter_collapseButton__hbjei`
};
export default ___CSS_LOADER_EXPORT___;
