import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const AddSupplierTour = ({ setAddBankModalShow }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const addSupplierTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "AddVendor", value: true } }));
      setTimeout(() => {
        addSupplierTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Add-Supplier",
        popover: {
          title: "Add Supplier",
          description: "Add new suppliers.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#supplier-form",
        popover: {
          title: `Add supplier form`,
          description: "A form for adding and updating a supplier.",
          side: "left",
          align: "start",
        },
      },

      {
        element: "#supplier-form-name",
        popover: {
          title: `Supplier Name`,
          description: "In this field, seller can add supplier name.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier-form-email",
        popover: {
          title: `Business Email`,
          description: "In this field, seller can add supplier business email.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier-form-number",
        popover: {
          title: `Business Number`,
          description: "In this field, seller can add supplier business number.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#supplier-form-product-types",
        popover: {
          title: `Product Types`,
          description: "In this field, seller can add supplier product types.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier-form-contact-person-name",
        popover: {
          title: `Contact Person Name`,
          description: "In this field, seller can add supplier contact person name.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#supplier-form-contact-person-number",
        popover: {
          title: `Contact Number`,
          description: "In this field, seller can add supplier contact number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier-form-supplier-opening-balance",
        popover: {
          title: `Opening Balance`,
          description: "In this field, seller can add supplier opening balance.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#supplier-form-add-bank-details",
        popover: {
          title: `Add bank details`,
          description: "From where seller can add supplier bank details.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setAddBankModalShow(true);
            setTimeout(() => {
              addSupplierTour.moveNext();
            });
          },
        },
      },
      {
        element: "#supplier-bank-details",
        popover: {
          title: `Bank Details`,
          description: "A form to add supplier bank details.",
          side: "left",
          align: "start",
          onPrevClick: () => {
            setAddBankModalShow(false);
            setTimeout(() => {
              addSupplierTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#supplier-bank-accountant-name",
        popover: {
          title: `Account Holder Name`,
          description: "In this field, seller can add supplier account holder name.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#supplier-bank-account-number",
        popover: {
          title: `Account Number`,
          description: "In this field, seller can add supplier account number.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#supplier-bank-name",
        popover: {
          title: `Bank Name`,
          description: "In this field, seller can select Bank name from the bank list.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#supplier-bank-email-address",
        popover: {
          title: `Email Address`,
          description: "In this field, seller can add supplier email address.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#supplier-bank-submit-button",
        popover: {
          title: `Submit Button`,
          description: "This button will save the bank details of the supplier.",
          side: "top",
          align: "center",
          onNextClick: () => {
            setAddBankModalShow(false);
            addSupplierTour.moveNext();
          },
        },
      },
      {
        element: "#supplier-form-terms-and-condition",
        popover: {
          title: `Terms and conditions`,
          description: "In this field, seller can add supplier terms and conditions.",
          side: "left",
          align: "start",
          onPrevClick: () => {
            setAddBankModalShow(true);
            setTimeout(() => {
              addSupplierTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#supplier-form-save-button",
        popover: {
          title: `Save Button`,
          description: "To save the supplier.",
          side: "top",
          align: "center",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "AddVendor", value: true } }));
            setTimeout(() => {
              addSupplierTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });

  useEffect(() => {
    addSupplierTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <div></div>;
};

export default AddSupplierTour;
