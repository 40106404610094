import { useState } from "react";
import ListingHeader from "../Components/Listings/ListingHeader/ListingHeader";
import ListingPagination from "../Components/Listings/ListingPagination/ListingPagination";
// import ListingTableActions from "../Components/Listings/ListingTableActions/ListingTableActions";
import ListingWidget from "../Components/Listings/ListingsWidget/ListingWidget";
import CustomizeTableview from "../Components/TableViews/CustomizeTableview";
import style from "../styles/listings.module.css";
import { useEffect } from "react";
import ListingAddItem from "../Components/Listings/ListingAddItem/ListingAddItem";
import LoaderPage from "../Components/loaders/loaderPage";
import axios from "axios";
import NoResult from "../Components/NoResult";
import { useSelector } from "react-redux";
import PopUpModal from "../Components/Modal";
import { Button, Modal } from "react-bootstrap";
import { dateTime } from "../Features/dateTime";
import ListingFilter from "../Components/Listings/ListingFilters/ListingFilter";
import LoaderOverlay from "../Components/loaders/loaderOverlay";
import { toast } from "react-toastify";
import SubscriptionAlertModal from "../Components/SubscribtionAlertModal";
import CompletionForm from "../Components/Listings/ListingCompletionForm/CompletionForm";
import { useNavigate } from "react-router";
import TableSettings from "../Components/TableSettings";
import MarketplaceTour from "../TourGuides/MarketplaceTour";

const Listings = () => {
  const navigate = useNavigate();

  const [openTab, setOpenTab] = useState("all");
  const [listingData, setListingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [listingCompletionModal, setListingCompletionModel] = useState(false);

  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfItems, setNoOfItems] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const shop_id = user.shop_id;

  const [itemsLimit, setItemsLimit] = useState();
  const [itemsLimitModalShow, setItemsLimitModalShow] = useState(false);
  const [delistModal, setDelistModal] = useState(false);

  //Customize Table
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [columns, setColumns] = useState([
    { label: "category", value: 1 },
    { label: "brand", value: 1 },
    { label: "stock_quantity", value: 1 },
    { label: "listed_quantity", value: 1 },
    { label: "online_discount", value: 1 },
    { label: "no_of_views", value: 1 },
    { label: "no_of_lead", value: 1 },
    { label: "active_since", value: 1 },
  ]);
  const [columnNotToShow, setColumnNotToShow] = useState([]);

  // const [isValidate, setIsValidate] = useState(false);
  const [reset, setReset] = useState(false);

  const handleSettings = (e, label, i) => {
    // setIsValidate(false);
    setColumns((values) => values.map((val) => (val.label === label ? { label: val.label, value: e.target.checked === false ? 0 : 1 } : val)));
  };
  const selectAll = () => {
    setColumns([
      { label: "category", value: 1 },
      { label: "brand", value: 1 },
      { label: "stock_quantity", value: 1 },
      { label: "listed_quantity", value: 1 },
      { label: "online_discount", value: 1 },
      { label: "no_of_views", value: 1 },
      { label: "no_of_lead", value: 1 },
      { label: "active_since", value: 1 },
    ]);
  };

  const handlesubmitSettings = async (e) => {
    e.preventDefault();

    const desiredObject = {};

    columns.forEach((obj) => {
      desiredObject[obj.label] = obj.value;
    });
    try {
      const data = await axios.post(
        process.env.REACT_APP_API_URL + `/settings/add-listings-settings`,
        { user_id: userId, listings_table: desiredObject },
        {
          withCredentials: true,
        }
      );
      if (data) {
        setShowSettingModal(false);
        setReset((prev) => !prev);
      }
    } catch {}
  };

  useEffect(() => {
    const fetchTableColumns = async () => {
      try {
        const data = await axios.get(process.env.REACT_APP_API_URL + `/settings/get-listings-settings/${userId}`, {
          withCredentials: true,
        });
        const res = data?.data?.listings_table;
        const desiredArray = [];
        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            desiredArray.push({ label: key, value: res[key] });
          }
        }
        if (desiredArray.length > 0) {
          setColumns(desiredArray);
        } else {
          setColumns([...columns]);
        }
        const defaultNotShowColum = ["id", "status", "online_discount_unit", "condition_id", "item_id", "sale_price", "archived_on", "is_deleted"];
        setColumnNotToShow((prev) => [...defaultNotShowColum, ...desiredArray.filter((obj) => obj.value === 0).map((obj) => obj.label)]);
      } catch {}
    };
    fetchTableColumns();
  }, [reset, showSettingModal]);

  //Customize Table --- END ---

  const [widgetData, setWidgetData] = useState({
    total: null,
    active: null,
    lead: null,
    remaining: null,
  });

  const [searchInput, setSearchInput] = useState("");
  const [category, setCategory] = useState(null);
  const [brand, setBrand] = useState(null);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });

  const resetButtonHandle = () => {
    setCategory(null);
    setBrand(null);
    setOrderBy({ created_at: "DESC" });
    setSearchInput("");
    setSizeOfPages(10);
  };

  const [changeSignal, setChangeSignal] = useState(false);
  const sendChangeSignal = () => {
    setChangeSignal((prev) => !prev);
  };

  const [addListingModalShow, setAddListingModalShow] = useState(false);

  const closeAddListingModalHandle = (listingId) => {
    if (listingId) {
      setSelectedListing({
        listingId: listingId,
      });
      setListingCompletionModel(true);
      setAddListingModalShow(false);
    } else {
      setAddListingModalShow(false);
    }
  };

  const addListingButtonHandle = () => {
    setAddListingModalShow(true);
  };

  useEffect(() => {
    const remaingDays = (d) => {
      if (d) {
        const var1 = dateTime(d);
        const var2 = new Date();
        var Difference_In_Time = var2.getTime() - var1.getTime();

        const totalMilliseconds = Difference_In_Time;

        const days = Math.floor(totalMilliseconds / (1000 * 60 * 60 * 24));
        const hours = Math.floor((totalMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((totalMilliseconds % (1000 * 60)) / 1000);

        let result = "";
        if (days > 0) {
          result += `${days} day${days > 1 ? "s" : ""} `;
        }

        result += `${hours} hour${hours > 1 ? "s" : ""} `;

        return result;
      } else {
        return "--";
      }
    };

    const formatDiscount = (value, unit) => {
      return unit === "fixed" ? "Rs." + value : value + "%";
    };

    if (user?.tour && !user?.tour.Marketplace) {
      setListingData([
        {
          id: 2,
          image: "images/dummyImages/mobiles.jpg",
          title: "Test 003",
          category: "Mobile",
          brand: "Apple",
          stock_quantity: 10,
          sale_price: 50000,
          listed_quantity: 5,
          condition_id: 2,
          online_price: 600000,
          online_discount: formatDiscount(5000, "fixed"),
          online_discount_unit: "fixed",
          product_URL: null,
          status: "Validated,Inactive",
          no_of_views: 5,
          no_of_lead: 2,
          active_since: remaingDays("2024-01-01"),
          item_id: 1,
          archived_on: null,
          is_deleted: 0,
        },
        {
          id: 3,
          image: "images/dummyImages/mobiles.jpg",
          title: "Test 004",
          category: "Mobile",
          brand: "Apple",
          stock_quantity: 10,
          sale_price: 50000,
          listed_quantity: 5,
          condition_id: 2,
          online_price: 600000,
          online_discount: formatDiscount(5000, "fixed"),
          online_discount_unit: "fixed",
          product_URL: null,
          status: "Validation Pending,Inactive",
          no_of_views: 5,
          no_of_lead: 2,
          active_since: remaingDays("2024-01-01"),
          item_id: 1,
          archived_on: "1970-01-01",
          is_deleted: 0,
        },
      ]);
      setNoOfItems(2);
      setNoOfPages(1);
      setWidgetData((prev) => ({
        ...prev,
        active: 0,
        total: 0,
        lead: 0,
      }));
      setIsLoading(false);
    } else {
      const fetchListing = async () => {
        setIsLoading(true);
        const paramsObject = {
          search: searchInput,
          size: sizeOfPages,
          page: currentPage,
        };
        if (openTab !== "all") {
          paramsObject.active = openTab === "active" ? true : false;
        }
        if (category) {
          paramsObject.category = category;
        }
        if (brand) {
          paramsObject.brand = brand;
        }
        if (orderBy) {
          paramsObject.orderBy = new URLSearchParams(orderBy).toString();
        }
        const searchParams = new URLSearchParams(paramsObject).toString();
        try {
          const response = await axios.get(process.env.REACT_APP_API_URL + `/listings${searchParams.length > 0 ? `?${searchParams}` : ""}`, {
            withCredentials: true,
          });

          if (response) {
            setListingData(
              response?.data?.success?.data.map((item) => {
                return {
                  id: item.id,
                  image: item.image,
                  title: item.title,
                  category: item.category,
                  brand: item.brand,
                  stock_quantity: item.stock_quantity,
                  sale_price: item.sale_price,
                  listed_quantity: item.listed_quantity,
                  condition_id: item.condition_id,
                  online_price: item.online_price,
                  online_discount: formatDiscount(item.online_discount, item.online_discount_unit),
                  online_discount_unit: item.online_discount_unit,
                  product_URL: item.product_url,
                  status: item.status,
                  no_of_views: item.no_of_views,
                  no_of_lead: item.no_of_lead,
                  active_since: remaingDays(item.activation_date),
                  item_id: item.item_id,
                  archived_on: item.archived_on,
                  is_deleted: item.is_deleted,
                };
              })
            );
            setNoOfItems(response?.data?.success?.count?.no_of_items);
            setNoOfPages(response?.data?.success?.count?.no_of_pages);
            setWidgetData((prev) => ({
              ...prev,
              active: response?.data?.success?.count?.no_of_active,
              total: response?.data?.success?.count?.total_no_of_views,
              lead: response?.data?.success?.count?.total_no_of_lead,
            }));
            setIsLoading(false);
          }
        } catch (error) {
          setListingData([]);
          setIsLoading(false);
        }
      };
      let callTimeout;
      if (searchInput?.length > 0) {
        callTimeout = setTimeout(() => {
          fetchListing();
        }, 300);
      } else {
        fetchListing();
      }

      return () => {
        if (callTimeout) {
          clearTimeout(callTimeout);
        }
      };
    }
  }, [changeSignal, currentPage, sizeOfPages, searchInput, openTab, category, brand, orderBy, user?.tour.Marketplace]);

  const [selectedListing, setSelectedListing] = useState(null);

  const closeCompletionModalHandle = () => {
    setListingCompletionModel(false);
    setSelectedListing(null);
  };
  const handleCompletion = (listing) => {
    setListingCompletionModel(true);
    setSelectedListing({
      listing,
    });
  };
  const handleItemLimitModalShow = (listing) => {
    setItemsLimitModalShow(true);
    setSelectedListing({
      listing,
    });
  };
  const onHideItemLimit = () => {
    setItemsLimitModalShow(false);
    setSelectedListing(null);
  };
  const handleCompletionForItemLimit = () => {
    setListingCompletionModel(true);
    setItemsLimitModalShow(false);
  };

  const fetchMyConsumptions = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + `/inventory/subscription-consumption/${user?.shop_id}`, {
        withCredentials: true,
      });
      if (response) {
        const res = response.data.success.data;
        setItemsLimit(res?.total_items_on_marketplace >= res?.marketplace_items_limit ? true : false);
        setWidgetData((prev) => ({
          ...prev,
          remaining: res?.marketplace_items_limit - res?.total_items_on_marketplace,
        }));
      }
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    fetchMyConsumptions();
  }, [changeSignal]);

  const handleChangeDeList = (listing, isEditMode) => {
    setDelistModal(true);
    setSelectedListing({ listing, isEditMode });
  };

  const delistModalHide = () => {
    setDelistModal(false);
  };

  const archiveHandle = async (listingId) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(
        process.env.REACT_APP_API_URL + `/listings/archive/` + listingId,
        {},
        {
          withCredentials: true,
        }
      );
      if (response) {
        setChangeSignal((prev) => !prev);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const unarchiveHandle = async (listingId) => {
    try {
      setIsLoading(true);
      const response = await axios.patch(
        process.env.REACT_APP_API_URL + `/listings/unarchive/` + listingId,
        {},
        {
          withCredentials: true,
        }
      );
      if (response) {
        if (response?.data?.code === 422) {
          toast.error(`The associated inventory item is archived. Please unarchive the item first.`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
          setIsLoading(false);
        } else {
          setChangeSignal((prev) => !prev);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const confirmDeList = async (listing) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/listings/de-list/` + listing?.id,
        {},
        {
          withCredentials: true,
        }
      );
      if (response) {
        setChangeSignal((prev) => !prev);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const Overlay = ({ children }) => {
    return <div style={{ position: "fixed", width: "100%", height: "100%", background: "rgba(0, 0, 0, 0.5)", zIndex: 9999 }}>{children}</div>;
  };

  return (
    <>
      {user?.tour && !user?.tour?.Marketplace && <MarketplaceTour />}
      <LoaderOverlay loading={isLoading} />
      {/* <LoaderOverlay loading={isActionLoading} /> */}
      <div className={`d-flex flex-column ${style.listingContainer}`} id="listings">
        <PopUpModal
          title="Customize table"
          show={showSettingModal}
          onHide={() => {
            setShowSettingModal(false);
          }}
        >
          <TableSettings setReset={setReset} isValidate={false} handlesubmitSettings={handlesubmitSettings} selectAll={selectAll} handleSettings={handleSettings} columns={columns} show={showSettingModal} close={() => setShowSettingModal(false)} />
        </PopUpModal>
        <CompletionForm value={selectedListing} setValue={setSelectedListing} show={listingCompletionModal} close={closeCompletionModalHandle} itemsLimit={itemsLimit} sendChangeSignal={sendChangeSignal} />
        <ListingAddItem show={addListingModalShow} close={closeAddListingModalHandle} sendChangeSignal={sendChangeSignal} />
        <div className={`order-0`}>
          <ListingWidget active={widgetData?.active} total={widgetData?.total} lead={widgetData?.lead} remaining={widgetData?.remaining} />
        </div>
        <div className={`order-1 order-xl-2`}>
          <ListingHeader addListingButtonHandle={addListingButtonHandle} hideAvailableItems={true} hideCategoryFilter={true} setSizeOfPages={setSizeOfPages} sizeOfPages={sizeOfPages} setSearchText={setSearchInput} searchText={searchInput} />
        </div>
        <div className={`order-2 order-xl-3`}>
          <ListingFilter
            handleCategoryFilter={(state) => {
              if (state) {
                setCategory(state.value);
              } else {
                setCategory(null);
              }
            }}
            handleBrandFilter={(state) => {
              if (state) {
                setBrand(state.value);
              } else {
                setBrand(null);
              }
            }}
            handleOrderBy={(state) => {
              setOrderBy(state.value);
            }}
            handleResetButton={resetButtonHandle}
          />
        </div>
        <div className={`d-flex px-3 order-3 order-xl-1`} id="listings-tabs">
          <div className={`${style.tabButton} ${openTab === "all" ? style.activeTabButton : style.inactiveTabButton}`} onClick={() => setOpenTab("all")}>
            All
          </div>
          <div className={`${style.tabButton} ${openTab === "active" ? style.activeTabButton : style.inactiveTabButton}`} onClick={() => setOpenTab("active")}>
            Active
          </div>
          <div className={`${style.tabButton} ${openTab === "inactive" ? style.activeTabButton : style.inactiveTabButton}`} onClick={() => setOpenTab("inactive")}>
            Inactive
          </div>
        </div>
        {isLoading ? (
          <div
            style={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
            }}
            className={`order-3`}
          >
            {/* <LoaderPage /> */}
          </div>
        ) : !listingData[0] ? (
          <div className="order-4">
            <NoResult name="Listing" />
          </div>
        ) : (
          <>
            <div className={`px-3 order-4`}>
              <CustomizeTableview
                settingsModal={() => {
                  setShowSettingModal(true);
                }}
                data={listingData}
                link={{ label: "Click Here", column: "product_URL" }}
                borderRounded={false}
                columnNotShow={columnNotToShow}
                thumbnail={{
                  title: "Title",
                  img: "image",
                  description: "title",
                }}
                actionButton={{
                  column: ["status", "archived_on"],
                  isShow: (actionStatuses) => {
                    if (actionStatuses["archived_on"]) {
                      return false;
                    } else {
                      const statuses = actionStatuses["status"].split(",");
                      return statuses.includes("Validation Pending") || statuses.includes("Inactive") || statuses.includes("Active");
                    }
                  },
                  variant: (id, listing) => {
                    const status = listing["status"].split(",");
                    if (status.includes("Active", "Validated")) {
                      return "outline-danger";
                    } else {
                      return "outline-warning";
                    }
                  },
                  onClick: (id, listing) => {
                    const status = listing["status"].split(",");
                    if (status.includes("Active")) {
                      handleChangeDeList(listing);
                    } else {
                      if (itemsLimit) {
                        handleItemLimitModalShow(listing);
                      } else {
                        handleCompletion(listing);
                      }
                    }
                  },
                  label: (listing) => {
                    const status = listing["status"].split(",");
                    if (status.includes("Validation Pending") || status.includes("Inactive")) {
                      return "Activate";
                    } else if (status.includes("Active")) {
                      return "Deactivate";
                    }
                  },
                  style: {
                    width: "100%",
                    minWidth: "80px",
                  },
                }}
                status={{
                  column: "status",
                  format: (value) => {
                    const status = value.split(",");
                    if (status.includes("Active")) {
                      return (
                        <span
                          style={{
                            color: "rgba(36, 153, 62, 1)",
                            backgroundColor: "rgba(36, 153, 62, 0.1)",
                            padding: "5px",
                            borderRadius: "5px",
                            fontWeight: "400",
                            fontSize: "14px",
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                            maxWidth: "60px",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "rgba(36, 153, 62, 1)",
                              border: "1px solid rgba(36, 153, 62, 1)",
                              borderRadius: "10px",
                              height: "5px",
                              width: "5px",
                              padding: "3px",
                              display: "inline-block",
                            }}
                          >
                            {" "}
                          </span>
                          {"  "}
                          Active
                        </span>
                      );
                    } else {
                      return (
                        <span
                          style={{
                            backgroundColor: "white",
                            padding: "5px 0 5px 0",
                            borderRadius: "5px",
                            maxWidth: "60px",
                          }}
                        >
                          <span
                            style={{
                              color: "rgba(57, 65, 74, 0.6)",
                              backgroundColor: "rgba(129, 133, 142, 0.1)",
                              padding: "5px",
                              borderRadius: "5px",
                              fontWeight: "400",
                              fontSize: "16px",
                            }}
                          >
                            Inactive
                          </span>
                        </span>
                      );
                    }
                  },
                }}
                archive={{
                  action: archiveHandle,
                  isShow: (value) => {
                    if (value.archived_on === null) {
                      return true;
                    } else {
                      return false;
                    }
                  },
                }}
                unarchive={{
                  action: unarchiveHandle,
                  isShow: (value) => {
                    if (value.archived_on !== null) {
                      return true;
                    } else {
                      return false;
                    }
                  },
                }}
              />
            </div>
            <div className={`d-none d-sm-flex justify-content-between order-5`}>
              <ListingPagination totalItems={noOfItems} showing={listingData.length} noOfPages={noOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              {/* <ListingTableActions noOfSelected={selectedRows.length} /> */}
            </div>
            <div className={`d-block d-sm-none  order-4`}>
              <ListingPagination totalItems={noOfItems} showing={listingData.length} noOfPages={noOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>
          </>
        )}
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered className={`border-0`} backdrop="static" show={itemsLimitModalShow} onHide={onHideItemLimit}>
          <Modal.Header className={` `} closeButton>
            <Modal.Title id="contained-modal-title-vcenter fs-5 fw-2">⚠️ Subscription Limit</Modal.Title>
          </Modal.Header>
          <Modal.Body className={`py-3 px-5`}>
            <div className="">
              <h5>Max. Number of Allowed Listings Reached!</h5>
              <p>
                <strong>Delist a Product or Upgrade your Subscription to continue adding new listings. </strong> You cannot activate a new listing however, you can continue to fill the details for future use.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={onHideItemLimit}>
              Close
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => {
                navigate("/settings/plans");
              }}
            >
              Upgrade
            </Button>
            <Button variant="primary" onClick={handleCompletionForItemLimit}>
              Continue
            </Button>
          </Modal.Footer>
        </Modal>

        <PopUpModal title="Please confirm your action" show={delistModal} onHide={delistModalHide}>
          <div className="d-flex justify-content-center">
            <p className="fs-5 ">Are you sure you want to Deactivate this listing?</p>
          </div>
          <div className="d-flex justify-content-end gap-3 pb-3">
            <Button variant="outline-primary" onClick={delistModalHide}>
              Close
            </Button>
            <Button
              variant="outline-danger"
              onClick={() => {
                if (selectedListing) {
                  confirmDeList(selectedListing.listing);
                  delistModalHide(selectedListing.listing, selectedListing.isEditMode);
                }
              }}
            >
              Deactivate
            </Button>
          </div>
        </PopUpModal>
      </div>
    </>
  );
};
export default Listings;
