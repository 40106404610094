// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GlobalLocation_globalLocation__4ejVi {
  width: 100%;
  margin-right: 30px;
}
.GlobalLocation_globalLocation__4ejVi .react-select-container__value-container {
  width: 200px;
  text-wrap: nowrap;
}
.GlobalLocation_globalLocation__4ejVi button {
  width: 100% !important;
}

.GlobalLocation_registerButton__9Fcyw:disabled {
  border-color: transparent !important;
  background-color: #6c757d !important;
  color: white !important;
}

@media screen and (max-width: 992px) {
  .GlobalLocation_globalLocation__4ejVi {
    width: 100%;
  }
  .GlobalLocation_globalLocation__4ejVi .react-select-container__value-container {
    height: 36px;
    text-wrap: nowrap;
  }
  .GlobalLocation_globalLocation__4ejVi button {
    height: 41px;
    width: 120px;
    text-wrap: nowrap;
    overflow: hidden;
  }
}

@media screen and (max-width: 575px) {
  .GlobalLocation_globalLocation__4ejVi {
    width: 100%;
  }
  .GlobalLocation_globalLocation__4ejVi .react-select-container__value-container {
    height: 36px;
    width: 200px;
    text-wrap: nowrap;
    /* margin-left: 14px; */
  }
  /* .globalLocation :global(.react-select-container__control) {
    margin-left: 14px;
    width: 100% !important;
  } */
  .GlobalLocation_globalLocation__4ejVi button {
    height: 41px;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/GlobalLocation.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,oCAAoC;EACpC,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,YAAY;IACZ,iBAAiB;EACnB;EACA;IACE,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,uBAAuB;EACzB;EACA;;;KAGG;EACH;IACE,YAAY;IACZ,WAAW;EACb;AACF","sourcesContent":[".globalLocation {\n  width: 100%;\n  margin-right: 30px;\n}\n.globalLocation :global(.react-select-container__value-container) {\n  width: 200px;\n  text-wrap: nowrap;\n}\n.globalLocation button {\n  width: 100% !important;\n}\n\n.registerButton:disabled {\n  border-color: transparent !important;\n  background-color: #6c757d !important;\n  color: white !important;\n}\n\n@media screen and (max-width: 992px) {\n  .globalLocation {\n    width: 100%;\n  }\n  .globalLocation :global(.react-select-container__value-container) {\n    height: 36px;\n    text-wrap: nowrap;\n  }\n  .globalLocation button {\n    height: 41px;\n    width: 120px;\n    text-wrap: nowrap;\n    overflow: hidden;\n  }\n}\n\n@media screen and (max-width: 575px) {\n  .globalLocation {\n    width: 100%;\n  }\n  .globalLocation :global(.react-select-container__value-container) {\n    height: 36px;\n    width: 200px;\n    text-wrap: nowrap;\n    /* margin-left: 14px; */\n  }\n  /* .globalLocation :global(.react-select-container__control) {\n    margin-left: 14px;\n    width: 100% !important;\n  } */\n  .globalLocation button {\n    height: 41px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalLocation": `GlobalLocation_globalLocation__4ejVi`,
	"registerButton": `GlobalLocation_registerButton__9Fcyw`
};
export default ___CSS_LOADER_EXPORT___;
