import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PaginationBar from "../../Components/PaginationBar";
import SearchableSelect from "../../Components/SearchableSelect";
import axios from "axios";
import NoResult from "../../Components/NoResult";
import filterStyle from "../../styles/filtersButtons.module.css";
import filterr from "../../assets/svg/filter.svg";
import { Link } from "react-router-dom";
import ReactSelect from "../../Components/ReactSelect";
import whatsappIcon from "../../assets/svg/whatsapp.svg";

import Csv from "../../Components/csv/Csv";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dateTime, requestDateTime, TIMEOUT } from "../../Features/dateTime";
import triangle from "../../assets/svg/triangle.svg";
import dangerline from "../../assets/svg/dangerline.svg";
import { toast } from "react-toastify";
import LoaderPage from "../../Components/loaders/loaderPage";
import PaymentOnSell from "./PaymentOnSell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReservationsTour from "../../TourGuides/ReservationsTour";
const Reservations = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useSelector((state) => state.location.location);
  // const [locationSearch, setLocationSearch] = useState({});
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  // const [getTechLocations, setGetTechLocations] = useState("");
  const [search, setSearch] = useState("");
  const [toggle, refresh] = useState(false);
  const [statusSearch, setStatusSearch] = useState({ value: "0", label: "All" });
  const [reservedInventory, setReservedInventory] = useState([]);
  const [totalReservedInventory, setTotalReservedInventory] = useState();
  const [reservationId, setReservationId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [cancelModal, setCancelModal] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [category, setCategory] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState(null);
  const [reservedData, setReservedData] = useState({});
  const [listingTitle, setListingTitle] = useState();

  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [paymentModalProps, setPaymentModalProps] = useState({});

  const handleCancellation = (data) => {
    setCancelModal(true);
    setReservationId(data);
  };

  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearch("");
    setStatusSearch(null);
    // setLocationSearch({});
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const userId = user.user_id;
  const shopName = user.shop_name;
  const user_type = user.user_type;
  const filterCategoriesMobile = (e) => {
    if (e.target.value === "All") {
      setCategory({ value: null, label: e.target.value });
    } else {
      setCategory({ value: e.target.id, label: e.target.value });
    }
  };

  // Search Products
  const searchInventory = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearch("");
    } else {
      setSearch(e.target.value);
    }
  };

  const searchStatus = (e) => {
    setStatusSearch({ value: e.value, label: e.label });
  };

  const searchStatusMobile = (e) => {
    setStatusSearch({ value: e.target.id, label: e.target.value });
  };
  const getCat = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/categories/get", {
          withCredentials: true,
        })
        .then((res) => {
          const getData = res.data
            .filter((cat) => cat.id !== 7)
            .map((cat) => ({
              value: cat.id,
              label: cat.category,
            }));
          getData.splice(0, 0, { value: null, label: "All" });
          resolve(getData);
          setCategoriesOptions(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  const filterCategories = (e) => {
    if (e.value) {
      setCategory({ value: e.value, label: e.label });
    } else {
      setCategory(null);
    }
  };

  const resetFilterHandle = (e) => {
    setStatusSearch({ value: "0", label: "All" });
    setCategory(null);
  };

  const SearchData = [
    {
      name: "Select Status",
      handleChange: searchStatusMobile,
      value: statusSearch,
      filter: [
        { value: "0", label: "All" },
        { value: "1", label: "Done" },
        { value: "2", label: "Pending" },
        { value: "3", label: "Cancelled" },
      ],
    },
    {
      name: "Select Category",
      handleChange: filterCategoriesMobile,
      value: category,
      filter: categoriesOptions,
    },
  ];

  const changePage = (page) => {
    setCurrentPage(page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  function getReservedInventory() {
    if (user?.tour && !user?.tour.Reservations) {
      setReservedInventory([
        {
          listing_title: "Test 001",
          customer_name: "Test",
          customer_number: "03000000000",
          customer_email: "test@xyz.com",
          category_id: 2,
          effective_price: 95000,
          item_id: 0,
          location: "Main Location",
          notes: "Demo.....",
          quantity: 1,
          created_at: new Date().toISOString(),
          status: "Pending",
        },
      ]);
      setTotalReservedInventory(1);
      setNoOfPages(1);
      setIsLoading(false);
    } else {
      const [orderByObject] = Object.entries(orderBy);
      const query = new URLSearchParams({
        ...(search.length ? { search: search } : {}),
        ...(location?.value ? { location: location?.value } : {}),
        ...(statusSearch?.label === "All" ? {} : { status: statusSearch?.label }),
        ...(category?.value ? { category: category?.value } : {}),
        orderBy: `${orderByObject[0]}:${orderByObject[1]}`,
        page: currentPage,
        size: sizeOfPages,
      }).toString();
      axios
        .get(process.env.REACT_APP_API_URL + `/inventory/get-reserved-inventory/${shop_id}?${query}`, { withCredentials: true })
        .then(({ data }) => {
          setIsLoading(false);
          if (data.pagination.no_of_pages === 0) {
            if (searchParams.get("page")) {
              setNoOfPages(0);
              navigate("/reservations?page=0");
            }
          } else {
            setTotalReservedInventory(data.pagination.no_of_items);
            setNoOfPages(data.pagination.no_of_pages);
            if (currentPage > data.pagination.no_of_pages) {
              setCurrentPage(data.pagination.no_of_pages);
              navigate("/reservations?page=" + data.pagination.no_of_pages);
            }
            if (searchParams.get("page") === "0") {
              setCurrentPage(1);
              navigate("/reservations?page=1");
            }
          }
          setReservedInventory(
            data.reservations.map((row) => {
              const { listing_title, quantity, customer_name, customer_email, customer_number, location, created_at, ...restData } = row;
              return {
                listing_title,
                customer_name,
                customer_number: (
                  <>
                    <div className={`d-flex gap-1 align-item-center`}>
                      <img
                        src={whatsappIcon}
                        height={20}
                        width={20}
                        alt="whatsapp"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          window.open(`https://wa.me/${customer_number.replace("0", "92")}`);
                        }}
                      />
                      <span>{customer_number}</span>
                    </div>
                  </>
                ),
                customer_email,
                quantity,
                location,
                created_at,
                ...restData,
              };
            })
          );
        })
        .catch((err) => {
          setIsLoading(false);
          const tempErr = err.response.data.error;
          if (tempErr.status === 404 && tempErr.message === "No Record Found") {
            setReservedInventory([]);
          }
        });
    }
  }

  useEffect(() => {
    let timeout;
    if (location) {
      if (search?.length > 0) {
        timeout = setTimeout(() => {
          setIsLoading(true);
          getReservedInventory();
        }, TIMEOUT);
      } else {
        setIsLoading(true);
        getReservedInventory();
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [search, location, currentPage, noOfPages, sizeOfPages, statusSearch, shop_id, toggle, orderBy, category, user?.tour.Reservations]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);

  function requestAllHistory() {
    const query = new URLSearchParams({
      ...(search.length ? { search: search } : {}),
      ...(location?.value ? { location: location?.value } : {}),
      ...(statusSearch?.label === "All" ? {} : { status: statusSearch?.label }),
      ...(category?.value ? { category: category?.value } : {}),
    });
    return axios.get(process.env.REACT_APP_API_URL + `/inventory/get-reserved-inventory/${shop_id}?${query}`, { withCredentials: true });
  }
  const handleCancel = async () => {
    try {
      const data = await axios.patch(process.env.REACT_APP_API_URL + `/inventory/update-reservation-status/${reservationId}`, { status: "Cancelled" }, { withCredentials: true });
      if (data) {
        setCancelModal(false);
        refresh((prev) => !prev);
        toast.success(`${data.data.message}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
      }
    } catch {}
  };

  const handleDone = async (id, row) => {
    try {
      // const { customer_number, ...restData } = row;
      // const customerNumber = customer_number.props.children.props.children[1].props.children;
      // const reservationData = { ...restData, customer_number: customerNumber };
      // setPaymentModalProps(reservationData);
      setPaymentModalProps(row);
      setPaymentModalShow(true);
      // const data = await axios.patch(process.env.REACT_APP_API_URL + `/inventory/update-reservation-status/${id}`, { status: "Done" }, { withCredentials: true });
      // if (data) {
      //   refresh((prev) => !prev);
      //   toast.success(`${data.data.message}`, {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     autoClose: 1500,
      //     pauseOnHover: false,
      //     className: "toastMsg",
      //   });
      // }
    } catch {}
  };
  const Overlay = ({ children }) => {
    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
        }}
      >
        {children}
      </div>
    );
  };
  const caret = async (reservationId) => {
    const data = reservedInventory.filter((item) => item.id === reservationId);
    const { category_id, created_at, id, item_id, item_title, deleted_at, listing_id, updated_at, location, listing_title, customer_email, customer_name, customer_number, quantity, status, effective_price, ...reservationObj } = data[0];
    setListingTitle(listing_title);
    setReservedData(reservationObj);
  };

  // const handleSell = async (id) => {
  //   try {
  //     const data = await axios.post(process.env.REACT_APP_API_URL + `/sales/make-sale-on-reservation`, { reservation_id: id }, { withCredentials: true });
  //     if (data) {
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const onHidePaymentOnSell = (closedWithoutAnyAction = false) => {
    setPaymentModalShow(false);
    setPaymentModalProps({});
    if (!closedWithoutAnyAction) {
      refresh((prev) => !prev);
    }
  };

  return (
    <>
      {user?.tour && !user?.tour?.Reservations && <ReservationsTour />}
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} filter={filter} />
      ) : (
        <>
          <Container fluid>
            {toogleMobileSearch === "search" ? (
              <div className="d-flex  mb-2 justify-content-center align-items-center">
                <div className="">
                  {" "}
                  <SearchMobleInput searchlogo value={search} handleChange={searchInventory} />
                </div>
                <span className="ms-4" onClick={handleAcoordianCLose}>
                  X
                </span>
              </div>
            ) : (
              <Row className="d-flex align-items-center">
                <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline mb-3" id="reservations-search">
                  <SearchInput searchlogo handleChange={searchInventory} />
                </Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3"></Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3"></Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3" id="reservations-category">
                  <ReactSelect placeholder="Filter Category" value={category} options={getCat} handleChange={(e) => filterCategories(e)} />
                </Col>

                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3" id="reservations-status-filter">
                  <SearchableSelect
                    placeholder="Filter Status"
                    searchable={false}
                    value={statusSearch}
                    options={[
                      { value: "0", label: "All" },
                      { value: "1", label: "Done" },
                      { value: "2", label: "Pending" },
                      { value: "3", label: "Cancelled" },
                    ]}
                    handleChange={searchStatus}
                  />
                </Col>
                {/* For Mobile screen Start */}

                <Col className="d-none d-sm-inline d-lg-none d-md-inline px-0" sm={2}></Col>
                <Col xs="6" lg="3" xl="2" sm="3" className="mb-3" id="reservations-page-size">
                  <SearchableSelect
                    selectClassName={"bg-white"}
                    placeholder={`Showing ${sizeOfPages}`}
                    defaultValue={`Showing ${sizeOfPages}`}
                    value={`Showing ${sizeOfPages}`}
                    searchable={false}
                    options={[
                      { value: "10", label: "10" },
                      { value: "20", label: "20" },
                      { value: "30", label: "30" },
                    ]}
                    handleChange={(e) => setSizeOfPages(e.value)}
                  />
                </Col>
                <Col onClick={() => handleAcoordianOpen("search")} xs="2" sm="2" lg="3" className="d-lg-none d-inline mb-3">
                  <SearchInput searchlogo bordered />
                </Col>
                <Col className={`d-lg-none d-inline mb-3 ${filterStyle.filter}`} xs="2" lg="2">
                  <Link to="" className={`${filterStyle.filterToggleButtonLink}`}>
                    <Button onClick={() => handleAcoordianOpen("select")} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex align-items-center`}>
                      <img className="" src={filterr} alt="filter" />
                      <span className=" ms-2 d-none d-sm-inline">Filter</span>
                    </Button>
                  </Link>
                </Col>
                <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className={`mb-3 p-0 ${filterStyle.addbtn}`}></Col>

                {/* For Mobile screen End */}
              </Row>
            )}
            <div className="pt-2">
              <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle} id="reservations-filter-button">
                <span className="d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div>

            {isLoading ? (
              <div
                style={{
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoaderPage />
              </div>
            ) : !reservedInventory[0] ? (
              <NoResult />
            ) : (
              <div id="reservations-table">
                <CustomizeTableview
                  className={"mt-3"}
                  data={reservedInventory}
                  columnNotShow={["id", "item_id", "status", "updated_at", "listing_id", "category_id", "notes"]}
                  caret={caret}
                  caretTitle={listingTitle}
                  caretData={reservedData}
                  dateColumn={["created_at"]}
                  sortColumn={["created_at", "listing_title", "item_title"]}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  status={{
                    column: ["status", "created_at"],
                    style: ({ created_at, status }) => {
                      if (status === "Done") {
                        return "text-success";
                      } else if (status === "Pending" && dateTime(created_at).toDateString() === new Date().toDateString()) {
                        return "text-warning";
                      } else if (status === "Cancelled" || (status === "Pending" && dateTime(created_at).toDateString() !== new Date().toDateString())) {
                        return "text-danger";
                      }
                    },
                    format: ({ created_at, status }) => {
                      if (status === "Done") {
                        return "Done";
                      } else if (status === "Pending" && dateTime(created_at).toDateString() === new Date().toDateString()) {
                        return "Pending";
                      } else if (status === "Cancelled" || (status === "Pending" && dateTime(created_at).toDateString() !== new Date().toDateString())) {
                        return "Cancelled";
                      }
                    },
                  }}
                  done={{
                    action: handleDone,
                    isShow: ({ created_at, status }) => {
                      if (status === "Pending" && dateTime(created_at).toDateString() === new Date().toDateString()) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  }}
                  cancel={{
                    action: handleCancellation,
                    isShow: ({ created_at, status }) => {
                      if (status === "Pending" && dateTime(created_at).toDateString() === new Date().toDateString()) {
                        return true;
                      } else {
                        return false;
                      }
                    },
                  }}
                  actions={statusSearch?.label === "Pending" || statusSearch?.label === "All"}
                />
                <Row className="mt-5 align-items-center">
                  <Col className="d-none d-md-block" md="3" lg="3">
                    <span className="text-secondary fs-6">{totalReservedInventory} result(s)</span>
                  </Col>
                  <Col xs="12" md="6" lg="6">
                    <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                  </Col>
                  <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                    <span className="text-secondary fs-6">{totalReservedInventory} result(s)</span>
                  </Col>
                  <Col xs="6" md="3" lg="3" className="text-end mt-3">
                    <Csv
                      filename={`${
                        "Reserved Inventory " +
                        shopName +
                        " " +
                        new Date().toLocaleDateString("en-US", {
                          timeZone: "Asia/Karachi",
                        })
                      }`}
                      api={requestAllHistory}
                      responseObject={({ data }) => {
                        return data.reservations;
                      }}
                      format={(data) => {
                        const { id, listing_title, ...temp } = data;
                        const newObj = {
                          listing_title: listing_title.replace('"', " inches"),
                          ...temp,
                        };
                        return newObj;
                      }}
                      error={(err) => {
                        const tempErr = err.response.data.error;
                        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        </>
      )}
      <Modal
        show={cancelModal}
        onHide={() => {
          setCancelModal(false);
        }}
        animation={true}
        centered
      >
        <Modal.Header>
          <Modal.Title>Cancel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to cancel this reservation?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={() => {
                setCancelModal(false);
              }}
            >
              No
            </span>
            <Button className="w-100 rounded-3" onClick={() => handleCancel()}>
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <PaymentOnSell show={paymentModalShow} onHide={onHidePaymentOnSell} data={paymentModalProps} />
    </>
  );
};
export default Reservations;
