// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filtersButtons_filterToggleButton__7-WK7 {
  height: 40px;
}

.filtersButtons_filterToggleButtonLink__ivUFA {
  text-decoration: none !important;
}

.filtersButtons_addButton__B85FJ {
  height: 40px;
  width: auto;
}
.filtersButtons_addButtonLink__0Fi-o {
  padding: 0 0.5rem !important;
}
@media screen and (max-width: 575px) {
  .filtersButtons_filterToggleButton__7-WK7 {
    width: 44px;
    height: 40px;
  }
  .filtersButtons_addButton__B85FJ {
    width: 44px !important;
    height: 40px !important;
  }
}
@media screen and (min-width: 992px) {
  .filtersButtons_addButton__B85FJ {
    width: calc(100% - 10px) !important;
  }
  .filtersButtons_filter__oEKT7{
    margin-left: -11px;
  }
}
@media screen and (width > 350px) and (width <= 375px) {

  .filtersButtons_filter__oEKT7{
    margin-left: -11px;
  }
}
@media screen and (width > 380px) and (width <= 420px) {

  .filtersButtons_filter__oEKT7{
    margin-left: -8px;
  }
}
@media screen and (width > 310px) and (width <= 350px) {

  .filtersButtons_filter__oEKT7{
    margin-left: -15px;
  }
  .filtersButtons_addbtn__y6xvh{
    margin-left: 4px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/filtersButtons.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,4BAA4B;AAC9B;AACA;EACE;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,sBAAsB;IACtB,uBAAuB;EACzB;AACF;AACA;EACE;IACE,mCAAmC;EACrC;EACA;IACE,kBAAkB;EACpB;AACF;AACA;;EAEE;IACE,kBAAkB;EACpB;AACF;AACA;;EAEE;IACE,iBAAiB;EACnB;AACF;AACA;;EAEE;IACE,kBAAkB;EACpB;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".filterToggleButton {\n  height: 40px;\n}\n\n.filterToggleButtonLink {\n  text-decoration: none !important;\n}\n\n.addButton {\n  height: 40px;\n  width: auto;\n}\n.addButtonLink {\n  padding: 0 0.5rem !important;\n}\n@media screen and (max-width: 575px) {\n  .filterToggleButton {\n    width: 44px;\n    height: 40px;\n  }\n  .addButton {\n    width: 44px !important;\n    height: 40px !important;\n  }\n}\n@media screen and (min-width: 992px) {\n  .addButton {\n    width: calc(100% - 10px) !important;\n  }\n  .filter{\n    margin-left: -11px;\n  }\n}\n@media screen and (width > 350px) and (width <= 375px) {\n\n  .filter{\n    margin-left: -11px;\n  }\n}\n@media screen and (width > 380px) and (width <= 420px) {\n\n  .filter{\n    margin-left: -8px;\n  }\n}\n@media screen and (width > 310px) and (width <= 350px) {\n\n  .filter{\n    margin-left: -15px;\n  }\n  .addbtn{\n    margin-left: 4px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterToggleButton": `filtersButtons_filterToggleButton__7-WK7`,
	"filterToggleButtonLink": `filtersButtons_filterToggleButtonLink__ivUFA`,
	"addButton": `filtersButtons_addButton__B85FJ`,
	"addButtonLink": `filtersButtons_addButtonLink__0Fi-o`,
	"filter": `filtersButtons_filter__oEKT7`,
	"addbtn": `filtersButtons_addbtn__y6xvh`
};
export default ___CSS_LOADER_EXPORT___;
