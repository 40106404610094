// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Invoice_Card__FsC9x{
    width: 1080px;
}

.Invoice_brandLogo__boyoH{
    width: 200px;
    max-height: 200px;
    height: 86px;
    object-fit: contain;
}

.Invoice_terms__SXtMr {
    font-size: 13px;
    font-style: italic;
}

@media screen AND (max-width:768px) {
    
}`, "",{"version":3,"sources":["webpack://./src/Pages/PurchaseOrder/Invoices/Invoice.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;;AAEA","sourcesContent":[".Card{\n    width: 1080px;\n}\n\n.brandLogo{\n    width: 200px;\n    max-height: 200px;\n    height: 86px;\n    object-fit: contain;\n}\n\n.terms {\n    font-size: 13px;\n    font-style: italic;\n}\n\n@media screen AND (max-width:768px) {\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Card": `Invoice_Card__FsC9x`,
	"brandLogo": `Invoice_brandLogo__boyoH`,
	"terms": `Invoice_terms__SXtMr`
};
export default ___CSS_LOADER_EXPORT___;
