// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_loaderPageModal__naM2c .modal-content{
    background: transparent !important;
}

.loader_gbBlur__mdOcb{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
}`, "",{"version":3,"sources":["webpack://./src/Components/loaders/loader.module.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,kCAA0B;YAA1B,0BAA0B;AAC9B","sourcesContent":[":local(.loaderPageModal) :global(.modal-content){\n    background: transparent !important;\n}\n\n.gbBlur{\n    width: 100vw;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.1);\n    backdrop-filter: blur(1px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderPageModal": `loader_loaderPageModal__naM2c`,
	"gbBlur": `loader_gbBlur__mdOcb`
};
export default ___CSS_LOADER_EXPORT___;
