import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "./Cookies";
import authService from "./authService";
const initialState = {
  user: null,
  error: false,
  message: "",
  registerId: -1,
  allLocations: [],
  throughLogin: false,
};
// Login User
export const login = createAsyncThunk(process.env.REACT_APP_API_URL + "/auth/login-seller", async (user, thunkApi) => {
  try {
    const res = await authService.login(user);
    if (res) {
      return res;
    } else {
      return thunkApi.rejectWithValue("Invalid email/password!");
    }
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString();

    return thunkApi.rejectWithValue(message);
  }
});

export const loginByOtp = createAsyncThunk(process.env.REACT_APP_API_URL + "/otp/verify", async (payload, thunkApi) => {
  try {
    const res = await authService.loginByOtp(payload);
    if (res) {
      return res;
    } else {
      return thunkApi.rejectWithValue("Invalid OTP");
    }
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});
export const currentUser = createAsyncThunk(process.env.REACT_APP_API_URL + "/user/get-seller-user-by-accesstoken", async (refreshToken, thunkApi) => {
  try {
    const res = await authService.getUser(refreshToken);
    if (res) {
      return res;
    } else {
      return thunkApi.rejectWithValue("Invalid email/password!");
    }
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});

export const currentUserForAdmin = createAsyncThunk(process.env.REACT_APP_API_URL + "/user/direct-login", async (refreshToken, thunkApi) => {
  try {
    const res = await authService.getUserForAdmin(refreshToken);
    if (res) {
      return res;
    } else {
      return thunkApi.rejectWithValue("Invalid email/password!");
    }
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});

export const getRegisterId = createAsyncThunk(process.env.REACT_APP_API_URL + "/cash-registers/get-id", async ({ shop, location }, thunkApi) => {
  try {
    const res = await authService.getRegisterId(shop, location);
    if (res) {
      return res;
    } else {
      return thunkApi.rejectWithValue("Invalid shop or location!");
    }
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});
export const updateTourGuide = createAsyncThunk(process.env.REACT_APP_API_URL + "/settings/tour-guide", async ({ tour_guide, user_id }, thunkApi) => {
  try {
    const res = await authService.updateTourGuide({ tour_guide, user_id });
    if (res) {
      return res;
    } else {
      return thunkApi.rejectWithValue("Invalid user!");
    }
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.error.message) || error.message || error.toString();
    return thunkApi.rejectWithValue(message);
  }
});
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      // state.user = action.payload.user.user;
    },
    logout: (state, action) => {
      state.user = null;
      state.error = false;
      state.message = "";
      state.registerId = -1;
      state.allLocations = [];
      localStorage.removeItem("refreshToken");
      if (process.env.REACT_APP_ENV === "development") {
        Cookies.remove(process.env.REACT_APP_ACCESS_TOKEN);
      } else {
        Cookies.remove(process.env.REACT_APP_ACCESS_TOKEN, {
          domain: ".techbazaar.pk",
        });
      }
    },
    addUser: (state, action) => {
      state.user = action.payload;
    },

    setAllLocations: (state, action) => {
      state.allLocations = action.payload;
    },
    forcedActionCompleted: (state, action) => {
      state.user = {
        ...state.user,
        redirections: state.user.redirections.slice(1),
      };
    },
    throughLoginFalse: (state, action) => {
      state.throughLogin = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.error = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.throughLogin = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.user = null;
        state.error = true;
        state.message = action.payload;
      })
      .addCase(currentUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(currentUser.rejected, (state, action) => {
        state.user = null;
        state.error = true;
        state.message = "Session Expired! Please Login!";
      })
      .addCase(currentUserForAdmin.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(currentUserForAdmin.rejected, (state, action) => {
        state.user = null;
        state.error = true;
        state.message = action.payload;
      })
      .addCase(getRegisterId.rejected, (state, action) => {
        state.registerId = -1;
      })
      .addCase(getRegisterId.fulfilled, (state, action) => {
        state.registerId = action.payload;
      })
      .addCase(loginByOtp.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.throughLogin = true;
      })
      .addCase(loginByOtp.rejected, (state, action) => {
        state.user = null;
        state.error = true;
        state.message = action.payload;
      })
      .addCase(updateTourGuide.rejected, (state, action) => {
        // state.user.tour = action.payload;
      })
      .addCase(updateTourGuide.fulfilled, (state, action) => {
        const { key, value } = action.payload;
        state.user.tour[key] = value;
      });
  },
});
export const authActions = authSlice.actions;
export default authSlice.reducer;
