// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activationModal_modalDialog__w9qMa .table{
    margin-bottom: 0 !important;
}

/* .modalDialog :global(.modal-content){
    max-height: calc(100vh - 100px) !important;
} */

.activationModal_tableContainer__U3Lpn{
    /* max-height: calc(100vh - 220px) !important; */
    /* max-height: calc(100vh - 320px) !important; */
    max-height: calc(100vh - 320px) !important;

    width: 100%;
    /* height: 85% !important; */
}
.activationModal_tableContainer__U3Lpn::-webkit-scrollbar {
    width: 0.3em;
  }
  .activationModal_tableContainer__U3Lpn::-webkit-scrollbar-thumb {
    background-color: rgba(178, 183, 203, 1);
    outline: 1px solid rgba(222, 223, 228, 1);
    border-radius: 6px;
  }
  .activationModal_tableContainer__U3Lpn::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

@media screen and (max-width: 575px) {
    .activationModal_modalDialog__w9qMa .modal-content{
        height: 100vh !important;
    }
    .activationModal_tableContainer__U3Lpn{
      max-height: calc(100% - 65px) !important;
      height: 100% !important;
  }
  }
@media screen and (max-width: 500px) {
    .activationModal_modalDialog__w9qMa .modal-body{
        padding:0 !important;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/Listings/ListingActivationForms/activationModal.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;;GAEG;;AAEH;IACI,gDAAgD;IAChD,gDAAgD;IAChD,0CAA0C;;IAE1C,WAAW;IACX,4BAA4B;AAChC;AACA;IACI,YAAY;EACd;EACA;IACE,wCAAwC;IACxC,yCAAyC;IACzC,kBAAkB;EACpB;EACA;IACE,4CAA4C;EAC9C;;AAEF;IACI;QACI,wBAAwB;IAC5B;IACA;MACE,wCAAwC;MACxC,uBAAuB;EAC3B;EACA;AACF;IACI;QACI,oBAAoB;IACxB;EACF","sourcesContent":[".modalDialog :global(.table){\n    margin-bottom: 0 !important;\n}\n\n/* .modalDialog :global(.modal-content){\n    max-height: calc(100vh - 100px) !important;\n} */\n\n.tableContainer{\n    /* max-height: calc(100vh - 220px) !important; */\n    /* max-height: calc(100vh - 320px) !important; */\n    max-height: calc(100vh - 320px) !important;\n\n    width: 100%;\n    /* height: 85% !important; */\n}\n.tableContainer::-webkit-scrollbar {\n    width: 0.3em;\n  }\n  .tableContainer::-webkit-scrollbar-thumb {\n    background-color: rgba(178, 183, 203, 1);\n    outline: 1px solid rgba(222, 223, 228, 1);\n    border-radius: 6px;\n  }\n  .tableContainer::-webkit-scrollbar-track {\n    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\n  }\n\n@media screen and (max-width: 575px) {\n    .modalDialog :global(.modal-content){\n        height: 100vh !important;\n    }\n    .tableContainer{\n      max-height: calc(100% - 65px) !important;\n      height: 100% !important;\n  }\n  }\n@media screen and (max-width: 500px) {\n    .modalDialog :global(.modal-body){\n        padding:0 !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalDialog": `activationModal_modalDialog__w9qMa`,
	"tableContainer": `activationModal_tableContainer__U3Lpn`
};
export default ___CSS_LOADER_EXPORT___;
