import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const SaleHistoryTour = ({ setActiveTab }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const saleHistoryTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "SaleHistory", value: true } }));
      setTimeout(() => {
        saleHistoryTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Sale-History",
        popover: {
          title: "Sale History",
          description: "Overall sales history.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#sale-history",
        popover: {
          title: `A guide to sales history`,
          description: "This page shows information of all sold products with refund ,view & print  invoice options.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sale-history-search",
        popover: {
          title: `Search Bar`,
          description: "seller can search for any sold out product by it’s customer’s name ,email, phone no, or by sale number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sale-history-date-filter",
        popover: {
          title: `Date Filter`,
          description: "From this dropdown seller can select which days sales he wants to see.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sale-history-status-filter",
        popover: {
          title: `Status Filter`,
          description: "From this dropdown, the seller can select which type of sales to view.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sale-history-page-size",
        popover: {
          title: `Page Size`,
          description: "Seller can change the number of items per page.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-history-add-sales",
        popover: {
          title: `Add Sales`,
          description: "Navigate to sales page where seller can sell the products.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#sale-history-reset-filter",
        popover: {
          title: `Reset Filters`,
          description: "Seller can reset all filters.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sale-history-walk-in-customer",
        popover: {
          title: `Walk-in Customer`,
          description: "It shows sales of walk-in customer.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#table-settings",
        popover: {
          title: `Table settings`,
          description: "To customize the columns of table.",
          side: "right",
          align: "start",
        },
      },
      {
        element: ".nav-item:first-child > button",
        popover: {
          title: `Sales History`,
          description: "Sales of a location",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#sales-history-table > div:nth-child(2)",
        popover: {
          title: `Sales History`,
          description: "Shows list of sales of a specific location of a shop.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "Download sales history in CSV format.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#action-button",
        popover: {
          title: `Receive Payment`,
          description: "Seller can receive payment from customer if sale is done with partial payment.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#refund",
        popover: {
          title: `Refund`,
          description: "Seller can refund a fully paid sale or any product in a sale.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#print",
        popover: {
          title: `Print Invoice`,
          description: "Seller can print sale's invoice.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#view",
        popover: {
          title: `View Invoice`,
          description: "View sale's invoice.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#email",
        popover: {
          title: `Send Email`,
          description: "Seller can send sale's invoice via email to customer.",
          side: "right",
          align: "start",
        },
      },

      {
        element: "#archive",
        popover: {
          title: `Archive Sale`,
          description: "On clicking on this button, a confirmation dialog is shown. seller can archive sales or can restore the inventory .",
          side: "right",
          align: "start",
          onNextClick: () => {
            setActiveTab("Archived Sales");
            saleHistoryTour.moveNext();
          },
        },
      },
      {
        element: ".nav-item:last-child > button",
        popover: {
          title: `Archive`,
          description: "Shows dummy sales.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setActiveTab("Sales History");
            saleHistoryTour.movePrevious();
          },
        },
      },
      {
        element: "#sales-archive-history-table > div:nth-child(2)",
        popover: {
          title: `Sales History`,
          description: "Shows dummy sales of a specific location of a shop.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "SaleHistory", value: true } }));
            setTimeout(() => {
              saleHistoryTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    saleHistoryTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <div></div>;
};

export default SaleHistoryTour;
