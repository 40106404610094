import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const SubscriptionAlertModal = ({ show }) => {
  const navigate = useNavigate();

  const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    background: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    zIndex: 10000,
    display: show ? 'block' : 'none',
  };

  const buttonStyle = {
    padding: '10px',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  };

  const handleUpgrade = () => {
    // Implement logic to handle the upgrade process
    // For example, navigate to the plans page
    navigate('/settings/plans');
  };

  return (
    <div style={modalStyle}>
      <h2>Upgrade Your Subscription</h2>
      <hr/>
      <p className='hr'>This is a paid feature. Please upgrade your subscription to continue.</p>
      <hr/>
      <div className='d-flex justify-content-end'> <Button style={buttonStyle} variant='primary' onClick={handleUpgrade}>
        Upgrade Now
      </Button></div>
     
    </div>
  );
};

export default SubscriptionAlertModal;
