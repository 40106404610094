import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const QuotationTour = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const quotationTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Quotations", value: true } }));
      quotationTour.destroy();
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Quotations",
        popover: {
          title: "Quotation",
          description: "Overall Shop quotations.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#quotation",
        popover: {
          title: `Quotation Guide`,
          description: "This page shows all the quotations created by the seller.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#quotation-search",
        popover: {
          title: `Search Bar`,
          description: "seller can search for any sold out product by it’s customer’s name ,email, phone no, or by sale number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#quotation-date-filter",
        popover: {
          title: `Days Filter`,
          description: "From this dropdown seller can select which days quotations he wants to see.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#quotation-status-filter",
        popover: {
          title: `Status Filter`,
          description: "From this dropdown, the seller can see all quotations or those that have been expired or active.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#quotation-page-size",
        popover: {
          title: `Page Size`,
          description: "Seller can change the number of quotations per page.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#quotation-reset-filter",
        popover: {
          title: `Reset Filters`,
          description: "Clicking on this button will remove all applied filters on the search result.",
          side: "right",
          align: "start",
        },
      },

      {
        element: "#quotation-table > div:nth-child(1)",
        popover: {
          title: `Quotation Details`,
          description: "Shows list of quotation details.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#action-button",
        popover: {
          title: `Sell`,
          description: "Seller can a quotation directly from here to customer.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#view",
        popover: {
          title: `View Quotation`,
          description: "Seller can view, print and download quotation Invoice.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "Download sales history in CSV format.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Quotations", value: true } }));
            quotationTour.destroy();
          },
        },
      },
    ],
  });

  useEffect(() => {
    quotationTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <div></div>;
};

export default QuotationTour;
