// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddForm_background__amtq7 {
    opacity: 0.1;
    filter: blur(10px);
}

.AddForm_formContent__9FwI- {
    z-index: 1;
}

.AddForm_addForm__DcEla {
    padding: 0 50px !important;
}
.AddForm_form__510wo {
    width: 50% !important;
    box-shadow: 0px 20px 45px #f0edf6 !important;
}
@media screen and (max-width: 1500px) {
    .AddForm_addForm__DcEla {
        padding: 0 10px !important;
    }
    .AddForm_form__510wo {
        width: 80% !important;
    }
 }

@media screen and (max-width: 768px) {
    .AddForm_addForm__DcEla {
        padding: 0 10px !important;
    }
    .AddForm_form__510wo {
        width: 100% !important;
    }
 }

 @media screen and (max-width: 1024px) {
    .AddForm_addForm__DcEla {
        padding: 0 10px !important;
    }
    .AddForm_form__510wo {
        width: 100% !important;
    }
 }`, "",{"version":3,"sources":["webpack://./src/styles/AddForm.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,qBAAqB;IACrB,4CAA4C;AAChD;AACA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,qBAAqB;IACzB;CACH;;AAED;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,sBAAsB;IAC1B;CACH;;CAEA;IACG;QACI,0BAA0B;IAC9B;IACA;QACI,sBAAsB;IAC1B;CACH","sourcesContent":[".background {\n    opacity: 0.1;\n    filter: blur(10px);\n}\n\n.formContent {\n    z-index: 1;\n}\n\n.addForm {\n    padding: 0 50px !important;\n}\n.form {\n    width: 50% !important;\n    box-shadow: 0px 20px 45px #f0edf6 !important;\n}\n@media screen and (max-width: 1500px) {\n    .addForm {\n        padding: 0 10px !important;\n    }\n    .form {\n        width: 80% !important;\n    }\n }\n\n@media screen and (max-width: 768px) {\n    .addForm {\n        padding: 0 10px !important;\n    }\n    .form {\n        width: 100% !important;\n    }\n }\n\n @media screen and (max-width: 1024px) {\n    .addForm {\n        padding: 0 10px !important;\n    }\n    .form {\n        width: 100% !important;\n    }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `AddForm_background__amtq7`,
	"formContent": `AddForm_formContent__9FwI-`,
	"addForm": `AddForm_addForm__DcEla`,
	"form": `AddForm_form__510wo`
};
export default ___CSS_LOADER_EXPORT___;
