// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InvoiceCard_invoiceCard__THHON {
    max-width: 630px !important;
    width: auto;
    /* padding: 3rem; */
    color: black;
    margin: auto;
    box-shadow: 0px 20px 45px #F0EDF6;
}

.InvoiceCard_logoimg__Y3bgz{
    width: 200px;
    max-height: 200px;
    height: 86px;
    object-fit: cover;
}

.InvoiceCard_greyscale__\\+Ipy6{
    filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);
}

`, "",{"version":3,"sources":["webpack://./src/styles/InvoiceCard.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,iCAAiC;AACrC;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IAEI,uEAAuE;AAC3E","sourcesContent":[".invoiceCard {\n    max-width: 630px !important;\n    width: auto;\n    /* padding: 3rem; */\n    color: black;\n    margin: auto;\n    box-shadow: 0px 20px 45px #F0EDF6;\n}\n\n.logoimg{\n    width: 200px;\n    max-height: 200px;\n    height: 86px;\n    object-fit: cover;\n}\n\n.greyscale{\n    -webkit-filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);\n    filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invoiceCard": `InvoiceCard_invoiceCard__THHON`,
	"logoimg": `InvoiceCard_logoimg__Y3bgz`,
	"greyscale": `InvoiceCard_greyscale__+Ipy6`
};
export default ___CSS_LOADER_EXPORT___;
