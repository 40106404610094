import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const RefundHistoryTour = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const refundHistoryTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "RefundHistory", value: true } }));
      setTimeout(() => {
        refundHistoryTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Refund-History",
        popover: {
          title: "Refund History",
          description: "Overall Refunded sales history.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#refund-history",
        popover: {
          title: `A guide to refund history`,
          description: "This page shows information  of all refunded products with print invoice option.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#refund-history-search",
        popover: {
          title: `Search Bar`,
          description: "seller can search for any sold out product by it’s customer’s name ,email, phone no, or by sale number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#refund-history-date-filter",
        popover: {
          title: `Date Filter`,
          description: "From this dropdown seller can select which days refunded sales he wants to see.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#refund-history-page-size",
        popover: {
          title: `Page Size`,
          description: "Seller can change the number of items per page.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#refund-history-reset-filter",
        popover: {
          title: `Reset Filters`,
          description: "Seller can reset all filters.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#refund-history-walk-in-customer",
        popover: {
          title: `Walk-in Customer`,
          description: "It shows refunded sales of walk-in customer.",
          side: "right",
          align: "start",
        },
      },
      // {
      //   element: "#table-settings",
      //   popover: {
      //     title: `Table settings`,
      //     description: "To customize the columns of table.",
      //     side: "right",
      //     align: "start",
      //   },
      // },

      {
        element: "#refund-history-table > div:nth-child(1)",
        popover: {
          title: `Refund History`,
          description: "Shows list of refunded sales of a shop.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#print",
        popover: {
          title: `Print Invoice`,
          description: "Seller can print sale's invoice.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "Download sales history in CSV format.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "RefundHistory", value: true } }));
            setTimeout(() => {
              refundHistoryTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    refundHistoryTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <div></div>;
};

export default RefundHistoryTour;
