// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Setting_setcol__lOsfS{
    background-color: white;
}
.Setting_navtabs__kpztp .nav-item{
    border: 1px solid #8834ff;
}
.Setting_btn__tisp4{
    border-radius: 7px 0px 0px 7px !important;
    padding: 5px 20px !important;
    background-color: white !important;
}
.Setting_btn__tisp4:focus{
    background-color: #8834ff !important;
    border: 1px solid #8834ff !important;
color: #8834ff;


}
.Setting_row__RMuDs{
    border-radius: 7px  ;

}
.Setting_btns__ncTbz{
    border-radius: 0px !important;
    padding: 5px 20px !important;

}
.Setting_btns__ncTbz:disabled{
    color: gray !important;
}
.Setting_btns__ncTbz:focus{
    background-color: #8834ff !important;
    border: 1px solid #8834ff !important;
color: #8834ff;


}
.Setting_btne__okaKa{
    border-radius: 0px 7px 7px 0px !important ;
    padding: 5px 20px !important;


}
.Setting_btne__okaKa:disabled{
    color: gray !important;
}
.Setting_btne__okaKa:focus{
    background-color: #8834ff !important;
    border: 1px solid #8834ff !important;
color: #8834ff;


}
.Setting_activebtn__C0yxz{
    background-color: #8834ff !important;
    color: white !important;
}

.Setting_printerSettings__v9euH {
    height: 100%;
}

@media screen and (max-width: 992px) {
    .Setting_printerSettings__v9euH {
        height: auto;
    }
}
@media screen and (min-width: 800px) {
    .Setting_notShow__WoJbv {
    display: 'none';
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Setting.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yCAAyC;IACzC,4BAA4B;IAC5B,kCAAkC;AACtC;AACA;IACI,oCAAoC;IACpC,oCAAoC;AACxC,cAAc;;;AAGd;AACA;IACI,oBAAoB;;AAExB;AACA;IACI,6BAA6B;IAC7B,4BAA4B;;AAEhC;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,oCAAoC;IACpC,oCAAoC;AACxC,cAAc;;;AAGd;AACA;IACI,0CAA0C;IAC1C,4BAA4B;;;AAGhC;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,oCAAoC;IACpC,oCAAoC;AACxC,cAAc;;;AAGd;AACA;IACI,oCAAoC;IACpC,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;AACA;IACI;IACA,eAAe;IACf;AACJ","sourcesContent":[".setcol{\n    background-color: white;\n}\n.navtabs :global( .nav-item){\n    border: 1px solid #8834ff;\n}\n.btn{\n    border-radius: 7px 0px 0px 7px !important;\n    padding: 5px 20px !important;\n    background-color: white !important;\n}\n.btn:focus{\n    background-color: #8834ff !important;\n    border: 1px solid #8834ff !important;\ncolor: #8834ff;\n\n\n}\n.row{\n    border-radius: 7px  ;\n\n}\n.btns{\n    border-radius: 0px !important;\n    padding: 5px 20px !important;\n\n}\n.btns:disabled{\n    color: gray !important;\n}\n.btns:focus{\n    background-color: #8834ff !important;\n    border: 1px solid #8834ff !important;\ncolor: #8834ff;\n\n\n}\n.btne{\n    border-radius: 0px 7px 7px 0px !important ;\n    padding: 5px 20px !important;\n\n\n}\n.btne:disabled{\n    color: gray !important;\n}\n.btne:focus{\n    background-color: #8834ff !important;\n    border: 1px solid #8834ff !important;\ncolor: #8834ff;\n\n\n}\n.activebtn{\n    background-color: #8834ff !important;\n    color: white !important;\n}\n\n.printerSettings {\n    height: 100%;\n}\n\n@media screen and (max-width: 992px) {\n    .printerSettings {\n        height: auto;\n    }\n}\n@media screen and (min-width: 800px) {\n    .notShow {\n    display: 'none';\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setcol": `Setting_setcol__lOsfS`,
	"navtabs": `Setting_navtabs__kpztp`,
	"btn": `Setting_btn__tisp4`,
	"row": `Setting_row__RMuDs`,
	"btns": `Setting_btns__ncTbz`,
	"btne": `Setting_btne__okaKa`,
	"activebtn": `Setting_activebtn__C0yxz`,
	"printerSettings": `Setting_printerSettings__v9euH`,
	"notShow": `Setting_notShow__WoJbv`
};
export default ___CSS_LOADER_EXPORT___;
