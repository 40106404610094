// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddLocation_formMain__ACFRz {
  padding: 0 3rem !important;
}

/* :local(.multiSelect) :global(.react-select-container__value-container) {
  overflow: auto;
}
:local(.multiSelect)
  :global(.react-select-container__value-container)::-webkit-scrollbar {
  width: 0.3em;
}
:local(.multiSelect)
  :global(.react-select-container__value-container)::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
:local(.multiSelect)
  :global(.react-select-container__value-container)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */

@media screen and (max-width: 768px) {
  .AddLocation_formMain__ACFRz {
    padding: 0 1rem !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/AddLocation.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B;;AAEA;;;;;;;;;;;;;;;;GAgBG;;AAEH;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".formMain {\n  padding: 0 3rem !important;\n}\n\n/* :local(.multiSelect) :global(.react-select-container__value-container) {\n  overflow: auto;\n}\n:local(.multiSelect)\n  :global(.react-select-container__value-container)::-webkit-scrollbar {\n  width: 0.3em;\n}\n:local(.multiSelect)\n  :global(.react-select-container__value-container)::-webkit-scrollbar-thumb {\n  background-color: rgba(178, 183, 203, 1);\n  outline: 1px solid rgba(222, 223, 228, 1);\n  border-radius: 6px;\n}\n:local(.multiSelect)\n  :global(.react-select-container__value-container)::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\n} */\n\n@media screen and (max-width: 768px) {\n  .formMain {\n    padding: 0 1rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formMain": `AddLocation_formMain__ACFRz`
};
export default ___CSS_LOADER_EXPORT___;
