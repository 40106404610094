import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const TradeInProductsTour = ({ setAddModal, refresh }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const tradeInProductsTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "TradeInProduct", value: true } }));
      setTimeout(() => {
        refresh();
        tradeInProductsTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Trade-in-Product",
        popover: {
          title: "Trade-in Products",
          description: "Trade-in products.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#trade-in-products",
        popover: {
          title: `A guide to Trade-in products`,
          description: "On this page, all added trade-in product’s will be shown.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-products-search",
        popover: {
          title: `Search trade-in products`,
          description: "sellers can search any trade-in customer by product title or stock no.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-products-category-filter",
        popover: {
          title: `Filter trade-in products by category`,
          description: "From this dropdown seller can select which category’s trade-in products he wants to see.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-date-filter",
        popover: {
          title: `Days filter`,
          description: "Here seller can choose that which day’s trade-in products he wants to see.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-page-size",
        popover: {
          title: `Page size`,
          description: "Seller can choose that how many trade-in products he wants to see at a time.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-products",
        popover: {
          title: `Add Trade-in Products`,
          description: "Upon clicking on this button, seller can add new trade-in products.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setAddModal(true);
            setTimeout(() => {
              tradeInProductsTour.moveNext();
            });
          },
        },
      },
      {
        element: "#trade-in-add-product-modal > .modal-content",
        popover: {
          title: `Add Trade-in products`,
          description: "A form to add a trade-in products.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setAddModal(false);
            setTimeout(() => {
              tradeInProductsTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#trade-in-add-product-condition",
        popover: {
          title: `Product Condition`,
          description: "In this field ,USED condition will be selected automatically.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-product-date",
        popover: {
          title: `Entry Date`,
          description: "Here seller can select the date on which he wants to add trade-in product.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-product-location",
        popover: {
          title: `Location`,
          description: "From here seller can select the location on which he wants to add trade-in product.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-product-customer",
        popover: {
          title: `Customer`,
          description: "Seller can search and select the trade-in customer for whom he is going to add trade-in product.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-product-note",
        popover: {
          title: `Note`,
          description: "Any information related to trade-in product can  be added here.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-product-table",
        popover: {
          title: `Add Product Details`,
          description: "A table where seller can add trade-in product details including Title,Category,Quantity, Cost price,Sale price and an action to remove that product.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-product-add-more-button",
        popover: {
          title: `Add More`,
          description: "On clicking this button new row will be added and seller can add more trade-in products.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-product-payment-type",
        popover: {
          title: `Payment Type`,
          description: "Preferred payment type can be selected",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-product-amount",
        popover: {
          title: `Paid Amount`,
          description: "Seller can add paid amount of trade-in product.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-cancel-button",
        popover: {
          title: `Cancel`,
          description: "On clicking this Add trade-in product  modal will be closed, and no trade-in product will be added.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-tradeIn-button",
        popover: {
          title: `Add Trade-in Products`,
          description: "On clicking this button added information of trade-in product(s) will be saved.",
          side: "left",
          align: "start",
          onNextClick: () => {
            setAddModal(false);
            setTimeout(() => {
              tradeInProductsTour.moveNext();
            });
          },
        },
      },
      {
        element: "#trade-in-reset-filter",
        popover: {
          title: `Reset Filters`,
          description: "Clicking on this button will remove all applied filters on the search result.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setAddModal(true);
            setTimeout(() => {
              tradeInProductsTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#trade-in-products-table > div:nth-child(1)",
        popover: {
          title: `Trade-in Products List`,
          description: "Trade-in Products List.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "On clicking this, seller can download trade-in products record in csv file.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "TradeInProduct", value: true } }));
            setTimeout(() => {
              refresh();
              tradeInProductsTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    tradeInProductsTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default TradeInProductsTour;
