// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Subscription_card__LNKf6{
    box-shadow: 0px 20px 45px #F0EDF6;
}

.Subscription_title__3H3zQ{
background: #100146;
color: white;
padding-left: 2.5rem;
}
.Subscription_w_30__gm60k{
width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/styles/Subscription.module.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;AACrC;;AAEA;AACA,mBAAmB;AACnB,YAAY;AACZ,oBAAoB;AACpB;AACA;AACA,UAAU;AACV","sourcesContent":[".card{\n    box-shadow: 0px 20px 45px #F0EDF6;\n}\n\n.title{\nbackground: #100146;\ncolor: white;\npadding-left: 2.5rem;\n}\n.w_30{\nwidth: 40%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Subscription_card__LNKf6`,
	"title": `Subscription_title__3H3zQ`,
	"w_30": `Subscription_w_30__gm60k`
};
export default ___CSS_LOADER_EXPORT___;
