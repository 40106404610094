// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_paginationItems__23Stv .page-item:first-child .page-link {
    border-radius: 0 !important;
    border: 0 !important;
    background: transparent;
    font-size: 25px !important;
}
.Pagination_paginationItems__23Stv .page-item:last-child .page-link {
    border-radius: 0 !important;
    border: 0 !important;
    background: transparent;
    color: rgba(104, 110, 118, 1) !important;
    font-size: 25px !important;
}
.Pagination_paginationItems__23Stv .page-item:first-child .page-link:hover {
    color: rgba(217, 87, 249, 1) !important;
}
.Pagination_paginationItems__23Stv .page-item:last-child .page-link:hover {
    color: rgba(217, 87, 249, 1) !important;
}

.Pagination_paginationItems__23Stv .page-item.active .page-link{
    border: 2px solid #5932EA !important;
    border-color: #5932EA !important;
    background-color: transparent !important;
    color: #5932EA !important;
    border-radius: 6px;
    padding: 0.25rem 0.75rem !important;
}

.Pagination_paginationItems__23Stv .page-item .page-link{
    border: 0px !important;
    border-color: transparent !important;
    background-color: transparent !important;
    color: rgba(104, 110, 118, 1) !important;
}

.Pagination_paginationItems__23Stv .page-link:focus {
    box-shadow: none !important;
}

 .Pagination_paginationItems__23Stv .active{
    z-index: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/Pagination.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,oBAAoB;IACpB,uBAAuB;IACvB,0BAA0B;AAC9B;AACA;IACI,2BAA2B;IAC3B,oBAAoB;IACpB,uBAAuB;IACvB,wCAAwC;IACxC,0BAA0B;AAC9B;AACA;IACI,uCAAuC;AAC3C;AACA;IACI,uCAAuC;AAC3C;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;IAChC,wCAAwC;IACxC,yBAAyB;IACzB,kBAAkB;IAClB,mCAAmC;AACvC;;AAEA;IACI,sBAAsB;IACtB,oCAAoC;IACpC,wCAAwC;IACxC,wCAAwC;AAC5C;;AAEA;IACI,2BAA2B;AAC/B;;CAEC;IACG,qBAAqB;AACzB","sourcesContent":[".paginationItems :global(.page-item:first-child .page-link) {\n    border-radius: 0 !important;\n    border: 0 !important;\n    background: transparent;\n    font-size: 25px !important;\n}\n.paginationItems :global(.page-item:last-child .page-link) {\n    border-radius: 0 !important;\n    border: 0 !important;\n    background: transparent;\n    color: rgba(104, 110, 118, 1) !important;\n    font-size: 25px !important;\n}\n.paginationItems :global(.page-item:first-child .page-link:hover) {\n    color: rgba(217, 87, 249, 1) !important;\n}\n.paginationItems :global(.page-item:last-child .page-link:hover) {\n    color: rgba(217, 87, 249, 1) !important;\n}\n\n.paginationItems :global(.page-item,.active .page-link){\n    border: 2px solid #5932EA !important;\n    border-color: #5932EA !important;\n    background-color: transparent !important;\n    color: #5932EA !important;\n    border-radius: 6px;\n    padding: 0.25rem 0.75rem !important;\n}\n\n.paginationItems :global(.page-item .page-link){\n    border: 0px !important;\n    border-color: transparent !important;\n    background-color: transparent !important;\n    color: rgba(104, 110, 118, 1) !important;\n}\n\n.paginationItems :global(.page-link:focus) {\n    box-shadow: none !important;\n}\n\n .paginationItems :global(.active){\n    z-index: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationItems": `Pagination_paginationItems__23Stv`
};
export default ___CSS_LOADER_EXPORT___;
