// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddInventory_borderedHr__F80Te {
    border-top: 2px dashed #DEDFE4;
    height: 0 !important;
    background: none !important;
}
.AddInventory_borderedHr2__Wx\\+Pu {
    border-top: 2px dashed #c5c7d1;
    height: 0 !important;
    background: none !important;
}

.AddInventory_formMain__Oyj\\+0 {
    padding: 0 3rem !important;
}
.AddInventory_selectCAt__9cynC .react-select-container__menu-list {
    /* position: absolute; */
    bottom: 0;
}
/* .validationStyle{

    border: 1px solid red !important; 
    background-color: yellow;


} */

.AddInventory_upload_btn_wrapper__HutAc{
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}
.AddInventory_upload_btn_wrapper__HutAc input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  }
@media screen and (max-width: 768px) {
    .AddInventory_formMain__Oyj\\+0 {
        padding: 0 1rem !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/AddInventory.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,oBAAoB;IACpB,2BAA2B;AAC/B;AACA;IACI,8BAA8B;IAC9B,oBAAoB;IACpB,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,wBAAwB;IACxB,SAAS;AACb;AACA;;;;;;GAMG;;AAEH;IACI,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,eAAe;AACnB;AACA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,UAAU;EACV;AACF;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".borderedHr {\n    border-top: 2px dashed #DEDFE4;\n    height: 0 !important;\n    background: none !important;\n}\n.borderedHr2 {\n    border-top: 2px dashed #c5c7d1;\n    height: 0 !important;\n    background: none !important;\n}\n\n.formMain {\n    padding: 0 3rem !important;\n}\n.selectCAt :global(.react-select-container__menu-list) {\n    /* position: absolute; */\n    bottom: 0;\n}\n/* .validationStyle{\n\n    border: 1px solid red !important; \n    background-color: yellow;\n\n\n} */\n\n.upload_btn_wrapper{\n    position: relative;\n    overflow: hidden;\n    display: inline-block;\n    cursor: pointer;\n}\n.upload_btn_wrapper input[type=file] {\n  position: absolute;\n  left: 0;\n  top: 0;\n  opacity: 0;\n  }\n@media screen and (max-width: 768px) {\n    .formMain {\n        padding: 0 1rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderedHr": `AddInventory_borderedHr__F80Te`,
	"borderedHr2": `AddInventory_borderedHr2__Wx+Pu`,
	"formMain": `AddInventory_formMain__Oyj+0`,
	"selectCAt": `AddInventory_selectCAt__9cynC`,
	"upload_btn_wrapper": `AddInventory_upload_btn_wrapper__HutAc`
};
export default ___CSS_LOADER_EXPORT___;
