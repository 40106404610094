// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all {
    .barcode_pageBreak__qP-II {
        display: none;
    }
}

/* @media print {
    html,
    body{
        height: initial !important;
        overflow: initial !important; 
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }
} */

@media print {
    
    .barcode_pageBreak__qP-II{
        page-break-before: always;
        /* margin-top: 1rem;
        display: block;
        page-break-before: auto; */
    }
    .barcode_barcode__ojDfS {
        margin-top: 3pt;
        font-size:  4vw;
        /* size: 2in 1in; 
        size: fit-content; 
        page-break-before: always;
        width: auto;
        height: fit-content;
        block-size: fit-content; */
    }
}
.barcode_barcodeTitleText__aeWex{
    width: 279px;
    text-overflow: ellipsis;
    overflow: hidden;
}

`, "",{"version":3,"sources":["webpack://./src/Components/Barcode/barcode.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;;;;;;;;GAQG;;AAEH;;IAEI;QACI,yBAAyB;QACzB;;kCAE0B;IAC9B;IACA;QACI,eAAe;QACf,eAAe;QACf;;;;;kCAK0B;IAC9B;AACJ;AACA;IACI,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;AACpB","sourcesContent":["@media all {\n    .pageBreak {\n        display: none;\n    }\n}\n\n/* @media print {\n    html,\n    body{\n        height: initial !important;\n        overflow: initial !important; \n        print-color-adjust: exact;\n        -webkit-print-color-adjust: exact;\n    }\n} */\n\n@media print {\n    \n    .pageBreak{\n        page-break-before: always;\n        /* margin-top: 1rem;\n        display: block;\n        page-break-before: auto; */\n    }\n    .barcode {\n        margin-top: 3pt;\n        font-size:  4vw;\n        /* size: 2in 1in; \n        size: fit-content; \n        page-break-before: always;\n        width: auto;\n        height: fit-content;\n        block-size: fit-content; */\n    }\n}\n.barcodeTitleText{\n    width: 279px;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageBreak": `barcode_pageBreak__qP-II`,
	"barcode": `barcode_barcode__ojDfS`,
	"barcodeTitleText": `barcode_barcodeTitleText__aeWex`
};
export default ___CSS_LOADER_EXPORT___;
