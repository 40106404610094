import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import SearchableSelect from "../../SearchableSelect";
import moment from "moment";
import CustomizeTableview from "../../TableViews/CustomizeTableview";
import LoaderPage from "../../loaders/loaderPage";
import NoResult from "../../NoResult";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from "../../../styles/Reports.module.css";
import Csv from "../../csv/Csv";
import { requestDateTime } from "../../../Features/dateTime";
import SalesSummaryReportTour from "../../../TourGuides/SalesSummaryReportTour";

function SalesSummary() {
  const [data, setData] = useState([]);
  const [refresh, toggle] = useState(false);
  const [locationFields, setLocationFields] = useState({});
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const user_type = user.user_type;
  const shopID = user.shop_id;
  const currentDate = moment();
  const currentMonth = moment(currentDate).startOf("month").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
  const [startDate, setStartDate] = useState(requestDateTime(new Date(moment(currentDate).startOf("month").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"))));
  const [endDate, setEndDate] = useState(requestDateTime(new Date(moment(currentDate).endOf("month").format("ddd MMM DD YYYY 00:00:00 [GMT]ZZ (z)"))));
  const [location, setLocation] = useState({});
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/location/get-location-by-user/${user.user_id}`, { shop_id: user.shop_id, user_type: user_type }, { withCredentials: true })
      .then((res) => {
        const locationsArr = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));

        setLocation(locationsArr);
        setLocationFields({
          value: locationsArr[0]?.value,
          label: locationsArr[0]?.label,
          isDisabled: locationsArr[0]?.isDisabled,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [user.user_id, user.shop_id, user_type]);
  const fetchDownloadData = async () => {
    if (shopID) {
      return await axios.get(process.env.REACT_APP_API_URL + `/reports/daily-sales-summary/${shopID}/${locationFields.value}?date[from]=${startDate}&date[to]=${endDate}`, { withCredentials: true });
    }
  };
  const fetchData = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/reports/daily-sales-summary/${shopID}/${locationFields.value}?date[from]=${requestDateTime(startDate)}&date[to]=${requestDateTime(endDate)}`, { withCredentials: true });
      if (res) {
        setIsLoading(false);
        setData(
          res?.data?.data.map((item) => {
            return {
              date: item.date,
              cash_payments: item.cash_payments.toLocaleString("en-US"),
              card_payments: item.card_payments.toLocaleString("en-US"),
              transfer_payments: item.transfer_payments.toLocaleString("en-US"),
              total_payments: item.total_payments.toLocaleString("en-US"),
              total_discount: item.total_discount.toLocaleString("en-US"),
              total_sales: item.total_sales.toLocaleString("en-US"),
              total_refunds: item.total_refunds.toLocaleString("en-US"),
              total_shipping: item.total_shipping.toLocaleString("en-US"),
              net_sales: item.net_sales.toLocaleString("en-US"),
              total_due: item.total_due.toLocaleString("en-US"),
            };
          })
        );
        toggle((prev) => !prev);
      }
    } catch (error) {
      setData([]);

      throw new Error(error.response.data);
    }
  };
  useEffect(() => {
    if (shopID && locationFields.value) {
      fetchData();
    }
  }, [locationFields, startDate]);
  const handleLocation = (e) => {
    if (e.value) {
      setLocationFields(e);
    } else {
      setLocationFields({});
    }
  };
  const onChange = (date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
    setStartDate(new Date(startDate));
    setEndDate(endDate);
  };
  return (
    <>
      {user?.tour && !user?.tour.SalesSummaryReport && <SalesSummaryReportTour />}
      <Row className="align-items-baseline  bg-white py-2 px-1 d-flex  mb-3 " id="sales-summary-report-filter">
        <div style={{ maxWidth: "200px" }} className="me-3 ">
          <SearchableSelect placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={Object.keys(locationFields).length > 0 ? locationFields : null} style={{ width: "100px" }} disabled={!location.length} />
        </div>
        <div style={{ maxWidth: "200px" }} className="me-3 ">
          <DatePicker className={style.datpicker} selected={startDate} showMonthYearPicker onChange={onChange} dateFormat="MM/yyyy" maxDate={new Date()} />
        </div>
      </Row>
      {isLoading ? (
        <div style={{ height: "50vh", display: "flex", justifyContent: "center" }}>
          <LoaderPage />
        </div>
      ) : !data[0] ? (
        <NoResult name="Sales Summary" />
      ) : (
        <div id="sales-summary-report-data">
          <CustomizeTableview actions={false} data={data} totalPayments={{ column: "total_payments" }} netSales={{ column: "net_sales" }} salesDate={{ column: "date" }} />
          <Row className="mb-2">
            <Col className="d-none d-sm-block" sm="2" md="3" lg="3">
              <span className="text-secondary fs-6">{data?.length} result(s)</span>
            </Col>
            <Col xs="12" sm="7" md="6" lg="6"></Col>
            <Col className="d-sm-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{data?.length} result(s)</span>
            </Col>
            <Col xs="6" sm="3" md="3" lg="3" className="text-end justify-content-end pe-2 px-0">
              <Csv
                filename={`${
                  "Sales Summary " +
                  user.shop_name +
                  " " +
                  new Date().toLocaleDateString("en-US", {
                    timeZone: "Asia/Karachi",
                  })
                }`}
                api={fetchDownloadData}
                responseObject={(data) => data.data.data}
                format={(data) => {
                  return {
                    date: data.date,
                    cashPayments: data.cash_payments,
                    cardPayments: data.card_payments,
                    transferPayments: data.transfer_payments,
                    totalPayments: data.total_payments,
                    totalDiscount: data.total_discount,
                    totalSales: data.total_sales,
                    totalRefunds: data.total_refunds,
                    totalShipping: data.total_shipping,
                    netSales: data.net_sales,
                    totaldue: data.total_due,
                  };
                }}
                error={(err) => {
                  const tempErr = err.response.data.error;
                  if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                    return true;
                  } else {
                    return false;
                  }
                }}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default SalesSummary;
