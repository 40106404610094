import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const SupplierTour = ({ setVendorType }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const supplierTour = driver({
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Vendorlist", value: true } }));
      setTimeout(() => {
        supplierTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Supplier",
        popover: {
          title: `Supplier`,
          description: "Shows and manages supplier details.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier",
        popover: {
          title: `A guide to Supplier`,
          description: `Shows and manages supplier details.<br/>A supplier refers to a business or individual that provides the products or inventory to the retailer`,
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier-search",
        popover: {
          title: `Search Bar`,
          description: "Here, seller can search for any supplier by his name.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier-date-filter",
        popover: {
          title: `Day Filter`,
          description: "Here, the seller can choose which day’s supplier he wants to see.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#supplier-status-filter",
        popover: {
          title: `Status Filter`,
          description: "seller can see supplier based on it’s purchase order payment status.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier-page-size",
        popover: {
          title: `Page Size`,
          description: "Seller can change the number of records of supplier per page.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier-add-supplier",
        popover: {
          title: `Add Supplier`,
          description: "Navigate to add supplier form page where seller can add new suppliers.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#supplier-reset-filter",
        popover: {
          title: `Reset Filters`,
          description: "Clicking on this button will remove all applied filters on the search result.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier-type",
        popover: {
          title: `Supplier with Purchase Order`,
          description: "By selecting it, It shows the list of suppliers who have purchase order.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#supplier-with-purchase-table > div:nth-child(1)",
        popover: {
          title: `Supplier with Purchase Order`,
          description: "On this page, all supplier’s details will be shown with their statuses and actions buttons i.e. Edit & delete button.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#edit",
        popover: {
          title: `Edit Supplier`,
          description: "Clicking on this button seller can edit a supplier.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#remove",
        popover: {
          title: `Delete Supplier`,
          description: "Clicking on this button seller can delete a supplier.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "Download suppliers list in CSV format.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setVendorType("other");
            setTimeout(() => {
              supplierTour.moveNext();
            });
          },
        },
      },
      {
        element: "#supplier-type",
        popover: {
          title: `Supplier without Purchase Order`,
          description: "By selecting it, It shows the list of suppliers who don't have purchase order.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setVendorType("primary");
            setTimeout(() => {
              supplierTour.movePrevious();
            }, 100);
          },
        },
      },
      {
        element: "#supplier-without-purchase-table > div:nth-child(1)",
        popover: {
          title: `Supplier without Purchase Order`,
          description: "On this page, all supplier’s details will be shown with their statuses and actions buttons i.e. Edit & delete button.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "Vendorlist", value: true } }));
            setTimeout(() => {
              supplierTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    supplierTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default SupplierTour;
