// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetPassword_formContent__-laai {
    z-index: 1;
}

.ResetPassword_addForm__EQ3Kw {
    padding: 0 50px !important;
}
.ResetPassword_form__6ehus {
    width: 50% !important;
    box-shadow: 0px 20px 45px #f0edf6 !important;
}
.ResetPassword_formMain__fII7W {
    padding: 0 3rem !important;
}

.ResetPassword_passwordInput__QGoD9 {
    position: relative;
}
.ResetPassword_passwordInput__QGoD9 .form-control:invalid {
    background-position: right calc(0.375em + 1.5rem) center !important;
  }
.ResetPassword_passwordValidate__-b8bo {
    background-image: none !important;
}

.ResetPassword_passwordInput__QGoD9 svg {
    bottom: 13px;
    position: absolute;
    right: 10px;
    width: 16px;
}

.ResetPassword_passwordField__Q0YUq {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.ResetPassword_logoemail__6a87S{
    position:relative;
    left:17rem;
     margin-bottom:-4.8rem

}

.ResetPassword_Checkbox__Apf1l label {
     font-size: 13px;
}

@media screen and (max-width: 1024px) {
    .ResetPassword_addForm__EQ3Kw {
        padding: 0 10px !important;
    }
    .ResetPassword_form__6ehus {
        width: 100% !important;
    }
 }

@media screen and (max-width: 768px) {
    .ResetPassword_addForm__EQ3Kw {
        padding: 0 10px !important;
    }
    .ResetPassword_form__6ehus {
        width: 100% !important;
    }
    .ResetPassword_formMain__fII7W {
        padding: 0 1rem !important;
    }
    .ResetPassword_logoemail__6a87S{
        position:relative;
        left:16rem;
         margin-bottom:-4.8rem
    
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/ResetPassword.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,qBAAqB;IACrB,4CAA4C;AAChD;AACA;IACI,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,mEAAmE;EACrE;AACF;IACI,iCAAiC;AACrC;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,iBAAiB;IACjB,UAAU;KACT;;AAEL;;AAEA;KACK,eAAe;AACpB;;AAEA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,sBAAsB;IAC1B;CACH;;AAED;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,0BAA0B;IAC9B;IACA;QACI,iBAAiB;QACjB,UAAU;SACT;;IAEL;AACJ","sourcesContent":[".formContent {\n    z-index: 1;\n}\n\n.addForm {\n    padding: 0 50px !important;\n}\n.form {\n    width: 50% !important;\n    box-shadow: 0px 20px 45px #f0edf6 !important;\n}\n.formMain {\n    padding: 0 3rem !important;\n}\n\n.passwordInput {\n    position: relative;\n}\n.passwordInput :global(.form-control):invalid {\n    background-position: right calc(0.375em + 1.5rem) center !important;\n  }\n.passwordValidate {\n    background-image: none !important;\n}\n\n.passwordInput svg {\n    bottom: 13px;\n    position: absolute;\n    right: 10px;\n    width: 16px;\n}\n\n.passwordField {\n    position: absolute;\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n}\n.logoemail{\n    position:relative;\n    left:17rem;\n     margin-bottom:-4.8rem\n\n}\n\n.Checkbox label {\n     font-size: 13px;\n}\n\n@media screen and (max-width: 1024px) {\n    .addForm {\n        padding: 0 10px !important;\n    }\n    .form {\n        width: 100% !important;\n    }\n }\n\n@media screen and (max-width: 768px) {\n    .addForm {\n        padding: 0 10px !important;\n    }\n    .form {\n        width: 100% !important;\n    }\n    .formMain {\n        padding: 0 1rem !important;\n    }\n    .logoemail{\n        position:relative;\n        left:16rem;\n         margin-bottom:-4.8rem\n    \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContent": `ResetPassword_formContent__-laai`,
	"addForm": `ResetPassword_addForm__EQ3Kw`,
	"form": `ResetPassword_form__6ehus`,
	"formMain": `ResetPassword_formMain__fII7W`,
	"passwordInput": `ResetPassword_passwordInput__QGoD9`,
	"passwordValidate": `ResetPassword_passwordValidate__-b8bo`,
	"passwordField": `ResetPassword_passwordField__Q0YUq`,
	"logoemail": `ResetPassword_logoemail__6a87S`,
	"Checkbox": `ResetPassword_Checkbox__Apf1l`
};
export default ___CSS_LOADER_EXPORT___;
