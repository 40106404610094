import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import NoResult from "../NoResult";
import axios from "axios";

import Csv from "../csv/Csv";

import CustomizeTableview from "../TableViews/CustomizeTableview";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import PaginationBar from "../PaginationBar";
import ItemBatchesModal from "../ItemBatches/ItemBatchesModal";
import BarcodeModal from "../Barcode/BarcodeModal";
import LoaderPage from "../loaders/loaderPage";

const DELAY_TIME = 100;

function ArchiveList({ isActive, search, category, accessoryType, setSettingsModal, location, submitted, condition, columnNotToShow, sizeOfPages, setArchiveCount, recallAllQueries, unarchiveActionToggle }) {
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  const [noOfPages, setNoOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [refresh, toggle] = useState(false);
  const [itemTitle, setItemTitle] = useState();
  const [categoryId, setCategoryId] = useState();
  const navigate = useNavigate();

  // const [orderBy, setOrderBy] = useState({ "items.created_at": "DESC" });
  const [orderBy, setOrderBy] = useState({ "items.stock_no": "DESC" });

  const shopName = user.shop_name;
  const shopId = user.shop_id;

  // ItemBachesStates
  const [isItemBachesModalShow, setIsItemBachesModalShow] = useState(false);
  const [itemIdForBatch, setItemIdForBatch] = useState(-1);
  const closeItemBatchesModalHandle = () => {
    setIsItemBachesModalShow(false);
    setItemIdForBatch(-1);
  };

  // BarcodeStates
  const [isBarcodeModalShow, setIsBarcodeModalShow] = useState(false);
  const [item, setItem] = useState(null);
  const closeBarcodeModal = () => {
    setIsBarcodeModalShow(false);
    setItem(null);
  };

  const inventoryBatchesHandle = async (id, data) => {
    setItemIdForBatch(id);
    setItemTitle(data["title"]);
    setCategoryId(data["category_id"]);
    setIsItemBachesModalShow(true);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  function requestAllHistory() {
    let searchobj = {};
    if (category && Object.keys(category).length > 0) {
      searchobj = {
        ...searchobj,
        category_name: category?.value ? category?.label : null,
      };
    }
    if (accessoryType && Object.keys(accessoryType).length > 0) {
      searchobj = {
        ...searchobj,
        accessory_type: accessoryType?.value ? accessoryType?.label : null,
      };
    }

    if (location && Object.keys(location).length > 0) {
      searchobj = {
        ...searchobj,
        location_nick: location?.value ? location?.label : null,
      };
    }

    if (condition && Object.keys(condition).length > 0) {
      searchobj = {
        ...searchobj,
        condition_item: condition?.value ? condition?.label : null,
      };
    }

    if (Object.keys(search).length > 0) {
      searchobj = { ...searchobj, search: search };
    }

    searchobj = { ...searchobj, orderBy };

    return axios.post(process.env.REACT_APP_API_URL + `/inventory/get-archive/${user.user_id}`, searchobj, { withCredentials: true });
  }
  useEffect(() => {
    if (user?.tour && !user?.tour?.Inventory) {
      setIsLoading(false);
      setCount(1);
      setArchiveCount(1);
      setNoOfPages(1);
      setItems([
        {
          id: 1737,
          category_id: 2,
          img0: "images/dummyImages/mobiles.jpg",
          title: "Achive Test",
          stock_no: "00000",
          condition_item: "New",
          type: "Mobile",
          sale_price: 50000,
          is_miscellaneous: 0,
          location: "Main Location",
          in_stock: 10,
        },
      ]);
    } else {
      let searchobj = {};

      if (category && Object.keys(category).length > 0) {
        searchobj = {
          ...searchobj,
          category_name: category?.value ? category?.label : null,
        };
      }
      if (accessoryType && Object.keys(accessoryType).length > 0) {
        searchobj = {
          ...searchobj,
          accessory_type: accessoryType?.value ? accessoryType?.label : null,
        };
      }
      if (location && Object.keys(location).length > 0) {
        searchobj = {
          ...searchobj,
          location_nick: location?.value ? location?.label : null,
        };
      }

      if (condition && Object.keys(condition).length > 0) {
        searchobj = {
          ...searchobj,
          condition_item: condition?.value ? condition?.label : null,
        };
      }
      if (Object.keys(search).length > 0) {
        searchobj = { ...searchobj, search: search };
      }
      searchobj = { ...searchobj, orderBy };
      setIsLoading(true);
      const pageTimeout = setTimeout(async () => {
        try {
          const res = await axios.post(process.env.REACT_APP_API_URL + `/inventory/get-archive/${user.user_id}?page=${currentPage}&size=${sizeOfPages}`, searchobj, { withCredentials: true });
          const data = res.data;

          setCount(data?.count?.no_of_items);
          setArchiveCount(data?.count?.no_of_items);
          setItems(data?.data);
          setNoOfPages(data?.count?.no_of_pages);
          if (data?.count?.no_of_pages !== 0) {
            if (currentPage === 0) {
              navigate("/inventory?page=1");
              setCurrentPage(1);
            }
          }
          if (currentPage > data?.count?.no_of_pages) {
            setCurrentPage(data?.count?.no_of_pages);
            navigate("/inventory?page=" + data?.count?.no_of_pages);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          throw new Error(error.response.data);
        }
      }, DELAY_TIME);

      return () => {
        clearTimeout(pageTimeout);
      };
    }
  }, [search, category, location, submitted, condition, sizeOfPages, currentPage, refresh, orderBy, recallAllQueries, isActive, accessoryType, user.tour.Inventory]);

  const archiveEditHandle = (id, isMiscellaneous) => {
    const temp = items.filter((obj) => obj.id === id);
    navigate("/inventory/edit/" + id, {
      state: { id: temp[0].category_id, isMiscellaneous: isMiscellaneous },
    });
  };
  const deleteInventory = async (id) => {
    try {
      const res = await axios.post(process.env.REACT_APP_API_URL + `/inventory/delete/${id}`, {}, { withCredentials: true });
      if (res) {
        toggle((prev) => !prev);
      }
    } catch (error) {
      throw new Error(error.response.data);
    }
  };
  const archiveDuplicateHandle = (id, is_miscellaneous) => {
    const temp = items.filter((obj) => obj.id === id);
    navigate("/inventory/duplicate/" + id, {
      state: { id: temp[0].category_id, is_miscellaneous: is_miscellaneous },
    });
  };
  const unarchiveInventory = async (id) => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/inventory/unarchive/${id}`, { withCredentials: true });
      if (res) {
        toggle((prev) => !prev);
        unarchiveActionToggle();
      }
    } catch (error) {
      throw new Error(error.response.data);
    }
  };
  const settingsModal = () => {
    setSettingsModal(true);
  };
  const barcodeHandle = (item) => {
    setItem(item);
    setIsBarcodeModalShow(true);
  };
  return (
    <div id="archived-inventory-table">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <LoaderPage />
        </div>
      ) : items?.length === 0 ? (
        <NoResult name="Inventory" />
      ) : (
        <>
          <CustomizeTableview
            settingsModal={settingsModal}
            batches={!columnNotToShow.includes("batches") ? inventoryBatchesHandle : ""}
            barcode={!columnNotToShow.includes("barcode") ? barcodeHandle : ""}
            thumbnail={{
              title: "Title",
              img: "img0",
              description: "title",
            }}
            data={items}
            columnNotShow={["id", "category_id", "is_miscellaneous", "img0", "title"].concat(columnNotToShow)}
            inventoryActions={{
              remove: deleteInventory,
              duplicate: archiveDuplicateHandle,
              unarchive: unarchiveInventory,
              edit: archiveEditHandle,
            }}
            dateColumn={columnNotToShow.includes("updated_at") ? (columnNotToShow.includes("created_at") ? "" : ["created_at"]) : columnNotToShow.includes("created_at") ? ["updated_at"] : ["created_at", "updated_at"]}
            status={
              !columnNotToShow.includes("status")
                ? {
                    column: "in_stock",
                    style: (value) => {
                      if (value > 0) {
                        return "text-success";
                      } else {
                        return "text-danger fst-italic";
                      }
                    },
                    format: (value) => {
                      if (value > 0) {
                        return "Available";
                      } else {
                        return "Sold Out *";
                      }
                    },
                  }
                : ""
            }
            sortColumn={["title", "created_at", "price", "sale_price", "updated_at", "in_stock"]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />

          <Row className="mt-5 align-items-center">
            <Col className="d-none d-sm-block" sm="3" md="3" lg="3">
              <span className="text-secondary fs-6">{count} result(s)</span>
            </Col>
            <Col xs="12" sm="6" md="6" lg="6">
              <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
            </Col>
            <Col className="d-sm-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} result(s)</span>
            </Col>
            <Col xs="6" sm="3" md="3" lg="3" className="text-end pe-2 px-0">
              <Csv
                filename={`${
                  "Archive " +
                  shopName +
                  " " +
                  new Date().toLocaleDateString("en-US", {
                    timeZone: "Asia/Karachi",
                  })
                }`}
                api={requestAllHistory}
                format={(data) => {
                  const { id, img0, category_id, ...temp } = data;
                  return temp;
                }}
                error={(err) => {
                  if (err.response) {
                    const tempErr = err.response.data.error;
                    if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                }}
                responseObject={(data) => {
                  return data?.data?.data;
                }}
              />
            </Col>
          </Row>
          <ItemBatchesModal show={isItemBachesModalShow} close={closeItemBatchesModalHandle} id={itemIdForBatch} categoryId={categoryId} itemTitle={itemTitle} />
          <BarcodeModal show={isBarcodeModalShow} close={closeBarcodeModal} item={item} />
        </>
      )}
    </div>
  );
}

export default ArchiveList;
