import React, { useEffect } from "react";
import { driver } from "driver.js";
import { useSelector, useDispatch } from "react-redux";
import { updateTourGuide } from "../Features/authSlice";

const TradeInTour = ({ setAddModal, refresh }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const tradeInTour = driver({
    showProgress: false,
    allowKeyboardControl: true,
    allowClose: false,
    onCloseClick: () => {
      dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "TradeIn", value: true } }));
      setTimeout(() => {
        refresh();
        tradeInTour.destroy();
      }, 100);
    },
    onPopoverRender: (popover, { config, state }) => {
      const skipButton = document.createElement("button");
      skipButton.innerText = "Skip";
      skipButton.className = "driver-popover-close-btn";
      popover.footerButtons.prepend(skipButton);
    },
    steps: [
      {
        element: "#tooltip-anchor-Trade-in",
        popover: {
          title: "Trade-in",
          description: "Overall shop Trade-ins.",
          side: "right",
          align: "center",
        },
      },
      {
        element: "#trade-in",
        popover: {
          title: `A guide to Trade-in customers`,
          description: "Shows and processes trade-in customers.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-search",
        popover: {
          title: `Search trade-in`,
          description: "seller can search any trade-in customer by his name,email or phone number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-date-filter",
        popover: {
          title: `Days filter`,
          description: "Here seller can choose that which day’s trade-in customer he wants to see.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-page-size",
        popover: {
          title: `Page size`,
          description: "Seller can choose that how many trade-in customers he wants to see at a time.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-customer",
        popover: {
          title: `Add Trade-in Customer`,
          description: "Upon clicking on this button, seller can add new trade-in customer.",
          side: "right",
          align: "start",
          onNextClick: () => {
            setAddModal(true);
            setTimeout(() => {
              tradeInTour.moveNext();
            });
          },
        },
      },
      {
        element: "#trade-in-add-customer-modal > .modal-content",
        popover: {
          title: `Add Trade-in customer`,
          description: "A form to add a new trade-in customer.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setAddModal(false);
            setTimeout(() => {
              tradeInTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#trade-in-add-customer-name",
        popover: {
          title: `Customer Full Name`,
          description: "Enter customer full name.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-customer-contact",
        popover: {
          title: `Customer Contact`,
          description: "Enter customer contact number.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-customer-email",
        popover: {
          title: `Customer Email`,
          description: "Enter customer email.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-customer-cnic",
        popover: {
          title: `Customer CNIC`,
          description: "Enter customer CNIC.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-customer-cnic-front",
        popover: {
          title: `CNIC Front`,
          description: "Upload customer CNIC front.",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-customer-cnic-back",
        popover: {
          title: `CNIC Back`,
          description: "Upload customer CNIC back.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#trade-in-add-customer-button",
        popover: {
          title: `Save`,
          description: "On clicking save button added information of trade-in customer will be saved.",
          side: "left",
          align: "start",
          onNextClick: () => {
            setAddModal(false);
            tradeInTour.moveNext();
          },
        },
      },
      {
        element: "#trade-in-reset-filter",
        popover: {
          title: `Reset Filters`,
          description: "Clicking on this button will remove all applied filters on the search result.",
          side: "right",
          align: "start",
          onPrevClick: () => {
            setAddModal(false);
            setTimeout(() => {
              tradeInTour.movePrevious();
            });
          },
        },
      },
      {
        element: "#trade-in-customer-table > div:nth-child(1)",
        popover: {
          title: `Trade-in Customers List`,
          description: "On this page, all added customers will be shown with their information.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#edit",
        popover: {
          title: `Edit`,
          description: "Upon clicking on this button, seller can edit any trade-in customer.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#remove",
        popover: {
          title: `Delete`,
          description: "On clicking this button seller can delete that customer.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#view",
        popover: {
          title: `View`,
          description: "On clicking this, customer sales modal will appear where seller can view trade-in products added against that specific customer.",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#csv-download",
        popover: {
          title: `Download CSV`,
          description: "On clicking this, seller can download customer record in csv file.",
          side: "right",
          align: "start",
          onNextClick: () => {
            dispatch(updateTourGuide({ user_id: user.user_id, tour_guide: { key: "TradeIn", value: true } }));
            setTimeout(() => {
              refresh();
              tradeInTour.destroy();
            }, 100);
          },
        },
      },
    ],
  });
  useEffect(() => {
    tradeInTour.drive();
    const clickStop = (e) => {
      if (e.target.className !== "driver-popover-next-btn" && e.target.className !== "driver-popover-prev-btn" && e.target.className !== "driver-popover-close-btn") e.stopPropagation();
    };
    window.addEventListener("click", clickStop, true);
    return () => {
      window.removeEventListener("click", clickStop, true);
    };
  }, []);
  return <></>;
};

export default TradeInTour;
