// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchInput_searchInput__WhD5t {
  /* border-radius: 50px !important; */
  padding: 0.375rem 10px 0.375rem 2.5rem !important;
  font-family: sans-serif;
}
.SearchInput_searchIconTradeIn__8IqVM {
  position: absolute;
  top: 34px;
  left: 20px;
}
.SearchInput_searchIcon__6sjBI {
  position: absolute;
  top: 7px;
  left: 3px;
}
.SearchInput_search__VdYgM {
  padding-left: 2rem !important;
}
.SearchInput_iconBorder__-H2Ju {
  padding: 6px;
  border: 1px solid;
  border-radius: 6px;
}
.SearchInput_searchIcon_Mobile__-DyGu {
  color: #9537ff;
  position: absolute;
  top: -20px !important;
  padding-bottom: 0.4rem !important;
}

@media screen and (max-width: 900px) {
  .SearchInput_searchIcon__6sjBI {
    color: #9537ff;
    position: absolute;
    left: 1;
  }
  .SearchInput_searchInput__WhD5t {
    border-radius: 50px !important;
    padding: 0.375rem 2.5rem !important;
  }
  .SearchInput_coloredInput__RmUrh {
    border: 1px solid #9537ff !important;
  }
  .SearchInput_coloredInput__RmUrh::placeholder {
    color: #9537ff !important;
  }
}

@media screen and (max-width: 575px) {
  .SearchInput_searchIcon_Mobile__-DyGu {
    width: 44px;
    height: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/SearchInput.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,iDAAiD;EACjD,uBAAuB;AACzB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,iCAAiC;AACnC;;AAEA;EACE;IACE,cAAc;IACd,kBAAkB;IAClB,OAAO;EACT;EACA;IACE,8BAA8B;IAC9B,mCAAmC;EACrC;EACA;IACE,oCAAoC;EACtC;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".searchInput {\n  /* border-radius: 50px !important; */\n  padding: 0.375rem 10px 0.375rem 2.5rem !important;\n  font-family: sans-serif;\n}\n.searchIconTradeIn {\n  position: absolute;\n  top: 34px;\n  left: 20px;\n}\n.searchIcon {\n  position: absolute;\n  top: 7px;\n  left: 3px;\n}\n.search {\n  padding-left: 2rem !important;\n}\n.iconBorder {\n  padding: 6px;\n  border: 1px solid;\n  border-radius: 6px;\n}\n.searchIcon_Mobile {\n  color: #9537ff;\n  position: absolute;\n  top: -20px !important;\n  padding-bottom: 0.4rem !important;\n}\n\n@media screen and (max-width: 900px) {\n  .searchIcon {\n    color: #9537ff;\n    position: absolute;\n    left: 1;\n  }\n  .searchInput {\n    border-radius: 50px !important;\n    padding: 0.375rem 2.5rem !important;\n  }\n  .coloredInput {\n    border: 1px solid #9537ff !important;\n  }\n  .coloredInput::placeholder {\n    color: #9537ff !important;\n  }\n}\n\n@media screen and (max-width: 575px) {\n  .searchIcon_Mobile {\n    width: 44px;\n    height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": `SearchInput_searchInput__WhD5t`,
	"searchIconTradeIn": `SearchInput_searchIconTradeIn__8IqVM`,
	"searchIcon": `SearchInput_searchIcon__6sjBI`,
	"search": `SearchInput_search__VdYgM`,
	"iconBorder": `SearchInput_iconBorder__-H2Ju`,
	"searchIcon_Mobile": `SearchInput_searchIcon_Mobile__-DyGu`,
	"coloredInput": `SearchInput_coloredInput__RmUrh`
};
export default ___CSS_LOADER_EXPORT___;
