import axios from "axios";
import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import styles from "../../styles/PlansCard.module.css";
import { useEffect, useState } from "react";
import PopUpModal from "../Modal";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeCircleCheck, faMessage, faStore } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import rocket from "../../assets/images/rocket.png";
import running from "../../assets/images/running-man.png";
import crown from "../../assets/images/crown.png";
import trophy from "../../assets/images/trophy.png";
import RequestModal from "./RequestModal";
import MobilePansCard from "./MobilePansCard";
import CustomPlan from "./CustomPlan";
import PlansTour from "../../TourGuides/PlansTour";
const PlansCards = () => {
  const [plansData, setPlansData] = useState([]);
  const [frequency, setFrequency] = useState("monthly");
  const [planType, setPlanType] = useState("featured");
  const [modalShow, setModalShow] = useState(false);
  const [customModal, setCustomModal] = useState(false);
  const [planId, setPlanId] = useState();
  const [changePlan, setChangePlan] = useState("");
  const [check, setCheck] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const [selffPlanAmount, setSelfPlanAmount] = useState(0);
  const loc = useLocation();
  const shop_id = user.shop_id;
  const sectionsRef = useRef(null);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/subscriptions/get/plans`)
      .then((res) => {
        setPlansData(
          res.data.filter((plan) => {
            if (plan.plan_type === "fixed") {
              return plan;
            }
          })
        );
        res.data.map((plan) => {
          if (plan.id === user.plan_id) {
            setSelfPlanAmount(plan.monthly_total);
          }
        });
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  }, []);
  const handleFrequency = (e) => {
    setCheck(false);
    setFrequency(e.target.value);
    setTimeout(() => {
      setCheck(true);
    }, 200);
  };

  const onclickToScroll = () => {
    sectionsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const moduleNames = Array.from(new Set(plansData.flatMap((plan) => plan.modules.map((module) => module.name))));
  return (
    <div id="plans">
      {user?.tour && !user?.tour.Plans && <PlansTour />}
      <Row>
        <h3 className="text-center  ">Subscription Plans</h3>
        <strong className="text-center mt-2 mb-1">Choose from a variety of plans with flexibility of quarterly , half year and yearly payments or request for a custom plans </strong>
        <div className="d-flex gap-2 mt-2 justify-content-center ">
          <button
            id="subscriptions-plan-featured"
            name="plans"
            className={`btn btn-outline-secondary ${planType === "featured" ? "bg-secondary text-white" : ""}  rounded-pill `}
            aria-label="radio"
            value="featured"
            onClick={() => {
              setPlanType("featured");
            }}
          >
            Featured
          </button>
          <button
            id="subscriptions-plan-custom"
            className={`btn btn-outline-secondary rounded-pill ${planType === "custom" ? "bg-secondary text-white" : ""} `}
            onClick={() => {
              setPlanId(3);
              setChangePlan("");
              setPlanType("custom");
            }}
          >
            Custom
          </button>
        </div>
      </Row>

      {planType === "featured" ? (
        <Row className=" mt-5 m-0 w-100  justify-content-center  " id="subscriptions-featured-plan-details">
          <Col className="p-0" style={{ overflowX: "auto" }} xl="12" lg="12" md="12" sm="12">
            <div className="d-lg-flex d-md-none d-sm-none d-none ">
              <table ref={sectionsRef} className={`table mb-3 ${styles.termsTable}`}>
                <thead>
                  <tr style={{ backgroundColor: "#F5F6FA !important" }} className="">
                    <th style={{ width: "30%" }} scope="col ">
                      <div className={` inactiveColor  `}>
                        <div className="d-flex flex-column">
                          <div className="mb-2">
                            <p className={`text-uppercase  text-center m-0   text-black-50  ${styles.freqP} `}>Choose Payment Frequency</p>
                          </div>
                          <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className={`btn-group btn-group-sm border border-primary rounded-pill border-1 p-2 ${styles.pill} `} role="group" aria-label="Basic radio toggle button group">
                              <button name="plans" onClick={handleFrequency} value="monthly" className={`btn btn-outline-primary  fs-14 rounded-pill ${frequency === "monthly" && styles.activeBtn}`}>
                                3 Months
                              </button>

                              <button name="plans" onClick={handleFrequency} className={`btn btn-outline-primary fs-14 rounded-pill mx-1 ${frequency === "quarterly" && styles.activeBtn} `} aria-label="radio Quarterly" value="quarterly" checked={frequency === "quarterly"}>
                                6 Months
                              </button>

                              <button name="plans" onClick={handleFrequency} className={`btn btn-outline-primary fs-14 rounded-pill ${frequency === "yearly" && styles.activeBtn}`} aria-label="radio yearly" value="yearly" checked={frequency === "yearly"}>
                                12 Months
                              </button>
                            </div>
                          </div>
                          <div className="col-12 text-muted fw-normal form-text fs-14 ">
                            <p className={`text-center m-0 `}>Save 10% on 6 Months</p>
                            <p className={`text-center m-0 `}>Save 20% on 12 Months</p>
                          </div>
                        </div>
                      </div>
                    </th>
                    {plansData?.map((i) => (
                      <th scope="col" className={` ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? styles.currentPlan : ""} text-center `}>
                        <>
                          {i.plan_name === "Basic" || i.plan_name === "Starter" ? (
                            <img src={rocket} height="40" width="40" alt="rocket" />
                          ) : i.plan_name === "Standard" || i.plan_name === "Plus" ? (
                            <img src={running} height="40" width="40" alt="rocket" />
                          ) : i.plan_name === "Pro" || i.plan_name === "Power" ? (
                            <img src={crown} height="40" width="40" alt="rocket" />
                          ) : i.plan_name === "Business" || i.plan_name === "Enterprise" ? (
                            <img src={trophy} height="40" width="40" alt="rocket" />
                          ) : (
                            ""
                          )}

                          <h6 className={` mt-1   ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? "inactiveColor" : "inactiveColor"} ${styles.subsh2}     `}>{i.plan_name.toUpperCase()}</h6>
                          {
                            <div className={`${styles.subsdiv} ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? "activeColor" : ""} `}>
                              <div className="d-flex flex-column text-center">
                                {frequency === "monthly" ? (
                                  ""
                                ) : frequency === "quarterly" ? (
                                  <span
                                    style={{
                                      textDecoration: "line-through",
                                      fontSize: "16px",
                                    }}
                                    className=" "
                                  >
                                    {i.monthly_total * 6}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      textDecoration: "line-through",
                                      fontSize: "16px",
                                    }}
                                    className=" "
                                  >
                                    {i.monthly_total * 12}
                                  </span>
                                )}
                                <span className="d-grid ">
                                  <div className="mb-2">
                                    <span className={`${styles.subsspan1}`}>Rs.</span>
                                    <span className={`${styles.subsspan2}`}> {frequency === "monthly" ? i.monthly_total * 3 : frequency === "quarterly" ? i.monthly_total * 6 * 0.9 : frequency === "yearly" ? i.monthly_total * 12 * 0.8 : ""}</span>
                                    {/* <span className={`${styles.subsspan3}`}>/{frequency}</span> */}
                                  </div>
                                  <span className={`text-center fw-light form-text d-lg-none d-xl-block  mb-2`}>
                                    {i.plan_name === "Basic" || i.plan_name === "Starter"
                                      ? "New to the platform? Let's get you started"
                                      : i.plan_name === "Standard" || i.plan_name === "Plus"
                                      ? "Explore more with amazing features"
                                      : i.plan_name === "Pro" || i.plan_name === "Power"
                                      ? "Power usage with all the features at your disposal"
                                      : i.plan_name === "Business" || i.plan_name === "Enterprise"
                                      ? "Unlimited Resources with Dedicated Support"
                                      : ""}
                                  </span>
                                  <div>
                                    {user.on_payment === 1 ? (
                                      selffPlanAmount < i.monthly_total ? (
                                        <button
                                          className={`btn   btn-outline-primary `}
                                          variant="outline-primary"
                                          onClick={() => {
                                            setModalShow(true);
                                            setPlanId(i.id);
                                            setChangePlan("Upgrade");
                                          }}
                                        >
                                          {user.plan_name.includes("customPlan") ? "Subscribe" : "Upgrade"}
                                        </button>
                                      ) : selffPlanAmount > i.monthly_total ? (
                                        <button
                                          className={`btn   btn-outline-primary  ${styles.downgradeBtn}`}
                                          variant="outline-primary"
                                          onClick={() => {
                                            setModalShow(true);
                                            setPlanId(i.id);
                                            setChangePlan("Downgrade");
                                          }}
                                        >
                                          {user.plan_name.includes("customPlan") ? "Subscribe" : "Downgrade"}
                                        </button>
                                      ) : i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? (
                                        <>
                                          <button style={{ pointerEvents: "none" }} className={`btn   btn-outline-success `} variant="outline-primary">
                                            Current
                                          </button>
                                        </>
                                      ) : (
                                        <button
                                          className={`btn   btn-outline-primary `}
                                          variant="outline-primary"
                                          onClick={() => {
                                            setModalShow(true);
                                            setPlanId(i.id);
                                            setChangePlan("Change_Frequency");
                                          }}
                                        >
                                          {user.plan_name.includes("customPlan") ? "Subscribe" : "Change"}
                                        </button>
                                      )
                                    ) : (
                                      user.on_trial === 1 && (
                                        <button
                                          className={`btn   btn-outline-primary  `}
                                          variant="outline-primary"
                                          onClick={() => {
                                            setModalShow(true);
                                            setPlanId(i.id);
                                            setChangePlan("");
                                          }}
                                        >
                                          Buy Plan
                                        </button>
                                      )
                                    )}
                                  </div>
                                </span>
                              </div>
                            </div>
                          }
                        </>
                      </th>
                    ))}
                    {/* <th className={`  inactiveColor ${styles.subsh2}  font700   `}>
                    <h2 className={`  "inactiveColor ${styles.subsh2}  font700 d-flex justify-content-center   `}>Custom</h2>
                  </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr className="align-middle ">
                    <td className={`   ${styles.subsh2}  font700  strippedColumn `}>Consumable Items</td>
                    {plansData?.map((i) => (
                      <td className={`text-center strippedColumn ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? styles.currentPlan : ""} `}>
                        <h2 className={`   ${styles.subsh2}  font700   `}>{i.plan_name}</h2>
                      </td>
                    ))}
                  </tr>
                  {plansData.length > 0
                    ? Object.keys(plansData && plansData[0]).map((property, rowIndex) => (
                        <tr key={rowIndex}>
                          {property !== "id" &&
                            property !== "plan_name" &&
                            property !== "plan_type" &&
                            property !== "plan_amount" &&
                            property !== "monthly_total" &&
                            property !== "quarterly_total" &&
                            property !== "yearly_total" &&
                            property !== "modules" &&
                            property !== "themes" &&
                            property !== "is_active" && (
                              <td className={`  ${rowIndex % 2 === 0 ? "" : "bg-white"} h-100 d-flex  align-items-center  `} style={{ color: "#2f1c6a", minWidth: "180px" }}>
                                {property === "secondary_users_limit" ? "No of Employees" : property?.replaceAll("_", " ").charAt(0).toUpperCase() + property?.replaceAll("_", " ").slice(1)}
                              </td>
                            )}

                          {plansData?.map((plan, colIndex) => (
                            <>
                              {property !== "id" &&
                                property !== "plan_name" &&
                                property !== "plan_type" &&
                                property !== "plan_amount" &&
                                property !== "monthly_total" &&
                                property !== "quarterly_total" &&
                                property !== "yearly_total" &&
                                property !== "modules" &&
                                property !== "themes" &&
                                property !== "is_active" && (
                                  <td style={{ color: "#2f1c6a" }} className={`  text-center h-100  ${plan.id === user.plan_id && frequency === user.current_plan_billing_frequency ? styles.currentPlan : ""} border-top-0 `} key={colIndex}>
                                    {plan[property]}
                                  </td>
                                )}
                            </>
                          ))}
                          {/* {property !== "id" && property !== "plan_name" && property !== "plan_type" && property !== "plan_amount" && property !== "monthly_total" && property !== "quarterly_total" && property !== "yearly_total" && property !== "modules" && property !== "themes" &&property !== "is_active" && (
                          <td style={{ fontSize: "18px", color: "#2f1c6a", minWidth: "180px" }} className={`${styles.subsdiv} h-100 d-flex ${rowIndex % 2 === 0 ? "" : "bg-white"} font700  justify-content-center align-items-center`}>
                            --
                          </td>
                        )} */}
                        </tr>
                      ))
                    : ""}

                  <tr className="bg-secondary ">
                    <td className={`   ${styles.subsh2} align-middle font700 strippedColumn  `}>Features</td>
                    {plansData?.map((i) => (
                      <td className={`text-center strippedColumn border-top-0  ${i.id === user.plan_id && frequency === user.current_plan_billing_frequency ? styles.currentPlan : ""}`}>
                        <h2 className={`   ${styles.subsh2}  font700 align-middle    `}>{i.plan_name}</h2>
                      </td>
                    ))}
                  </tr>
                  {moduleNames.map((moduleName, rowIndex) => (
                    <tr key={moduleName}>
                      <td className={`  ${rowIndex % 2 === 0 ? "" : "bg-white"} h-100 d-flex  align-items-center  `} style={{ color: "#2972DD", minWidth: "180px" }}>
                        {moduleName.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")}
                      </td>
                      {plansData.map((plan) => {
                        const module = plan.modules.find((module) => module.name === moduleName);
                        return (
                          <td className={`${rowIndex % 2 === 0 ? "" : "bg-white"} text-center ${plan.id === user.plan_id && frequency === user.current_plan_billing_frequency ? styles.currentPlan : ""} border-top-0 `} key={plan.id + moduleName}>
                            {module ? module.isIncluded ? <FontAwesomeIcon style={{ color: "#36AF8D", fontSize: "18px" }} icon={faCheck} /> : <FontAwesomeIcon style={{ color: "#F35185", fontSize: "18px" }} icon={faXmark} /> : "No"}
                          </td>
                        );
                      })}
                      {/* <td className={`${styles.subsdiv} h-100 d-flex ${rowIndex % 2 === 0 ? "" : "bg-white"} font700  justify-content-center align-items-center`}>--</td> */}
                    </tr>
                  ))}

                  <tr>
                    <td style={{ color: "#C5D852" }} className={`   ${styles.subsh2} align-middle text-start  font700   `}>
                      Themes
                    </td>
                    {plansData.map((plan) => {
                      return (
                        <td style={{ color: "#2f1c6a", maxWidth: "170px" }} className={`  text-center ${plan.id === user.plan_id && frequency === user.current_plan_billing_frequency ? styles.currentPlan : ""} border-top-0 `}>
                          {plan.themes.length > 0 ? plan.themes.map((theme) => theme.theme_name).join(", ") : "No Themes"}
                        </td>
                      );
                    })}
                  </tr>

                  {/* ================================== */}
                </tbody>
              </table>
            </div>
          </Col>

          <div className="d-lg-none d-md-block">
            <MobilePansCard setCustomModal={setCustomModal} plansData={plansData} setModalShow={setModalShow} setPlanId={setPlanId} setChangePlan={setChangePlan} />
          </div>
        </Row>
      ) : (
        <CustomPlan shop_id={shop_id} />
      )}
      <PopUpModal
        title="Request"
        size="lg"
        show={modalShow}
        onHide={() => {
          setCustomModal(false);
          setModalShow(false);
        }}
      >
        <RequestModal
          nextPlanDate={loc.state}
          customModal={customModal}
          setCustomModal={setCustomModal}
          shop_id={shop_id}
          request_status="pending"
          priority="high"
          request_type={user.on_trial === 1 ? "paid-plan-request" : "change-plan-request"}
          frequency={frequency[0].toUpperCase() + frequency.substring(1)}
          title={user.on_payment === 1 ? `[${frequency[0].toUpperCase() + frequency.substring(1)}]-${changePlan}_Plan_Request` : `[${frequency[0].toUpperCase() + frequency.substring(1)}]-Paid_Plan_Request`}
          describe="Please Describe your request"
          placeholder="Provide details regarding your plan change request."
          planId={planId}
          close={() => setModalShow(false)}
        />
      </PopUpModal>
    </div>
  );
};

export default PlansCards;
