// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CartInputPart_salesPersonDropdown__Yy-Y6 .react-select-container__menu {
  height: 110px;
}
.CartInputPart_salesPersonDropdown__Yy-Y6 .react-select-container__menu-list {
  height: 110px;
}

.CartInputPart_salesPersonDropdown__Yy-Y6 .css-12pm38j-Control{
  font-size: 12px !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/SalesPage/Cart/CartInputPart.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[":local(.salesPersonDropdown) :global(.react-select-container__menu) {\n  height: 110px;\n}\n:local(.salesPersonDropdown) :global(.react-select-container__menu-list) {\n  height: 110px;\n}\n\n:local(.salesPersonDropdown) :global(.css-12pm38j-Control){\n  font-size: 12px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"salesPersonDropdown": `CartInputPart_salesPersonDropdown__Yy-Y6`
};
export default ___CSS_LOADER_EXPORT___;
