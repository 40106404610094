// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageInput_imageInput__9LXc8 {
    display: block;
    width: 100%;
    border: 1px dashed #DEDFE4;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 5px 0px;
    padding-left: 15px;
}

.ImageInput_imageInput__9LXc8 .ImageInput_img__WSSB8 {
    display: flex;
    justify-content: center;
    padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/styles/ImageInput.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,0BAA0B;IAC1B,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,aAAa;AACjB","sourcesContent":[".imageInput {\n    display: block;\n    width: 100%;\n    border: 1px dashed #DEDFE4;\n    box-sizing: border-box;\n    border-radius: 6px;\n    margin: 5px 0px;\n    padding-left: 15px;\n}\n\n.imageInput .img {\n    display: flex;\n    justify-content: center;\n    padding: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageInput": `ImageInput_imageInput__9LXc8`,
	"img": `ImageInput_img__WSSB8`
};
export default ___CSS_LOADER_EXPORT___;
