import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import PaginationBar from "../../Components/PaginationBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PopUpModal from "../../Components/Modal";
import filterImg from "../../assets/svg/filter.svg";
import axios from "axios";
import NoResult from "../../Components/NoResult";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment";
import SearchableSelect from "../../Components/SearchableSelect";
import { useSelector } from "react-redux";
import customloader from "../../assets/images/RollingLoader.gif";
import Csv from "../../Components/csv/Csv";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestDateTime, TIMEOUT } from "../../Features/dateTime";
import EditTradeInCustomer from "./EditTradeInCustomer";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomerTradeIn from "./TradeInCustomerView";
import LoaderPage from "../../Components/loaders/loaderPage";
import AddTradeInCustomer from "./AddTradeInCustomer";
import TradeInTour from "../../TourGuides/TradeInTour";

const TradeInCustomers = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);
  const shopId = user.shop_id;
  const shopName = user.shop_name;
  const [modalAdd, setAddModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalSales, setModalSales] = useState(false);
  // customer
  const [customer, setCustomer] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [totalCustomers, setTotalCustomers] = useState();

  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });

  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  // Start Customer with pagination
  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/trade-in?page=" + page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  const filters = {
    search: searchInput,
    dates: {
      from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
      to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
    },
    orderBy,
  };
  const headers = {
    filters: JSON.stringify(filters),
  };
  function getTradeInCustomers() {
    if (user?.tour && !user?.tour.TradeIn) {
      setTotalCustomers(1);
      setCustomer([
        {
          customer_name: "Test",
          customer_email: "test@gmail.com",
          customer_type: "trade_in",
          customer_number: "+92-311-1111111",
          CNIC: "11111-1111111-1",
          created_at: "1970-01-01T18:42:15.000Z",
          updated_at: null,
        },
      ]);
      setNoOfPages(1);
      setIsLoading(false);
    } else {
      axios
        .get(process.env.REACT_APP_API_URL + `/trade-in/customers/${shopId}?page=` + currentPage + `&size=` + sizeOfPages, { headers, withCredentials: true })
        .then((data) => {
          setIsLoading(false);
          setTotalCustomers(data?.data?.pagination?.no_of_items);
          setCustomer(data?.data?.data);

          if (data?.data?.pagination?.no_of_pages === 0) {
            if (searchParams.get("page")) {
              setNoOfPages(0);
              navigate("/trade-in?page=0");
            }
          } else {
            setNoOfPages(data?.data?.pagination?.no_of_pages);
            if (currentPage > data?.data?.pagination?.no_of_pages) {
              setCurrentPage(data?.data?.pagination?.no_of_pages);
              navigate("/trade-in?page=" + data?.data?.pagination?.no_of_pages);
            }
            if (searchParams.get("page") === "0") {
              setCurrentPage(1);
              navigate("/trade-in?page=1");
            }
          }
        })
        .catch((err) => {
          const tempErr = err.response.data.error;

          if (tempErr.status === 404 && tempErr.message === "No Record Found") {
            setCustomer([]);
          }
        });
    }
  }

  useEffect(() => {
    let timeOut;
    if (searchInput?.length > 0) {
      timeOut = setTimeout(() => {
        setIsLoading(true);
        getTradeInCustomers();
      }, TIMEOUT);
    } else {
      setIsLoading(true);
      getTradeInCustomers();
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [currentPage, searchInput, noOfPages, sizeOfPages, shopId, toggle, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [fromDate, toDate, orderBy]);

  function requestAllHistory() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + `/trade-in/customers/${shopId}`, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  const resetFilterHandle = () => {
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    setSizeOfPages(10);
  };

  const closeModalAdd = () => {
    setAddModal(false);
    refresh((prev) => !prev);
  };

  const [customerSalesId, setCustomerSalesId] = useState(-1);
  // View Customer
  const view = async (id) => {
    setModalSales(true);
    setCustomerSalesId(id);
  };
  const closeModalSales = () => {
    setModalSales(false);
    setCustomerId(-1);
    setCustomerSalesId(-1);
    refresh((prev) => !prev);
  };
  // Edit Customer
  const edit = (id) => {
    setModalEdit(true);
    setCustomerId(id);
  };
  const closeModalEdit = () => {
    setModalEdit(false);
    setCustomerId(-1);
    refresh((prev) => !prev);
  };
  // Delete Customer
  const remove = async (id) => {
    try {
      const response = await axios.delete(process.env.REACT_APP_API_URL + "/trade-in/customer/" + id, {
        withCredentials: true,
      });
      if (response) {
        toast.success(`${response.data.message}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
        refresh((prev) => !prev);
      } else {
        toast.error(`${response.data.message}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const searchResult = () => {
    setToogleMobileSearch(false);
    setToogleMobileSearch(false);
  };
  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearchInput("");
  };
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };
  const SearchData = [];
  return (
    <>
      {user?.tour && !user?.tour?.TradeIn && <TradeInTour setAddModal={setAddModal} setModalEdit={setModalEdit} refresh={() => refresh((prev) => !prev)} />}
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} dateName={"Select Date"} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} filter={filter} />
      ) : (
        <Container fluid id="trade-in">
          {toogleMobileSearch === "search" ? (
            <div className="d-flex  mb-2 justify-content-center align-items-center">
              <div className="">
                {" "}
                <SearchMobleInput searchlogo value={searchInput} handleChange={handleSearchChange} />
              </div>
              <span className="ms-4" onClick={handleAcoordianCLose}>
                X
              </span>
            </div>
          ) : (
            <Row className="d-flex align-items-center">
              <Col xs="2" lg="3" className="d-none d-lg-inline" id="trade-in-search">
                <SearchInput searchlogo handleChange={handleSearchChange} value={searchInput} />
              </Col>
              <Col xs lg="2" xl="3" className="d-none d-lg-inline"></Col>
              <Col xs lg="2" className="d-none d-lg-inline" id="trade-in-date-filter">
                <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
              </Col>

              <Col xs="2" lg="3" onClick={() => handleAcoordianOpen("search")} className="d-lg-none d-inline">
                <SearchInput searchlogo bordered />
              </Col>
              <Col className="d-lg-none d-flex" xs="2" lg="2">
                <Button variant="outline-primary" onClick={() => handleAcoordianOpen("select")}>
                  <img src={filterImg} alt="filter" />
                  <span className=" ms-2 d-none d-sm-inline">Filter</span>{" "}
                </Button>
              </Col>
              <Col className="d-none d-sm-inline d-lg-none d-md-inline" sm={2}></Col>
              <Col xs="6" lg="3" xl="2" sm="3" id="trade-in-page-size">
                <SearchableSelect
                  placeholder={`Showing ${sizeOfPages}`}
                  defaultValue={`Showing ${sizeOfPages}`}
                  value={`Showing ${sizeOfPages}`}
                  searchable={false}
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                  ]}
                  handleChange={handlePageSize}
                />
              </Col>
              <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className="p-0">
                <Button className="h-100 w-100" style={{ fontSize: "1rem" }} size="sm" type="submit" onClick={() => setAddModal(true)} id="trade-in-add-customer">
                  <span className="d-none d-sm-inline d-lg-inline">Add Trade-in Customer</span> <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
            </Row>
          )}
          <div className="pt-2  d-lg-inline">
            <Button size="sm" type="submit" style={{ fontSize: "14px", marginTop: "7px" }} onClick={resetFilterHandle} id="trade-in-reset-filter">
              <span className=" d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div
              style={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LoaderPage />
            </div>
          ) : (
            <>
              {customer.length === 0 ? (
                <NoResult onClick={() => setAddModal(true)} name="Trade-in Customer" />
              ) : (
                <div id="trade-in-customer-table">
                  <CustomizeTableview
                    className={"mt-5"}
                    data={customer}
                    edit={edit}
                    remove={remove}
                    view={view}
                    columnNotShow={["id", "shop_id"]}
                    dateColumn={["created_at", "updated_at"]}
                    sortColumn={["customer_name", "created_at", "updated_at"]}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    actions={user.permissionArray?.includes("Customer")}
                  />
                  <Row className="mt-5 align-items-center">
                    <Col className="d-none d-md-block" md="3" lg="3">
                      {totalCustomers > 1 ? <span className="text-secondary fs-6">{totalCustomers} results</span> : <span className="text-secondary fs-6">{totalCustomers} result</span>}
                    </Col>
                    <Col xs="12" md="6" lg="6">
                      <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                    </Col>
                    <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                      {totalCustomers > 1 ? <span className="text-secondary fs-6">{totalCustomers} results</span> : <span className="text-secondary fs-6">{totalCustomers} result</span>}
                    </Col>
                    <Col xs="6" md="3" lg="3" className="text-end ">
                      <Csv
                        filename={`${
                          "Trade-in Customers " +
                          shopName +
                          " " +
                          new Date().toLocaleDateString("en-US", {
                            timeZone: "Asia/Karachi",
                          })
                        }`}
                        api={requestAllHistory}
                        format={(data) => {
                          const { id, created_at, updated_at, customer_number, ...temp } = data;
                          const newObj = {
                            ...temp,
                            customer_number: customer_number.replaceAll("+", "").replaceAll("-", " "),
                            created_at: created_at.split("T")[0],
                            updated_at: updated_at ? updated_at.split("T")[0] : null,
                          };
                          return newObj;
                        }}
                        error={(err) => {
                          const tempErr = err.response.data.error;
                          if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                            return true;
                          } else {
                            return false;
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </>
          )}
        </Container>
      )}

      <PopUpModal title="Add Trade-in Customer" show={modalAdd} onHide={closeModalAdd} id="trade-in-add-customer-modal">
        <AddTradeInCustomer close={closeModalAdd} />
      </PopUpModal>

      <PopUpModal title="Edit Customer" show={modalEdit} onHide={closeModalEdit}>
        <EditTradeInCustomer id={customerId} close={closeModalEdit} />
      </PopUpModal>

      <CustomerTradeIn id={customerSalesId} show={modalSales} close={closeModalSales} />
    </>
  );
};

export default TradeInCustomers;
