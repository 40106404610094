// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardPaymentPart_borderedHr__lsdfG {
  border-top: 2px dashed #dedfe4;
  height: 0 !important;
  background: none !important;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SalesPage/Cart/CardPaymentPart.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,oBAAoB;EACpB,2BAA2B;EAC3B,SAAS;AACX","sourcesContent":[".borderedHr {\n  border-top: 2px dashed #dedfe4;\n  height: 0 !important;\n  background: none !important;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderedHr": `CardPaymentPart_borderedHr__lsdfG`
};
export default ___CSS_LOADER_EXPORT___;
