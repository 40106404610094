// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view_gridContainer__a8DIs:not(.smallGrid) {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, 180px);
  justify-content: center;
}

.view_gridContainer__a8DIs.smallGrid {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SalesPage/Views/view.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,+CAA+C;EAC/C,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,+CAA+C;EAC/C,uBAAuB;AACzB","sourcesContent":[".gridContainer:not(:global(.smallGrid)) {\n  display: grid;\n  gap: 12px;\n  grid-template-columns: repeat(auto-fill, 180px);\n  justify-content: center;\n}\n\n.gridContainer:global(.smallGrid) {\n  display: grid;\n  gap: 12px;\n  grid-template-columns: repeat(auto-fill, 100px);\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridContainer": `view_gridContainer__a8DIs`
};
export default ___CSS_LOADER_EXPORT___;
