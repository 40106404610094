// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chart_canvas__-IE5r {
    height: 200px !important;
}

.Chart_barCanvas__F0mRs {
    height: 450px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/Chart.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".canvas {\n    height: 200px !important;\n}\n\n.barCanvas {\n    height: 450px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas": `Chart_canvas__-IE5r`,
	"barCanvas": `Chart_barCanvas__F0mRs`
};
export default ___CSS_LOADER_EXPORT___;
