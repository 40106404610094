import axios from "axios";
import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddForm from "../../Components/AddForm";
import SearchableSelect from "../../Components/SearchableSelect";
import Loading from "../../Components/Loading/Loading";
import { requestDateTime } from "../../Features/dateTime";
const PurchaseForm = ({ purchaseId, id }) => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const [error, setError] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [isloading, setisLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            `/vendors/get-vendor-by-shop/${shop_id}`,
          { vendorId: id },
          { withCredentials: true },
        )
        .then((res) => {
          setData(res.data);
          setisLoading(false);
        })
        .catch((err) => {
          setData([]);
          setisLoading(false);
        });
    }, 1000);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [shop_id, id]);
  useEffect(() => {
    const pageTimeout = setTimeout(() => {
      if (purchaseId && purchaseId !== -1) {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "/vendors/purchase/get-payments/" +
              purchaseId,
            { shopId: shop_id, vendorId: id },
            { withCredentials: true },
          )
          .then((res) => {
            if (res?.data?.code === 200) {
              setError(false);
              setTotalAmount(res.data.success.data.total_amount);
              setPaidAmount(res.data.success.data.paid_amount);
              setPurchaseDetail(res.data.success.data.purchase_detail);
              setPurchaseDate(
                res.data.success.data.purchase_date.split("T")[0],
              );
              setDueAmount(res.data.success.data.due_amount);
              setisLoading(false);
            } else {
              setPaymentError(res.data.error.message);
              setisLoading(false);
              setError(true);
            }
          })
          .catch((err) => {
            setError(true);
            setisLoading(false);
            throw new Error(err);
          });
      }
    }, 1000);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [purchaseId, shop_id]);
  //validation
  const [validated, setValidated] = useState(false);
  //states
  const [totalAmount, setTotalAmount] = useState("");
  const [errorTotalAmount, setErrorTotalAmount] = useState(false);
  const [paidAmount, setPaidAmount] = useState("");
  const [errorPaidAmount, setPaidTotalAmount] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [purchaseDate, setPurchaseDate] = useState(
    new Date().toISOString().split("T")[0],
  );
  const [purchaseDetail, setPurchaseDetail] = useState("");
  const [paymentTypeCheck, setPaymentTypeCheck] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (totalAmount) {
      setDueAmount(totalAmount - paidAmount);
    } else {
      setDueAmount("");
    }
  }, [totalAmount, paidAmount]);
  //onchange handles
  const handleTotalAmountChange = (e) => {
    if (
      purchaseId &&
      purchaseId !== -1 &&
      e.target.value >= 0 &&
      e.target.value.length < 10
    ) {
      setTotalAmount(e.target.value);
      if (e.target.value >= paidAmount) {
        setErrorTotalAmount(false);
      } else {
        setErrorTotalAmount(true);
      }
    } else {
      if (e.target.value >= 0 && e.target.value.length < 10) {
        setTotalAmount(e.target.value);
      }
      if (e.target.value < paidAmount) {
        setPaidAmount(0);
      }
    }
  };
  const handlePaidAmountChange = (e) => {
    if (
      Number(e.target.value) <= Number(totalAmount) &&
      Number(e.target.value) > 0
    ) {
      let num = parseInt(e.target.value, 10);
      setPaidAmount(num);
      setPaidTotalAmount(false);
    } else {
      setPaidTotalAmount(true);
      setPaidAmount("");
      setPaymentType("");
    }
  };
  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.value);
  };
  const handlePurchaseDateChange = (e) => {
    setPurchaseDate(e.target.value);
  };
  const handlePurchaseDetailChange = (e) => {
    setPurchaseDetail(e.target.value);
  };
  //submit method
  const handlePurchaseSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    try {
      if (
        totalAmount.length !== "" &&
        Number(totalAmount) > 0 &&
        totalAmount >= paidAmount &&
        !errorTotalAmount
      ) {
        const obj = {
          purchase_amount: totalAmount,
          purchase_detail: purchaseDetail,
          purchase_date: purchaseDate,
        };
        let res;
        if (!purchaseId || purchaseId === -1) {
          if (paidAmount > 0 ? (paymentType.length > 0 ? true : false) : true) {
            setIsClicked(true);
            res = await axios.post(
              process.env.REACT_APP_API_URL +
                "/vendors/purchase/add-with-payment",
              {
                purchase: {
                  ...obj,
                  vendor_id: id,
                },
                payment: {
                  payment_amount: paidAmount ? Number(paidAmount) : 0,
                  note: purchaseDetail,
                  payment_type: paymentType,
                  payment_date: requestDateTime(
                    purchaseDate + "T" + new Date().toISOString().split("T")[1],
                  ),
                },
              },
              { withCredentials: true },
            );
            if (res) {
              navigate("/vendor/purchase/" + id);
            }
            // }
          }
        } else {
          setIsClicked(true);

          res = await axios.patch(
            process.env.REACT_APP_API_URL +
              "/vendors/purchase/update/" +
              purchaseId,
            obj,
            { withCredentials: true },
          );
          if (res) {
            navigate("/vendor/purchase/" + id);
          }
        }
        if (res) {
          setValidated(false);
          setTotalAmount("");
          setPaidAmount("");
          setPaymentType("");
          setDueAmount("");
          setPurchaseDetail("");
        }
      }
    } catch (error) {
      setIsClicked(false);

      throw new Error(error.response.data);
    }
  };

  return (
    <>
      {isloading ? (
        <Loading />
      ) : data.length === 0 || error === true ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <h1>{data.length === 0 ? "No record exists." : paymentError}</h1>
        </div>
      ) : (
        <AddForm>
          <div className="d-flex justify-content-between align-items-center px-5">
            <h5>{purchaseId ? "Edit" : "Add"} Purchase</h5>
          </div>
          <hr />
          <Form
            noValidate
            validated={validated}
            onSubmit={handlePurchaseSubmit}
            className="px-5"
          >
            <Form.Group className="mb-3">
              <Form.Label className="mb-1">
                Total Amount<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                min={1}
                value={totalAmount}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onChange={handleTotalAmountChange}
                className="p-2"
                type="number"
                placeholder="Rs. 1000"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Total Purchase Amount.
              </Form.Control.Feedback>
            </Form.Group>
            {errorTotalAmount && (
              <p className="mb-3" style={{ color: "red" }}>
                Total Amount you entered is less then amount you already paid on
                this purchase.
              </p>
            )}
            <Form.Group className="mb-3">
              <Form.Label className="mb-1">Paid Amount</Form.Label>
              <Form.Control
                value={paidAmount}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-", "."].includes(e.key) &&
                  e.preventDefault()
                }
                onChange={handlePaidAmountChange}
                className="p-2"
                type="number"
                placeholder="Rs. 500"
                disabled={purchaseId}
              />
            </Form.Group>
            {errorPaidAmount && (
              <div className="alert alert-warning" role="alert">
                Paid Amount should be less then or equal to the total amount.
              </div>
            )}
            {(!purchaseId || purchaseId === -1) && paidAmount > 0 && (
              <Form.Group className="mb-3">
                <SearchableSelect
                  placeholder="Select"
                  label="Payment Type"
                  important
                  searchable={false}
                  options={[
                    { value: "Transfer", label: "Transfer" },
                    { value: "Cash", label: "Cash" },
                    { value: "Card", label: "Card" },
                    { value: "Check", label: "Check" },
                  ]}
                  handleChange={handlePaymentTypeChange}
                />
                <Form.Control
                  hidden
                  value={paymentType}
                  onChange={() => {}}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please Select Payment Type.
                </Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label className="mb-1">
                Due Amount<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                value={dueAmount}
                onChange={() => {}}
                className="p-2"
                type="text"
                placeholder="Rs. 500"
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1">
                Purchase Date<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                value={purchaseDate}
                onChange={handlePurchaseDateChange}
                className="p-2"
                type="date"
                placeholder=""
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Purchase Date.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="purchaseDetail_PurchaseForm"
            >
              <Form.Label className="mb-1">Purchase Detail</Form.Label>
              <Form.Control
                value={purchaseDetail}
                disabled={isClicked}
                onChange={handlePurchaseDetailChange}
                className="p-2"
                as="textarea"
                maxLength={"200"}
                placeholder="Comments"
                rows={3}
              />
            </Form.Group>
            <Button
              className="w-100"
              disabled={isClicked}
              onClick={handlePurchaseSubmit}
            >
              Submit
            </Button>
          </Form>
        </AddForm>
      )}
    </>
  );
};

export default PurchaseForm;
