// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadViaMobile_container__vugGG {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 250px;
  margin: 0 auto;
  border: 1px solid #dedfe4;
  box-sizing: border-box;
  border-radius: 6px;
}

.UploadViaMobile_close__LGqfL {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #9e3ce7;
  height: 20px;
  width: 20px;
}

.UploadViaMobile_qr_container__8yU86 {
  height: 192px !important;
  width: 192px !important;
  border: 2px solid #dedfe4;
  box-sizing: border-box;
  border-radius: 6px;
}

.UploadViaMobile_qr_container__8yU86 > canvas {
  object-fit: contain !important;
  height: 100% !important;
  width: 100% !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ImageUploader/UploadViaMobile.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,cAAc;EACd,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;EACf,YAAY;EACZ,6BAA6B;EAC7B,cAAc;EACd,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B,uBAAuB;EACvB,sBAAsB;AACxB","sourcesContent":[".container {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  min-height: 250px;\n  margin: 0 auto;\n  border: 1px solid #dedfe4;\n  box-sizing: border-box;\n  border-radius: 6px;\n}\n\n.close {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  cursor: pointer;\n  border: none;\n  background-color: transparent;\n  color: #9e3ce7;\n  height: 20px;\n  width: 20px;\n}\n\n.qr_container {\n  height: 192px !important;\n  width: 192px !important;\n  border: 2px solid #dedfe4;\n  box-sizing: border-box;\n  border-radius: 6px;\n}\n\n.qr_container > canvas {\n  object-fit: contain !important;\n  height: 100% !important;\n  width: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `UploadViaMobile_container__vugGG`,
	"close": `UploadViaMobile_close__LGqfL`,
	"qr_container": `UploadViaMobile_qr_container__8yU86`
};
export default ___CSS_LOADER_EXPORT___;
