// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vendors_vendorInfo__ZykVt {
    margin: 0.5rem 3rem !important;
    padding: 3rem !important;
}

.Vendors_orderNum__ecXEX {
    padding: 0rem 3rem!important;
}

.Vendors_toggle__ZrEeb .btn:focus {
    background: transparent !important;
}
.Vendors_toggle__ZrEeb {
    background: transparent !important;
}

@media screen and (max-width: 768px) {
    .Vendors_vendorInfo__ZykVt {
        margin: 0.5rem 0.3rem !important;
        padding: 1rem 0.5rem !important;
    }
    .Vendors_orderNum__ecXEX {
        padding: 0rem 1rem !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Vendors.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,wBAAwB;AAC5B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,kCAAkC;AACtC;AACA;IACI,kCAAkC;AACtC;;AAEA;IACI;QACI,gCAAgC;QAChC,+BAA+B;IACnC;IACA;QACI,6BAA6B;IACjC;AACJ","sourcesContent":[".vendorInfo {\n    margin: 0.5rem 3rem !important;\n    padding: 3rem !important;\n}\n\n.orderNum {\n    padding: 0rem 3rem!important;\n}\n\n.toggle :global(.btn:focus) {\n    background: transparent !important;\n}\n.toggle {\n    background: transparent !important;\n}\n\n@media screen and (max-width: 768px) {\n    .vendorInfo {\n        margin: 0.5rem 0.3rem !important;\n        padding: 1rem 0.5rem !important;\n    }\n    .orderNum {\n        padding: 0rem 1rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vendorInfo": `Vendors_vendorInfo__ZykVt`,
	"orderNum": `Vendors_orderNum__ecXEX`,
	"toggle": `Vendors_toggle__ZrEeb`
};
export default ___CSS_LOADER_EXPORT___;
